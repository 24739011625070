import React, {
  useState,
  useRef,
  useEffect,
  useImperativeHandle,
  forwardRef,
} from "react";
import {
  Button,
  FormControl,
  MenuItem,
  Checkbox,
  FormControlLabel,
  Select,
  InputLabel,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  CircularProgress, Tooltip, IconButton
} from "@mui/material";
import Resizer from 'react-image-file-resizer';
import AddIcon from "@mui/icons-material/Add";
import CancelIcon from "@mui/icons-material/Cancel";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";
import CloseIcon from "@mui/icons-material/Close";
import RefreshIcon from "@mui/icons-material/Refresh";
import InfoIcon from "@mui/icons-material/Info";
import Jimp from "jimp";
import "../../App.css";
import { useUser } from "../../UserContext";
import { AppHelper } from "../../AppHelper";

const ScanPopup = forwardRef(
  (
    { isOpen, isCropped, onClose, onScanComplete, onCamOpen, isSa, isClear, scanAgain },
    ref
  ) => {
    const [selectedFile, setSelectedFile] = useState(null);
    const [selectedVersion, setSelectedVersion] = useState("2.1");

    const [pages, setPages] = useState([
      { imageBase64: null, useOriginalImage: false },
    ]);
    const [numPages, setNumPages] = useState(1); // Initial number of pages is 1
    const [selectedIndex, setSelectedIndex] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const fileInputRef = useRef(null);
    const oUser = AppHelper.userCacheGet();
    const openCvURL = "https://docs.opencv.org/4.7.0/opencv.js";
    const [loadedOpenCV, setLoadedOpenCV] = useState(false);
    const [selectedImage, setSelectedImage] = useState(undefined);
    const [loadingPages, setLoadingPages] = useState(
      Array(numPages).fill(false)
    );
    const [originalImages, setOriginalImages] = useState(
      Array(numPages).fill(false)
    );
    const [inprocessImg, setInprocessImg] = useState({});

    const loadOpenCv = (onComplete) => {
      const isScriptPresent = !!document.getElementById("open-cv");
      if (isScriptPresent || loadedOpenCV) {
        setTimeout(function () {
          onComplete();
        }, 1000);
        setLoadedOpenCV(true);
      } else {
        const script = document.createElement("script");
        script.id = "open-cv";
        script.src = openCvURL;

        script.onload = function () {
          setTimeout(function () {
            onComplete();
          }, 1000);
          setLoadedOpenCV(true);
        };
        document.body.appendChild(script);
      }
    };

    useEffect(() => {
      setSelectedFile(null);
      //console.log("pop-open >>> set pages");
      if (isClear) {
        setPages([{ imageBase64: null, useOriginalImage: false }]);
        setNumPages(1);
        setSelectedIndex(null);
        setOriginalImages(Array(1).fill(false));
      }
    }, [isOpen]);
    useEffect(()=>{
      if(!scanAgain) return;
      //setSelectedVersion('4.0');
      handleScan(null, '4.0');
    }, [scanAgain]);

    const handleRetry = async (selectedIndex) => {
      setLoadingPages((prevLoadingPages) => {
        //console.log('Previous loadingPages:', prevLoadingPages);
        return prevLoadingPages.map((_, index) =>
          index === selectedIndex ? true : prevLoadingPages[index]
        );
      });

      const base64 = pages[selectedIndex].imageBase64;
      uploadTranscribeV3_0_S1(base64, selectedIndex);
    };

    const handleFileChange = async (event) => {
      
      setInprocessImg({
        fileType: '',
        fileSize: '',
        width: 0,
        height: 0,
      });
      const file = event.target.files[0];
      setSelectedFile(file);
      if (!file) return;
      try {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        console.log("isCropped", isCropped);
        const isHeicFile = file.name.toLowerCase().endsWith('.heic');
        if (isCropped) {
          reader.onload = () => {
            setLoadingPages((prevLoadingPages) =>
              prevLoadingPages.map((_, index) =>
                index === selectedIndex ? true : prevLoadingPages[index]
              )
            );
            const base64 = reader.result.split(",")[1];
            console.log(base64);
            // eslint-disable-next-line no-undef
            const scanner = new jscanify();
            loadOpenCv(() => {
              console.log(file);
              const newImg = document.createElement("img");
              newImg.src = `data:image/jpeg;base64,${base64}`;

              newImg.onload = function () {
                const resultCanvas = scanner.extractPaper(newImg, 386, 500);
                const highlightedCanvas = scanner.highlightPaper(newImg);
                const tempCanvas = document.createElement("canvas");
                tempCanvas.width = resultCanvas.width;
                tempCanvas.height = resultCanvas.height;
                const tempCtx = tempCanvas.getContext("2d");
                tempCtx.drawImage(resultCanvas, 0, 0);

                const highlightedBase64 = tempCanvas.toDataURL("image/jpeg");

                const base64WithoutPrefix = highlightedBase64.substring(
                  "data:image/jpeg;base64,".length
                );

                //console.log("highlightedBase64", selectedIndex, base64WithoutPrefix);

                const updatedPages = pages.map((page, index) =>
                  index === selectedIndex
                    ? { ...page, imageBase64: base64WithoutPrefix }
                    : page
                );

                setLoadingPages((prevLoadingPages) =>
                  prevLoadingPages.map((_, index) =>
                    index === selectedIndex ? false : prevLoadingPages[index]
                  )
                );
              };
            });
          };
        } else {
          await new Promise((resolveReader, rejectReader) => {
            reader.onload = () => {
              resolveReader();
            };
            reader.onerror = (error) => {
              rejectReader(error);
            };
          });
          //reader.onload = (eImg) => {
            console.log('file change');
            const imgView = new Image();
            imgView.src = reader.result;//eImg.target.result;
            //console.log(imgView, eImg.target.result);
            if(isHeicFile){
              setInprocessImg({
                fileType: (file.type && file.type.length>0) ? file.type : 'NA (' + file.name + ')',
                fileSize: formatFileSize(file.size),
                width: 0,
                height: 0,
              });
            }
            await new Promise((resolveImg, rejectImg) => {
              imgView.onload = () => {
                resolveImg();
              };
              imgView.onerror = (error) => {
                rejectImg(error);
              };
            });
            //imgView.onload = () => {
              setInprocessImg({
                fileType: (file.type && file.type.length>0) ? file.type : 'NA',
                fileSize: formatFileSize(file.size),
                width: imgView.naturalWidth,
                height: imgView.naturalHeight,
              });
              console.log(imgView.naturalWidth,'x',imgView.naturalHeight);
            //};
            const base64 = reader.result.split(",")[1]; // Extract the base64 string from the data URL
            setLoadingPages((prevLoadingPages) => {
              //console.log('Previous loadingPages:', prevLoadingPages);
              return prevLoadingPages.map((_, index) =>
                index === selectedIndex ? true : prevLoadingPages[index]
              );
            });
            //if(1==1) return;
            if (selectedVersion == "2.1") {
              console.log("selectedIndex", selectedIndex);
              uploadAlignV2_1_S1(base64, selectedIndex, file.type, file.size, isHeicFile);
            } else {
              // Update the pages array with the new image base64
              const updatedPages = pages.map((page, index) =>
                index === selectedIndex
                  ? { ...page, imageBase64: base64 }
                  : page
              );
              //setPages(updatedPages);
              setLoadingPages((prevLoadingPages) => {
                console.log("Previous loadingPages:", prevLoadingPages);
                return prevLoadingPages.map((_, index) =>
                  index === selectedIndex ? false : prevLoadingPages[index]
                );
              });
            }
          //};
        }
      } catch (error) {
        console.error("Error converting image to base64:", error);
      }
    };
    const uploadAlignV2_1_S1 = async (argB64, argImgIndex, fileType='', fileSize, isHeic=false) => {
      if(isHeic){
        console.log('heic <<<<');
        console.log(argB64);
        var _resHeic2Jpeg = await AppHelper.apiPost("api/convert/heic2Jpeg", {
          b64: argB64,
        });
        console.log(_resHeic2Jpeg);
        if(!_resHeic2Jpeg.status){
          console.log('failed', _resHeic2Jpeg);
          return;
        }
        argB64 = _resHeic2Jpeg.data;
      }
      var _resData;
      var _res = await AppHelper.apiPost("api/essay/scan/2.1/s1", {
        token: "",
        imgs: [argB64],
      });
      if (
        !_res.status ||
        (!_res.data && _res.data.length < 1) ||
        !_res.data[0].status ||
        !_res.data[0].data
      ) {
        alert("Failed to upload. Please refresh and try again!");
        return;
      }
      console.log(_res.data[0]);
      var _requesId = _res.data[0].data;
      var _newFileSize = '';
      if(_res.isResized && _res.isResized>0){
        _newFileSize = '*** ' + formatFileSize(_res.isResized);
      }
      console.log("_requesId", _requesId);

      await new Promise((resolve) => setTimeout(resolve, 1000 * 1)); //-- 1sec wait before checking the result
      var _waiting = true;

      var _iWait = 0;
      while (_waiting) {
        _iWait++;
        var _resResult = await AppHelper.apiPost(
          "api/essay/scan/2.1/s1/result",
          { token: "", requestId: _requesId }
        );
        if (!_resResult || !_resResult.status) {
          console.log(_resResult);
          _waiting = false;
        } else if (_resResult.data) {
          _resData = _resResult.data;
          _waiting = false;
        } else {
          console.log("wait.. " + _iWait);
          await new Promise((resolve) => setTimeout(resolve, 1000 * 1));
        }
        if(_iWait>30){
          console.log('exit wait.. after 30sec');
          _waiting = false;
        }
      }
      if (!_resData) {
        // alert(
        //   "Failed to read the upload result data. Please refresh and try again!"
        // );
        // return;
        _resData = argB64;
      }
      //console.log("_resData", _resData);
      //console.log("argB64", argB64);

      const updatedPages = pages.map((page, index) =>
        index === selectedIndex
          ? {
              ...page,
              imageBase64: _resData,
              originalImage: argB64,
              useOriginalImage: originalImages[selectedIndex] || false,
              fileType: fileType,
              fileSize: formatFileSize(fileSize),
              newFileSize: _newFileSize
            }
          : page
      );
      //setPages(updatedPages);

      setPages((prevPages) => {
        return prevPages.map((page, index) =>
          index === argImgIndex
            ? {
                ...page,
                imageBase64: _resData,
                originalImage: argB64,
                useOriginalImage: originalImages[selectedIndex] || false,
                fileType: fileType,
                fileSize: formatFileSize(fileSize),
                newFileSize: _newFileSize
              }
            : page
        );
      });
      setLoadingPages((prevLoadingPages) =>
        prevLoadingPages.map((_, index) =>
          index === argImgIndex ? false : false
        )
      );
    };
    function formatFileSize(bytes) {
      const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
      if (bytes === 0) return '0 Bytes';
      const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)), 10);
      return `${(bytes / Math.pow(1024, i)).toFixed(2)} ${sizes[i]}`;
    }
    const uploadTranscribeV3_0_S1 = async (argB64, argImgIndex) => {
      var _resData;
      var _res = await AppHelper.apiPost("api/essay/scan/3.0/s1", {
        token: "",
        imgs: [argB64],
      });
      console.log(_res.status);
      console.log(_res.data);
      if (
        !_res.status ||
        (!_res.data && _res.data.length < 1) ||
        !_res.data[0].status ||
        !_res.data[0].data
      ) {
        alert("Failed to upload. Please refresh and try again!");
        return;
      }
      var _requesId = _res.data[0].data;
      console.log("_requesId", _requesId);

      _resData = _res.data;
      //console.log("_resData", _resData);
      //console.log("argB64", argB64);

      const updatedPages = pages.map((page, index) =>
        index === selectedIndex
          ? {
              ...page,
              imageBase64: _resData,
              originalImage: argB64,
              useOriginalImage: originalImages[selectedIndex] || false,
            }
          : page
      );
      //setPages(updatedPages);

      setPages((prevPages) => {
        return prevPages.map((page, index) =>
          index === argImgIndex
            ? {
                ...page,
                imageBase64: _resData,
                originalImage: argB64,
                useOriginalImage: originalImages[selectedIndex] || false,
              }
            : page
        );
      });
      setLoadingPages((prevLoadingPages) =>
        prevLoadingPages.map((_, index) =>
          index === argImgIndex ? false : false
        )
      );
    };

    const heic2Jpeg = async(argB64)=>{

    }

    const handleAdd = () => {
      setNumPages(numPages + 1); // Increment number of pages
      setPages([...pages, { imageBase64: null }]);
      setLoadingPages([...loadingPages, false]);
      console.log("originalImages", originalImages);
      setOriginalImages([...originalImages, false]);
    };

    const handleRemove = () => {
      setNumPages(Math.max(numPages - 1, 1)); // Decrement number of pages, but ensure at least 1 page remains
      setPages(pages.slice(0, -1));
      setLoadingPages(loadingPages.slice(0, -1));
      setOriginalImages(originalImages.slice(0, -1));
    };

    const handleCamera = (argIndex) => {
      setSelectedIndex(argIndex);
      if (onCamOpen) {
        onCamOpen();
      }
    };

    const handleRefresh = (index) => {
      const updatedPages = [...pages];
      updatedPages[index].imageBase64 = null;
      setPages(updatedPages);
    };

    const handleScan = async (argEvent, argVer) => {
      // Validate all imageBase64 values are not null
      const isValid = pages.every((page) => page.imageBase64 !== null);

      if (!isValid) {
        alert("Please upload or capture images for all pages.");
        setIsLoading(false); // Set loading state back to false
        return;
      }
      const totalSize = pages.reduce((acc, page) => {
        const base64String = page.useOriginalImage
          ? page.originalImage
          : page.imageBase64;
        const sizeB = new Buffer(base64String, "base64").length;
        const sizeMB = (sizeB / (1024 * 1024)).toFixed(2);
        return acc + sizeMB;
      }, 0);
      console.log("size MB", totalSize);
      if (totalSize > 30) {
        alert(
          "Total image size exceeds 30 MB. Please reduce the number or size of images."
        );
        setIsLoading(false); // Set loading state back to false
        return;
      }

      setIsLoading(true); // Set loading state to true
      const images = pages.map((page) =>
        page.useOriginalImage ? page.originalImage : page.imageBase64
      ); // Get all imageBase64 values

      /*const data = {
    "status": true,
    "data": [
    {
    "status": true,
    "data": "\n<!DOCTYPE html>\n<html>\n<head>\n<title>Essay Title Not Visible</title>\n</head>\n<body>\n<p>Good morning teachers and friends, my name is Winona from 5 Agility and I am here today to tell you why you should choose me as the Exco prefect.</p>\n<p>Firstly, in my last four years at Anchor Green primary [school], I've learned the importance of good leadership, responsibility, and teamwork. When I am chosen to serve as an Exco prefect, I am committed to upholding the core values of our school and will contribute positively to our community.</p>\n<p>Secondly, I want to be an Exco prefect because this role gives me an opportunity to help other voice out</p>\n<!-- The essay seems to continue beyond what is visible in the image -->\n</body>\n</html>\n",
    "title": "Essay Title Not Visible",
    "body": "\nGood morning teachers and friends, my name is Winona from 5 Agility and I am here today to tell you why you [should] choose me as the Exco prefect.\nFirstly, in my last four years at Anchor Green primary school, I've learned the importance of good leadership, responsibility, and teamwork. When I am chosen to serve as an Exco prefect, I am committed to upholding the core values of our school and will contribute positively to our community.\nSecondly, I want to be an Exco prefect because this role gives me an opportunity to help other voice out\n\n\n\n"
    }
    ]
    };
    onScanComplete(data);
    onClose();
    return;*/

      for(var i=0;i<images.length;i++){
        const imgVW2 = new Image();
        imgVW2.src = `data:image/jpeg;base64,${images[i]}`;

        // Wrap onload event in a Promise
        await new Promise((resolve, reject) => {
          imgVW2.onload = () => {
            resolve();
          };
          imgVW2.onerror = (error) => {
            reject(error);
          };
        });

        // Once the image has loaded, you can access the dimensions
        const imgVW2_w = imgVW2.naturalWidth;
        const imgVW2_h = imgVW2.naturalHeight;
        console.log(imgVW2_w, 'x', imgVW2_h);
        const mp = (imgVW2_w * imgVW2_h) / 2000000;  // Calculate megapixels
        const max_mp = 1.5;//2
        console.log('mp', mp, max_mp);
        if(mp>max_mp){
          console.log('need to resize', mp);
          const scale = Math.sqrt(max_mp / mp);
          const new_height = parseInt(imgVW2_h * scale);
          const new_width = parseInt(imgVW2_w * scale);
          console.log('new', new_width, 'x', new_height);
          console.log('i', i, images.length);
          console.log('before', images[i].length);
          const imgFileOrigin = AppHelper.imgB64ToFile(images[i]);
          const resizedFile = await new Promise((resolve, reject) => {
            Resizer.imageFileResizer(
              imgFileOrigin,
              new_width,
              new_height,
              'JPEG', // Output format
              99, // Quality
              0, // Rotation
              (resizedFile) => {
                resolve(resizedFile);
              },
              'blob' // Output type
            );
          });
          images[i] = await AppHelper.imgFileToB64(resizedFile);
          console.log('after', images[i].length);
        }
      }
      //if(1==1) return;

      var _url = "api/essay/scan";
      if (selectedVersion == "2.0") _url = "api/essay/scan/2.0";
      else if (selectedVersion == "2.1") _url = "api/essay/scan/2.1";
      //else if (selectedVersion == "4.0") _url = "api/essay/scan/4.0";

      if(argVer) _url = "api/essay/scan/" + argVer;
      //console.log('argVer',argVer);

      var _res = await AppHelper.apiPost(_url, { token: "", imgs: images });

      if (!_res || !_res.status) {
        alert("ERROR: Failed to scan!");
        setIsLoading(false); // Set loading state back to false
        return;
      } else {
        setIsLoading(false); // Set loading state back to false
        onScanComplete(_res, images);
        onClose();
      }
    };

    const handleClose = () => {
      if (isClear) {
        setNumPages(1);
        setPages([{ imageBase64: null }]);
        setOriginalImages(Array(numPages).fill(false));
      }
      onClose();
    };

    const handleBrowse = (index) => {
      setSelectedIndex(index); // Set the selected index
      fileInputRef.current.click();
    };

    const handleOriginalImageChange = (index) => {
		
      setSelectedIndex(index); // Set the selected index
	  
      originalImages[index] = !originalImages[index] || false;
      pages[index].useOriginalImage = originalImages[index];
      setOriginalImages(originalImages);
     const newPages = [...pages];
		newPages[index] = {
			...newPages[index],
			useOriginalImage: originalImages[index],
		};

		// Update state with new copies
		
		setPages(newPages);
	  console.log("originalImages", originalImages);
	  console.log("pages", pages);
    };

    const handleCamCapture = (arg) => {
      const base64 = arg.split(",")[1];
      setLoadingPages((prevLoadingPages) =>
        prevLoadingPages.map((_, index) =>
          index === selectedIndex ? true : false
        )
      );
      if (selectedVersion == "2.1x") {
        console.log("selectedIndex", selectedIndex);
        uploadAlignV2_1_S1(base64, selectedIndex);
      } else {
        // Update the pages array with the new image base64
        // const updatedPages = pages.map((page, index) =>
        //   index === selectedIndex ? { ...page, imageBase64: base64 } : page
        // );
        // setPages(updatedPages);
        // setLoadingPages((prevLoadingPages) =>
        //   prevLoadingPages.map((_, index) => (index === selectedIndex ? false : false))
        // );
        const updatedPages = pages.map((page, index) =>
          index === selectedIndex ? { ...page, imageBase64: base64 } : page
        );
        //setPages(updatedPages);
        setPages((prevPages) => {
          return prevPages.map((page, index) =>
            index === selectedIndex ? { ...page, imageBase64: base64 } : page
          );
        });
        setLoadingPages((prevLoadingPages) =>
          prevLoadingPages.map((_, index) =>
            index === selectedIndex ? false : false
          )
        );
      }
    };
    useImperativeHandle(ref, () => ({
      handleCamCapture,
    }));

    return (
      <Dialog open={isOpen} onClose={onClose} fullWidth maxWidth="xs">
        <DialogTitle>Scan</DialogTitle>
        <DialogContent>
          <Grid container direction="column" spacing={3}>
            {isSa && (
              <Grid item>
                <FormControl fullWidth>
                  <InputLabel sx={{ marginTop: "10px" }} id="version-label">
                    Version
                  </InputLabel>
                  <Select
                    labelId="version-label"
                    id="version"
                    value={selectedVersion}
                    onChange={(event) => setSelectedVersion(event.target.value)}
                    label="Version"
                  >
                    <MenuItem value="1.0">1.0</MenuItem>
                    <MenuItem value="2.0">2.0</MenuItem>
                    <MenuItem value="2.1">2.1</MenuItem>
                    {/* <MenuItem value="3.0">3.0</MenuItem> */}
                    {/* <MenuItem value="4.0">4.0</MenuItem> */}
                  </Select>
                </FormControl>
              </Grid>
            )}
            {[...Array(numPages)].map((_, index) => (
              <Grid item key={index}>
                {((pages[index].imageBase64 != null && !loadingPages[index])) && (
                  <Grid container>
                    <FormControlLabel
                      //onClick={() => handleOriginalImageChange(index)}
                      control={
                        <Checkbox
                          color="primary"
                          checked={originalImages[index]}
                          onChange={() => handleOriginalImageChange(index)}
                        />
                      }
                      label="Click here if your page isn't aligned properly"
                      //label="Align page"
                      style={{marginRight:0}}
                    />
                    {/* <Tooltip title="Aligning your page will produce better transcription results. If you have already aligned your page, there's no need to align.">
                      <IconButton>
                        <InfoIcon />
                      </IconButton>
                    </Tooltip> */}
                  </Grid>
                )}
                <Grid container justifyContent="left">
                  {pages[index].imageBase64 === null &&
                    !loadingPages[index] && (
                      <Grid item sx={{ marginRight: "30px" }}>
                        <Grid
                          container
                          direction="column"
                          justifyContent="left"
                          sx={{
                            border: "1px solid #ccc",
                            padding: "38px",
                            borderRadius: "4px",
                            marginBottom: "16px",
                          }}
                        >
                          <Button
                            startIcon={<CloudUploadIcon />}
                            sx={{ marginBottom: "8px" }}
                            onClick={() => handleBrowse(index)}
                          >
                            Upload
                          </Button>
                          {/* <Button startIcon={<PhotoCameraIcon />} onClick={()=> handleCamera(index)}>Camera</Button> */}
                        </Grid>
                      </Grid>
                    )}
                  {(pages[index].imageBase64 !== null ||
                    loadingPages[index]) && (
                    <Grid item sx={{ marginRight: "30px" }}>
                      {loadingPages[index] ? (
                        <>
                        {/* { inprocessImg && inprocessImg.fileSize &&
                          <div style={{marginBottom:'.5rem'}}>
                            <div>File Type: {inprocessImg.fileType}</div>
                            <div>File Size: {inprocessImg.fileSize}</div>
                            <div>{inprocessImg.width} x {inprocessImg.height}</div>
                          </div>
                        } */}
                          <CircularProgress />
                        </>
                      ) : (
                        <>
                          <img
                            src={`data:image/jpeg;base64,${
                              pages[index].useOriginalImage
                                ? pages[index].originalImage
                                : pages[index].imageBase64
                            }`}
                            alt={`Page ${index + 1}`}
                            style={{ height: "150px", width: "150px" }}
                          />
                          <br />
                          <span
                            style={{
                              textAlign: "center",
                              marginTop: "8px",
                              marginBottom: "18px",
                            }}
                          >{`Page ${index + 1}`}</span>
                          <Button
                            onClick={() => handleRefresh(index)}
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center",
                              textAlign: "center",
                            }}
                          >
                            <RefreshIcon />
                            RETAKE
                          </Button>
                        </>
                      )}
                    </Grid>
                  )}
                  <Grid item>
                  {/* { pages[index].imageBase64 &&
                  <div style={{fontSize:'0.8rem'}}>
                    <div>{pages[index].fileType}</div>
                    <div>{pages[index].fileSize}</div>
                    <div>{pages[index].newFileSize}</div>
                  </div>
                  } */}
                  {index === numPages - 1 && !loadingPages[index] && (
                      <Grid
                        container
                        direction="column"
                        justifyContent="left"
                        sx={{
                          border: "0px solid #ccc",
                          padding: "18px",
                          borderRadius: "4px",
                          marginBottom: "16px",
                        }}
                      >
                        <Button
                          onClick={handleAdd}
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            textAlign: "center",
                          }}
                        >
                          <AddIcon />
                          ADD PAGE
                        </Button>
                        {numPages > 1 && (
                          <Button
                            onClick={handleRemove}
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center",
                              textAlign: "center",
                            }}
                          >
                            <CancelIcon />
                            REMOVE PAGE
                          </Button>
                        )}
                      </Grid>
                  )}
                  </Grid>
                </Grid>
              </Grid>
            ))}
            <Grid item>
              <Grid container justifyContent="center">
                <Button
                  className="app-btn-bg"
                  //sx={{ backgroundColor: 'rgb(99, 136, 137)', ":hover": { backgroundColor: 'rgb(157, 188, 152)' } }}
                  color="info"
                  variant="contained"
                  onClick={handleScan}
                  disabled={isLoading}
                >
                  {isLoading ? (
                    <CircularProgress size={24} color="inherit" />
                  ) : (
                    "SCAN"
                  )}
                </Button>
              </Grid>
            </Grid>
          </Grid>
          <input
            ref={fileInputRef}
            type="file"
            accept="image/jpeg,image/png,image/bmp/heic/HEIC"
            style={{ display: "none" }}
            onChange={handleFileChange}
          />
        </DialogContent>
        <Button
          onClick={handleClose}
          style={{ position: "absolute", top: 0, right: 0 }}
        >
          <CloseIcon />
        </Button>
      </Dialog>
    );
  }
);

export default ScanPopup;
