import React, { useState, useEffect, useRef } from "react";
import CssBaseline from "@mui/material/CssBaseline";
import { alpha } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import AppTopBar2 from "./common/app-top-bar2";
import MultiSelectComponent from "./common/multi-select";
import AppLoading from "./common/app-loading";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import {
  Avatar,
  AppBar,
  Button,
  Box,
  Card,
  CardActions,
  CardContent,
  Chip,
  Fab,
  Container,
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Paper,
  Select,
  Toolbar,
  Typography,
  MenuItem,
  FormControl,
  InputLabel,
  TextField,
  Grid,
  Table,
  TableContainer,
  TableCell,
  TableHead,
  TableBody,
  TableRow,
  TableSortLabel,
} from "@mui/material";
import { visuallyHidden } from "@mui/utils";
import LinearProgress from "@mui/material/LinearProgress";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import IconButton from "@mui/material/IconButton";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import PhoneNumber from "libphonenumber-js";
import { AppHelper } from "../AppHelper";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import SpaCheck from "./common/spa-check";
const dayjs = require("dayjs");

function LinearProgressWithLabel(props) {
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Box sx={{ width: "100%", mr: 1 }}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box>
        <Typography
          variant="body2"
          color="text.secondary"
          fontSize={"0.7rem"}
          sx={{
            color: props.recSelected == 1 ? "#fff" : "text.secondary",
          }}
        >
          {`${Math.round(props.value)}%`}
        </Typography>
      </Box>
    </Box>
  );
}
function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// Since 2020 all major browsers ensure sort stability with Array.prototype.sort().
// stableSort() brings sort stability to non-modern browsers (notably IE11). If you
// only support modern browsers you can replace stableSort(exampleArray, exampleComparator)
// with exampleArray.slice().sort(exampleComparator)
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}
const headCellsEssay = [
  {
    id: "timestampUtc",
    numeric: false,
    disablePadding: false,
    label: "TIMESTAMP",
  },
  { id: "name", numeric: false, disablePadding: false, label: "USER" },
  { id: "logCode", numeric: false, disablePadding: false, label: "LOG CODE" },
  { id: "remark", numeric: false, disablePadding: false, label: "REMARK" },
];

function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };
  const headCells = headCellsEssay;

  return (
    <TableHead>
      <TableRow>
        {/* <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              'aria-label': 'select all desserts',
            }}
          />
        </TableCell> */}
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

const UserLogsTable = ({ logs, filterLog, filterCode }) => {
  const [tblOrderLog, setTblOrderLog] = React.useState("desc");
  const [tblOrderByLog, setTblOrderByEssay] = React.useState("timestampUtc");

  const listSortAndFilterEssay = () => {
    var _list = [...logs];

    if (filterLog && filterLog.length > 0) {
      var _filterText = filterLog.toLowerCase();

      alert(filterLog);
      _list = _list.filter(
        (r) =>
          r.name.toLowerCase().includes(_filterText) ||
          r.email.toLowerCase().includes(_filterText)
      );
    }

    if (filterCode && filterCode.length > 0) {
      const _filterCodes = filterCode.map((code) => code.code.toLowerCase());

      _list = _list.filter((r) =>
        _filterCodes.includes(r.logCode.toLowerCase())
      );
    }

    var temp = stableSort(_list, getComparator(tblOrderLog, tblOrderByLog));
    return temp;
  };

  const tblSortLog = (event, property) => {
    const isAsc = tblOrderByLog === property && tblOrderLog === "asc";
    setTblOrderLog(isAsc ? "desc" : "asc");
  };

  return (
    <Table sx={{ minWidth: 650 }} aria-label="simple table">
      <EnhancedTableHead
        order={tblOrderLog}
        orderBy={tblOrderByLog}
        onRequestSort={tblSortLog}
        rowCount={listSortAndFilterEssay().length}
      />
      <TableBody>
        {listSortAndFilterEssay().map((row) => (
          <TableRow
            key={row.logId}
            sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
          >
            <TableCell align="left">
              {new Date(row.timestampUtc).toLocaleString()}
            </TableCell>
            <TableCell align="left">{row.name}</TableCell>
            <TableCell align="left">
              {row.logCode == "RULE-UPDATE"
                ? "Update Scoring Rule"
                : row.logCode == "P-P"
                ? "View Profile Page"
                : row.logCode == "P-SA"
                ? "View SA Page"
                : row.logCode == "P-H"
                ? "View Home Page"
                : row.logCode == "P-ES" &&
                  (!row.remark || row.remark == "" || row.remark == null)
                ? "View Essay Home Page"
                : row.logCode == "ES-ADD"
                ? "Submit New Essay"
                : row.logCode == "ES-DELETE"
                ? "Delete Essay"
                : "View Essay"}
            </TableCell>
            <TableCell align="left">{row.remark || ""}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

const UserLogs = ({ logs }) => {
  return (
    <ul>
      {logs.map((log) => (
        <li key={log.logId}>
          <p>Timestamp: {new Date(log.timestampUtc).toLocaleString()}</p>
          <p>Log Code: {log.logCode}</p>
          <p>Remark: {log.remark || "N/A"}</p>
        </li>
      ))}
    </ul>
  );
};

export default function PageSaUserLogListV2() {
  // const theme = createTheme({
  //   typography: {
  //     fontFamily: 'Noto Sans',
  //   }
  // });
  const navigate = useNavigate();
  const oUser = AppHelper.userCacheGet();
  const isPhoneVerifed =
    oUser && oUser.phoneNumber && oUser.phoneNumber.length > 0;

  const [isLoading, setIsLoading] = useState(true);
  const [logList, setLogList] = useState([]);

  const [startDate, setStartDate] = useState(dayjs());
  const [endDate, setEndDate] = useState(dayjs());
  const [selectedCodes, setSelectedCodes] = useState([]);
  const handleStartDateChange = (date) => {
    setStartDate(date);
  };

  const handleEndDateChange = (date) => {
    setEndDate(date);
    if (startDate && date && date < startDate) {
      setStartDate(date);
    } else if (!startDate) {
      setStartDate(date);
    }
  };

  const handleUserChange = (value) => {
    setSelectedCodes(value);
  };

  const otpRefs = [useRef(), useRef(), useRef(), useRef(), useRef(), useRef()];
  const drawerWidth = 240;

  const [codes, setCodes] = useState([
    {
      title: "View Profile Page",
      code: "P-P",
    },
    {
      title: "View Home Page",
      code: "P-H",
    },
    {
      title: "View Essay Home Page",
      code: "P-ES",
    },
    {
      title: "View SA Page",
      code: "P-SA",
    },
    {
      title: "Submit New Essay",
      code: "ES-ADD",
    },
    {
      title: "Delete Essay",
      code: "ES-DELETE",
    },
    {
      title: "View Essay",
      code: "P-ES",
    },
    {
      title: "Update Scoring Rule",
      code: "RULE-UPDATE",
    },
  ]);

  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [isClosing, setIsClosing] = React.useState(false);
  const [viewMode, setViewMode] = React.useState("LIST");
  const [refreshList, setRefreshList] = React.useState(false);
  const [recEssay, setRecEssay] = React.useState({});
  const [autoSelectId, setAutoSelectId] = React.useState("");
  const [countEssay, setCountLog] = React.useState(0);
  const [countUser, setCountUser] = React.useState(0);

  const [tblOrderLog, setTblOrderLog] = React.useState("desc");
  const [tblOrderByLog, setTblOrderByEssay] = React.useState("tCode");
  const [filterLog, setFilterLog] = React.useState("");

  const [tblOrderAcc, setTblOrderAcc] = React.useState("desc");
  const [tblOrderByAcc, setTblOrderByAcc] = React.useState("essayCount");
  const [filterAcc, setFilterAcc] = React.useState("");
  const [groupedData, setGroupedData] = useState([]);
  const [expandedUsers, setExpandedUsers] = useState({});

  const toggleUser = (userId) => {
    setExpandedUsers((prev) => ({
      ...prev,
      [userId]: !prev[userId],
    }));
  };

  useEffect(() => {
    if (!oUser) {
      navigate("/login");
      return;
    }
  });

  useEffect(() => {
    const fetchLogList = async () => {
      logList.splice(0);
      //setIsLoading(true);
      var _res = await AppHelper.apiPost("api/audit/list", {
        token: oUser.token,
        startDate: startDate,
        endDate: endDate,
      });
      console.log("_res", _res);
      if (!_res || !_res.status) {
        setIsLoading(false);
        return;
      }

      var _list = [...AppHelper.sortList(_res.data, "timestamp", 2)];
      setLogList(_list);
      setTimeout(() => {
        console.log("logList", _list);
      }, 1000);
      setIsLoading(false);
      setCountLog(_list.length);
      var grpUser = groupByUser(_res.data);
      setGroupedData(grpUser);
    };

    fetchLogList();
  }, [refreshList, startDate, endDate, selectedCodes, filterLog]);

  const handleDrawerClose = () => {
    setIsClosing(true);
    setMobileOpen(false);
  };
  const handleDrawerTransitionEnd = () => {
    setIsClosing(false);
  };

  useEffect(() => {
    AppHelper.apiAuditPost("P-SA", "");
  }, []);

  const drawerItemColrs = {
    "1x": "rgba(0,0,0,0.25)",
    "1xx": "rgb(157, 188, 152)",
    1: "rgb(99, 136, 137)",
    "0x": "white",
    0: "rgb(235, 217, 180)",
  };
  const drawerItemColorsText = {
    1: "rgba(255,255,255,1)",
    0: "rgba(0,0,0,0.6)",
  };
  const drawer = (
    <>
      <div style={{ overflowY: "auto", marginBottom: "110px" }}>
        <Box width={"100%"}>
          <List sx={{ fontFamily: "Noto Sans" }}>
            {logList.map((rec, index) => (
              <React.Fragment key={rec.logId}>
                <ListItem
                  sx={{
                    backgroundColor: rec.selected
                      ? drawerItemColrs[rec.selected]
                      : drawerItemColrs["0"],
                    color: rec.selected
                      ? drawerItemColorsText[rec.selected]
                      : drawerItemColorsText["0"],
                  }}
                  disablePadding
                >
                  <ListItemButton>
                    <div style={{ widht: "100%", flex: 1 }}>
                      <Box>
                        <Typography sx={{ fontWeight: "bold" }}>
                          {rec.viewTitle}
                        </Typography>
                      </Box>
                      <div>
                        <LinearProgressWithLabel
                          value={rec.avgScoreP}
                          recSelected={rec.selected}
                        />
                      </div>
                      <Typography
                        sx={{
                          display: "inline",
                          color: rec.selected
                            ? drawerItemColorsText[rec.selected]
                            : drawerItemColorsText["0"],
                          opacity: "0.8",
                        }}
                        component="span"
                        variant="body2"
                      >
                        {rec.tTitle}
                      </Typography>
                    </div>
                  </ListItemButton>
                </ListItem>
                <Divider />
              </React.Fragment>
            ))}
          </List>
        </Box>
      </div>
      <div
        style={{
          position: "absolute",
          bottom: 50,
          right: 0,
          left: 0,
          height: "60px",
          borderTop: "0 solid rgba(0,0,0,0.12)",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {/* <Button sx={{
        backgroundColor:'rgb(99, 136, 137)', 
        ":hover":{backgroundColor:'rgb(157, 188, 152)'},
        width:'100%',
        margin:'0 5px 22px 5px'
        }}
        onClick={essayAddClick} color="info" variant="contained" size="large">
        + ADD
      </Button> */}
      </div>
    </>
  );
  const mobileOpenClick = (arg) => {
    setMobileOpen(!mobileOpen);
  };
  const back2Home = () => {
    navigate("/");
  };
  const listSortAndFilterEssay = () => {
    var _list = [...logList];
    if (filterLog && filterLog.length > 0) {
      var _filterText = filterLog.toLowerCase();
      _list = _list.filter(
        (r) =>
          r.name.toLowerCase().includes(_filterText) ||
          r.email.toLowerCase().includes(_filterText)
      );
    }

    return stableSort(_list, getComparator(tblOrderLog, tblOrderByLog));
  };
  const filterLogChange = (event) => {
    setFilterLog(event.target.value);
  };
  const visibleLogs = React.useMemo(
    () => listSortAndFilterEssay(),

    //stableSort(logList, getComparator(tblOrderLog, tblOrderByLog))
    // .slice(
    //   page * rowsPerPage,
    //   page * rowsPerPage + rowsPerPage,
    // )

    //[order, orderBy, page, rowsPerPage],
    [logList, tblOrderLog, tblOrderByLog, filterLog, selectedCodes]
  );
  const tblSortLog = (event, property) => {
    const isAsc = tblOrderByLog === property && tblOrderLog === "asc";
    setTblOrderLog(isAsc ? "desc" : "asc");
    setTblOrderByEssay(property);
  };

  const groupByUser = (data) => {
    return data.reduce((acc, log) => {
      if (!acc[log.userId]) {
        acc[log.userId] = [];
      }

      if (selectedCodes && selectedCodes.length > 0) {
        const _filterCodes = selectedCodes.map((code) =>
          code.code.toLowerCase()
        );
        console.log("sleectedCode4", selectedCodes);
        if (_filterCodes.includes(log.logCode.toLowerCase())) {
          acc[log.userId].push(log);
        }
      } else {
        if (filterLog && filterLog.length > 0) {
          if (log.email.toLowerCase().includes(filterLog.toLowerCase())) {
            acc[log.userId].push(log);
          } else if (
            log.email.toLowerCase().includes(filterLog.toLowerCase())
          ) {
            acc[log.userId].push(log);
          }
        } else acc[log.userId].push(log);
      }
      return acc;
    }, {});
  };

  const container =
    window !== undefined ? () => window.document.body : undefined;
  return (
    <Box sx={{ display: "flex" }}>
      <AppTopBar2 mobileOpen={mobileOpen} mobileOpenClick={mobileOpenClick} />
      {isLoading ? (
        <AppLoading />
      ) : (
        <>
          {/* <Box
        component="nav"
        sx={{ width: { md: drawerWidth }, flexShrink: { sm: 0, md:0 }, border:'0 solid red' }}
      >
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onTransitionEnd={handleDrawerTransitionEnd}
          onClose={handleDrawerClose}
          ModalProps={{ keepMounted: true }}
          sx={{
            display: { xs: 'block', sm: 'block', md:'none' },
            minWidth: '300px'
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: 'none', sm: 'none', md: 'block' },
            backgroundColor:'red',
            minWidth:'300px'
          }}
          PaperProps={{
            sx: {
              backgroundColor: "#f9efdb", //color: "red",
              marginTop:'65px',
              width: drawerWidth
            }
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box> */}
          <Box
            component="main"
            sx={{
              flexGrow: 1,
              p: 3,
              //width: { md: `calc(100% - ${drawerWidth}px)` },
              width: "100%",
              marginTop: "50px",
            }}
          >
            {viewMode == "LIST" && (
              <>
                <Grid container spacing={0}>
                  <Grid item xs={3} sm={3}>
                    <Box m={0.5} pt={1}>
                      <DatePicker
                        sx={{ width: "100%" }}
                        label="Start Date"
                        value={startDate}
                        format="DD/MM/YYYY"
                        onChange={handleStartDateChange}
                        renderInput={(params) => <TextField {...params} />}
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={3} sm={3}>
                    <Box m={0.5} pt={1}>
                      <DatePicker
                        sx={{ width: "100%" }}
                        label="End Date"
                        value={endDate}
                        format="DD/MM/YYYY"
                        onChange={handleEndDateChange}
                        renderInput={(params) => <TextField {...params} />}
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={6} sm={6}>
                    <Box m={0.5} pt={1}>
                      <MultiSelectComponent
                        onChange={handleUserChange}
                        title="Select Log Code"
                        options={codes}
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={6} sm={6}>
                    <Box m={0.5} pt={1}>
                      <TextField
                        value={filterLog}
                        onChange={filterLogChange}
                        id="txtFilterLog"
                        label=""
                        variant="standard"
                        placeholder="Search"
                        fullWidth
                      />
                    </Box>
                  </Grid>
                </Grid>
                <div>
                  <Box m={0.5} pt={1.5}>
                    {!isLoading && (
                      <TableContainer component={Paper}>
                        <Table aria-label="simple table">
                          <TableBody>
                            {Object.keys(groupedData).map((userId) => (
                              <React.Fragment key={userId}>
                                {groupedData[userId].length > 0 && (
                                  <TableRow>
                                    <TableCell
                                      onClick={() => toggleUser(userId)}
                                      sx={{ cursor: "pointer" }}
                                    >
                                      <Box sx={{ display: "flex" }}>
                                        {!expandedUsers[userId] ? (
                                          <ExpandMoreIcon
                                            sx={{ marginRight: "0.1rem" }}
                                          />
                                        ) : (
                                          <ExpandLessIcon
                                            sx={{ marginRight: "0.1rem" }}
                                          />
                                        )}
                                        {groupedData[userId][0].name +
                                          " - " +
                                          groupedData[userId][0].email}{" "}
                                        ({groupedData[userId].length} logs)
                                      </Box>
                                    </TableCell>
                                  </TableRow>
                                )}
                                {expandedUsers[userId] && (
                                  <TableRow key={`r01-${userId}`}>
                                    <TableCell>
                                      <UserLogsTable
                                        filterCode={selectedCodes}
                                        logs={groupedData[userId]}
                                        filterLog={filterLog}
                                      />
                                    </TableCell>
                                  </TableRow>
                                )}
                              </React.Fragment>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    )}
                  </Box>
                </div>
              </>
            )}
          </Box>
        </>
      )}
      <SpaCheck></SpaCheck>
    </Box>
  );
}
