import React, { useEffect, useState, useRef } from "react";
import { AppHelper } from '../../AppHelper';
import MarkEmailReadIcon from '@mui/icons-material/MarkEmailRead';
import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox';
import SaveIcon from '@mui/icons-material/Save';
import { pink, green, blue } from '@mui/material/colors';
import {
  Button, Box, TextField, Chip, Dialog, DialogTitle, Grid, IconButton,
  Paper, CircularProgress, TableContainer, Table, TableHead, TableBody, TableRow, TableCell, Tooltip
} from '@mui/material';
import dayjs from "dayjs";
const relativeTime = require("dayjs/plugin/relativeTime");
const configJson = require('../../config.json?v=20230925');
dayjs.extend(relativeTime);

export default function PopShareEmail ({
  onClose, open, essay, shareId
}){
  const [records, setRecords] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [oUser, setOUser] = useState(AppHelper.userCacheGet());
  const [email1, setEmail1] = useState(oUser? (oUser.email1 || ''):'');
  const [email2, setEmail2] = useState(oUser? (oUser.email2 || ''):'');
  const [email1Sent, setEmail1Sent] = useState(false);
  const [email2Sent, setEmail2Sent] = useState(false);
  
  useEffect(()=>{
    if(!open) return;
    setEmail1Sent(false);
    setEmail2Sent(false);
  }, [open]);
  const handleClose = () =>{ onClose() };
  const onEmailSave = async(type)=>{ 
    const _res = await AppHelper.apiPost('api/account/updateEmail', {
      email1:email1,
	    email2:email2,
	    emailType:type,
      token: oUser.token,
	  profileId: oUser.profileId,
    });
    if (!_res.status) {
      alert('Email update failed');
      return;
    }
	  alert('Email update success');
    
	  if(type == 1)
		  oUser.email1 =  email1;
	  else if(type == 2)
		  oUser.email2 =  email2;
	  AppHelper.userCacheUpdate(oUser);
  }
  const sendEmail = async(arg)=>{
    var recipient = [];
    var _emailCode = 'SHARE';
    if(arg==1 & !AppHelper.isEmptyString(email1)){
      recipient.push(email1);
      _emailCode = 'SHARE-STUDENT';
    }
    else if(arg==2 & !AppHelper.isEmptyString(email2)){
      recipient.push(email2);
      _emailCode = 'SHARE-PARENT';
    }
    if(recipient.length < 1){
      alert('No email to send!');
      return;
    }
    var _user = AppHelper.userCacheGet();
    var _shareUrl = AppHelper.shareUrl(shareId);
    var _payload = {
      token: _user.token, 
      toEmail:recipient, ccEmail:[], 
      code:_emailCode, 
      param:{
        "link":_shareUrl,
        siteUrl:'https://essayace.ai'//configJson.siteUrl
      }
    };
    setIsLoading(true);
    var _res = await AppHelper.apiPost('api/email', _payload);
    setIsLoading(false);
    if (!_res || !_res.status) {
      alert('ERROR: Failed to send email!');
      return;
    }
    //alert('Email sent successfully!');
    if(arg==1) setEmail1Sent(true);
    else if(arg==2) setEmail2Sent(true);
    console.log('arg', arg);
  }
  return(
    <>
    <Dialog onClose={handleClose} open={open}>
      <DialogTitle>SHARE (Email)</DialogTitle>
      <Box m={1}>
        <Grid container m={1}>
          <Grid item xs={8}>
            <TextField
              margin="dense"
              label="Student Email"
              value={email1}
              type="email"
              fullWidth
              onChange={(e) => setEmail1(e.target.value)}
              InputProps={{endAdornment: <SaveIcon onClick={()=>onEmailSave(1)} sx={{cursor:'pointer'}} />}}
            />
          </Grid>
          <Grid item xs={4} p={0.5} display={'flex'} alignItems={'center'} justifyContent={'center'}>
            {/* <Tooltip title="share via Email">
              <IconButton onClick={() => sendEmail(1)} area-label="share via Email">
                <EmailIcon style={{color:pink[500]}} />
              </IconButton>
            </Tooltip> */}
            {!isLoading && !email1Sent &&
              <Button onClick={()=>sendEmail(1)} className="app-btn-bg" color="info" variant="contained" size="large" style={{marginRight:'2px'}}
                endIcon={<ForwardToInboxIcon />}
              >
                SEND
              </Button>
            }
            {email1Sent &&
              <MarkEmailReadIcon sx={{color:'rgba(19,130,117,1)'}}/>
            }
          </Grid>
          <Grid item xs={8}>
            <TextField
              margin="dense"
              label="Parent Email"
              value={email2}
              type="email"
              fullWidth
              onChange={(e) => setEmail2(e.target.value)}
              InputProps={{endAdornment: <SaveIcon onClick={()=>onEmailSave(2)} sx={{cursor:'pointer'}} />}}
            />
          </Grid>
          <Grid item xs={4} p={0.5} display={'flex'} alignItems={'center'} justifyContent={'center'}>
            {!isLoading && !email2Sent &&
              <Button onClick={()=>sendEmail(2)} className="app-btn-bg" color="info" variant="contained" size="large" style={{marginRight:'2px'}}
                endIcon={<ForwardToInboxIcon />}
              >
                SEND
              </Button>
            }
            {email2Sent &&
              <MarkEmailReadIcon sx={{color:'rgba(19,130,117,1)'}}/>
            }
          </Grid>
        </Grid>
      </Box>
      { isLoading &&
        <div style={{margin:'1rem', textAlign:'center'}}>
          <CircularProgress />
        </div>
      }
    </Dialog>
    </>
  );
}