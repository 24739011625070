import React, { useCallback, useState, useRef, useEffect  } from 'react';
import { Box, Button, Dialog, DialogContent, DialogTitle, Fab, Grid, CircularProgress  } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import CancelIcon from '@mui/icons-material/Cancel';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import SyncIcon from '@mui/icons-material/Sync';
import CloseIcon from '@mui/icons-material/Close';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import Jimp from 'jimp';
import { useUser } from '../../UserContext';
import { AppHelper } from '../../AppHelper';
import Webcam from "react-webcam";

const ScanCam = (props) => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [pages, setPages] = useState([{ imageBase64: null }]);
  const [numPages, setNumPages] = useState(1); // Initial number of pages is 1
  const [selectedIndex, setSelectedIndex] = useState(null); 
  const [isLoading, setIsLoading] = useState(false);
  const fileInputRef = useRef(null);
  const [ oUser, setOUser] = useState(null);
  const webcamRef = useRef(null);
  const [ isCamFront, setIsCamFront] = useState(false);
  const [ videoConstraints, setVideoConstraints] = useState({
    //width: { min: 1680 },
    //height: { min: 2376 },
    //width: '100%', height: '100vh',
    //aspectRatio: 0.6666666667,
    facingMode: { exact: "environment" }
  });

  useEffect(() => {
    // setSelectedFile(null);
    // setPages([{ imageBase64: null }]);
    // setNumPages(1);
    // setSelectedIndex(null);
  }, [props.isOpen]);

  const capture = useCallback(() => {
    const _imgB64 = webcamRef.current.getScreenshot({width: 800, height: 1040});//{width: 1920, height: 1080}
    if(props.onCamCapture) props.onCamCapture(_imgB64);
  }, [webcamRef]);

  const onClose = () =>{
    if(props.onClose) props.onClose();
  };
  const camSideChange = () => {
    setIsCamFront(!isCamFront);
    setTimeout(() => {
      var _constraints = {...videoConstraints};
      _constraints.facingMode = isCamFront ? { exact: "environment" } : 'user';
      console.log(isCamFront, _constraints);
      setVideoConstraints(_constraints);
    }, 0.2*1000);
  };

  return (
    <Dialog open={props.isOpen} fullWidth maxWidth="xs" sx={{minHeight:'500px'}}>
      <DialogTitle>Scan</DialogTitle>
      <DialogContent>
        <Box>
          <Box>
            <Fab onClick={camSideChange} color="secondary" aria-label="add">
              <SyncIcon />
            </Fab>
          </Box>
          <Webcam
            width={210} height={297}
            ref={webcamRef}
            //screenshotFormat="image/jpeg"
            videoConstraints={videoConstraints}
            audio={false}
            screenshotFormat="image/jpeg"
            forceScreenshotSourceSize
            // videoConstraints={{
            //   height: 2376,
            //   width: 1680
            // }}
            // style={{
            //   position: "absolute",
            //     textAlign: "center",
            //     zindex: 8,
            //     right:0,
            //     height: "100vh",
            //      width: "100%",
            //      objectFit: "fill",
            //   }}
          ></Webcam>
          <Box>
            {/* <button onClick={capture}>Capture photo</button> */}
            <Fab onClick={capture} color="primary" aria-label="add">
              <CameraAltIcon />
            </Fab>
          </Box>
        </Box>
      </DialogContent>
      <Button onClick={onClose} style={{ position: 'absolute', top: 0, right: 0 }}>
        <CloseIcon />
      </Button>
    </Dialog>
  );
};

export default ScanCam;
