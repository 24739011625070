import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useParams } from 'react-router-dom';
import AppTopBar2 from './common/app-top-bar2';
import AppLoading from './common/app-loading';
import { Button, Box } from '@mui/material';
import { AppHelper } from '../AppHelper';
import EssayView from './essay/essay-view';
import SpaCheck from './common/spa-check';

export default function PageEssayDetail() {
  
  const navigate = useNavigate();
  const oUser = AppHelper.userCacheGet();
  const { pageEssayId } = useParams();
  
  const [isLoading, setIsLoading] = useState(true);

  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [viewMode, setViewMode] = React.useState('VIEW');  
  const [recEssay, setRecEssay] = React.useState({});
  const [profileId, setProfileId] = useState('');
  const [isSa, setIsSa] = React.useState(false);

  useEffect(()=>{
    if(!oUser){
      navigate('/login');
      return;
    }
  });
  
  useEffect(()=>{
    if(!oUser){
      navigate('/login');
      return;
    }
    if(!oUser.profileId){
      console.log('no profile id', oUser);
      //navigate('/login');
      //return;
    }
    if(profileId=='')
      setProfileId(oUser.profileId);

    const fetchIAM = async()=>{
      var _res = await AppHelper.apiPost('api/account/iam', {token: oUser.token});
      if(!_res || !_res.status || !_res.data || !_res.data.isSa){
        return;
      }
      setIsSa(true);
    }
    fetchIAM();
  });
  
  useEffect(() => {
    AppHelper.apiAuditPost("P-SA", "");
  }, []);

  useEffect(()=>{
    const fetchEssayList = async() =>{
      setIsLoading(true);
      var _res = await AppHelper.apiPost('api/essay/detail', {token: oUser.token, essayId:pageEssayId});
      setIsLoading(false);
      if(!_res || !_res.status){
        return;
      }
      var _recEssay = _res.data;
      _recEssay.email = _res.email;
		  _recEssay.student = _res.student;
		
		  setRecEssay(_recEssay);  
    };
    
    const fetchIAM = async()=>{
      if(!oUser){
        navigate('/login');
        return;
      }
      await AppHelper.apiPost('api/account/iam', {token: oUser.token});
      fetchEssayList();
    }
    fetchIAM();
  }, [pageEssayId]);
 
  const mobileOpenClick = (arg)=>{
    setMobileOpen(!mobileOpen);
  };
  
  const essayViewDone = (arg) =>{    
    setViewMode('VIEW');
  };
  return (
    <Box sx={{display:'flex'}}>
      <AppTopBar2 mobileOpen={mobileOpen} mobileOpenClick={mobileOpenClick} />
      {isLoading? (
        <AppLoading />
      ):(
      <>
      <Box
        component="main"
        sx={{ flexGrow: 1, p: 3, 
          //width: { md: `calc(100% - ${drawerWidth}px)` },
          width: '100%',
          marginTop:'50px' 
        }}
      >
        {
          viewMode=='VIEW' &&(
            <>    
            <EssayView isSa={isSa} essayFormDone={essayViewDone} essayId={recEssay.essayId}></EssayView>
            </>
          )
        }
      </Box>
      </>
      )}
      <SpaCheck></SpaCheck>
    </Box>
  );
}
