import React, {useState, useEffect, useRef} from "react";
import './dev-01.css';

export default function Dev01() {
  const containerRef = useRef(null);
  const openCvURL = 'https://docs.opencv.org/4.7.0/opencv.js';
  const [loadedOpenCV, setLoadedOpenCV] = useState(false);
  const [selectedImage, setSelectedImage] = useState(undefined);

  const images = [
    { src: '/img/IMG_4173.png' }, 
    { src: '/img/paper-1.png' }, 
    { src: '/img/paper-2.png' }, 
    { src: '/img/paper-3.jpeg' },
    { src: '/img/paper-4.jpeg' },
  ];

  useEffect(() => {
    // eslint-disable-next-line no-undef
    const scanner = new jscanify();
    loadOpenCv(() => {
      if (selectedImage) {
        containerRef.current.innerHTML = '';
        const newImg = document.createElement('img');
        newImg.src = selectedImage.src;

        newImg.onload = function () {
          const resultCanvas = scanner.extractPaper(newImg, 386, 500);
          containerRef.current.append(resultCanvas);

          const highlightedCanvas = scanner.highlightPaper(newImg);
          containerRef.current.append(highlightedCanvas);
        };
      }
    });
  }, [selectedImage]);
  const loadOpenCv = (onComplete) => {
    const isScriptPresent = !!document.getElementById('open-cv');
    if (isScriptPresent || loadedOpenCV) {
      setLoadedOpenCV(true);
      onComplete();
    } else {
      const script = document.createElement('script');
      script.id = 'open-cv';
      script.src = openCvURL;

      script.onload = function () {
        setTimeout(function () {
          onComplete();
        }, 1000);
        setLoadedOpenCV(true);
      };
      document.body.appendChild(script);
    }
  };

  return (
    <>
      <div className="scanner-container">
        <div>
          {!loadedOpenCV && (
            <div>
              <h2>Loading OpenCV...</h2>
            </div>
          )}
          {images.map((image, index) => (
            <img
              key={index}
              className={selectedImage && selectedImage.src === image.src ? 'selected' : ''}
              src={image.src}
              onClick={() => setSelectedImage(image)}
            />
          ))}
        </div>
        <div ref={containerRef} id="result-container"></div>
      </div>
    </>
  );
}