import React, { useEffect, useState, useRef } from "react";
import { AppHelper } from '../../AppHelper';
import {
  Button, Box, Chip, Dialog, DialogTitle, Grid, IconButton,
  ListItem, ListItemIcon, ListItemText,
  Tooltip, Typography
} from '@mui/material';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import WarningIcon from '@mui/icons-material/Warning';
import ErrorIcon from '@mui/icons-material/Error';
import LightbulbIcon from '@mui/icons-material/Lightbulb';
import GppGoodIcon from '@mui/icons-material/GppGood';
import TipsAndUpdatesIcon from '@mui/icons-material/TipsAndUpdates';
import { pink, green } from '@mui/material/colors';
import PopErrorAdd from "./pop-error-add";

export default function ScoreSubExpand ({
  records, essay, allowMark, onMarkDone
}){
  const [subList, setSubList] = useState([]);

  useEffect(() => {
    if(records && records.length>0){
      records.forEach(r => {
        r.eg = [];
        if(r.exampleImprovements){
          r.exampleImprovements.forEach((ei, i)=>{
            r.eg.push({
              v: ei,
              isFalse: r.exampleImprovementsFalse && r.exampleImprovementsFalse[i] && r.exampleImprovementsFalse[i].isFalse==1
            });
          });
        }
      });
      setSubList(records);
    }
  }, [records]);
  
  const onFalseClick = async(iSub, argIndex, argTitle, argValue) => {
    var _code = argTitle.toLowerCase().replace(/ /g, "_");
	  var _user = AppHelper.userCacheGet();
    var _url = 'api/score/' + 'ST' + '/error/false';
	  var _payload = {token: _user.token, essayId: essay.essayId, errorIndex: argIndex, isFalse:argValue, code:_code};
	  
	  var _res = await AppHelper.apiPost(_url, _payload);
	  if (!_res || !_res.status) {
		  alert('ERROR: Failed to set false!');
		  return;
	  }
    var _tmpList = [...subList];
    var _sub = _tmpList[iSub];
    var _eg = _sub.eg[argIndex];
    _eg.isFalse = argValue==1;
    setSubList(_tmpList);
	};
  
  return(
    <>
    { subList.map((rSub, iSub)=>(
      <Grid key={'score_sub_'+iSub} container>
        <Grid item xs={12} mt={1}>
          <Typography sx={{fontWeight:'bold', color:'#666', marginRight:'0', marginLeft:'1rem'}}>
            {rSub.score}/4 {rSub.title}
            &nbsp;
            (Student Level: {rSub.score == 4? 'Proficient': rSub.score == 3? 'Competent': rSub.score == 2? 'Developing':'Needs Improvement'})
            {/* <Chip variant="outlined" sx={{marginLeft:'0.5rem'}} 
              icon={rSub.score == 4? <GppGoodIcon />: rSub.score == 3? <LightbulbIcon />: rSub.score == 2? <ErrorIcon />:<ErrorIcon />} 
              label={rSub.score == 4? 'Proficient': rSub.score == 3? 'Competent': rSub.score == 2? 'Developing':'Needs Improvement'}
            /> */}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          {rSub.eg.map((sEg,iSEg)=>(
            <Grid key={'sug_iSub_' + iSEg} container>
              <Grid item xs={12}>
                <div style={{display:'flex'}}>
                  <div style={{flexGrow:1}}>
                    <ListItem
                      // secondaryAction={
                      //   <div>
                      //   { allowMark && sEg.isFalse &&
                      //     <Tooltip title="Mark as Positive">
                      //       <IconButton onClick={()=>onFalseClick(iSub, iSEg, rSub.title, 0)} edge="end" aria-label="delete">
                      //         <TaskAltIcon style={{color:green[500]}} />
                      //       </IconButton>
                      //     </Tooltip>
                      //   }
                      //   { allowMark && !sEg.isFalse &&
                      //     <Tooltip title="Mark as False Positive">
                      //       <IconButton onClick={()=>onFalseClick(iSub, iSEg, rSub.title, 1)} edge="end" aria-label="delete">
                      //         <RemoveCircleOutlineIcon style={{color:pink[500]}} />
                      //       </IconButton>
                      //     </Tooltip>
                      //   }
                      //   </div>
                      // }
                    >
                      <ListItemIcon sx={{minWidth:'40px'}}>
                      <TipsAndUpdatesIcon sx={{color:'rgb(157, 188, 152)'}} />
                      </ListItemIcon>
                      <ListItemText sx={{textDecoration:(sEg.isFalse==1)?'line-through':''}}
                        primary={sEg.v}
                        //secondary={'2nd row'}
                      />
                    </ListItem>
                  </div>
                  <div>
                    <div style={{paddingRight:'115px'}}>
                        { allowMark && sEg.isFalse &&
                          <Tooltip title="Mark as Positive">
                            <IconButton onClick={()=>onFalseClick(iSub, iSEg, rSub.title, 0)} edge="end" aria-label="delete">
                              <TaskAltIcon style={{color:green[500]}} />
                            </IconButton>
                          </Tooltip>
                        }
                        { allowMark && !sEg.isFalse &&
                          <Tooltip title="Mark as False Positive">
                            <IconButton onClick={()=>onFalseClick(iSub, iSEg, rSub.title, 1)} edge="end" aria-label="delete">
                              <RemoveCircleOutlineIcon style={{color:pink[500]}} />
                            </IconButton>
                          </Tooltip>
                        }
                    </div>
                  </div>
                </div>
                {/* <Box>
                  <TipsAndUpdatesIcon sx={{color:'rgb(157, 188, 152)'}} />
                  {sEg}
                </Box> */}
              </Grid>
            </Grid>
          ))}
        </Grid>
      </Grid>
    ))}
    </>
  );
}