import React, { useEffect, useState, useRef } from "react";
import { AppHelper } from '../../AppHelper';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { pink, green, blue } from '@mui/material/colors';
import { diffWordsWithSpace } from 'diff';
import {
  Button, Box, Chip, chipClasses, CircularProgress, Dialog, DialogTitle,
  FormControl, FormControlLabel, FormLabel, Grid, IconButton, Fab, InputLabel, MenuItem,
  Radio, RadioGroup, Select,
  Paper, TableContainer, Table, TableHead, TableBody, TableRow, TableCell, Tooltip, Typography,
} from '@mui/material';
import dayjs from "dayjs";
import { EssayHelper } from "../../helpers/essay.helper";
import ScoreSubExpand from "./score-sub-expand";
import { useUserContext } from "../../contexts/context-user";
const relativeTime = require("dayjs/plugin/relativeTime");
dayjs.extend(relativeTime);

export default function PopScoreRuleCopy ({
  onClose, open, gradeRules, essay, gradeTo, onCopyDone
}){
	const { oUser } = useUserContext();
  const [record, setRecord] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [recEssay, setRecEssay] = useState(null);
  const [copyOption, setCopyOption] = useState('WS');
  const [gradeList, setGradeList] = useState([]);
  const [grade, setGrade] = useState('');
  
  useEffect(()=>{
    //if(!open || !essay) return;
    setGrade('');
  }, [open]);
  useEffect(()=>{
    var _gradeList =[
      {n:'P1',v:'P1'},
      {n:'P2',v:'P2'},
      {n:'P3',v:'P3'},
      {n:'P4',v:'P4'},
      {n:'P5',v:'P5'},
      {n:'P6',v:'P6'}
    ];
    _gradeList = _gradeList.filter(x=>x.v!=gradeTo);
    console.log(_gradeList);
    setGradeList([..._gradeList]);
  }, [gradeTo]);
	
  const handleClose = () =>{ onClose() };
  const gradeSelect = (event)=>{
    setGrade(event.target.value);
    console.log(essay);
  }
  const copyOptionChange = (arg)=>{
    console.log(arg.target.value);
    setCopyOption(arg.target.value);
  }
  const onCopy = async()=>{
    if(!oUser){
      alert('Invalid login user!');
      return;
    }
    if(grade == ''){
      alert('Please select Grade!');
      return;
    }
    console.log(grade, gradeTo);
    console.log(gradeRules);
    var _g = gradeRules.find(x=>x.grade == grade);
    if(!_g){
      alert('From Grade not found!');
      return;
    }
    var _g2 = gradeRules.find(x=>x.grade == gradeTo);
    if(!_g){
      alert('To Grade not found!');
      return;
    }
    var _data = [];
    _g.data.forEach(d1=>{
      _data.push({scoreType:d1.code, code:'overall', value:d1.v});
      if(d1.data && d1.data.length>0){
        var _d1Data = [...d1.data];
        if(copyOption == 'W' && (d1.code == 'GM' || d1.code == 'SP')){
          var _d1 = _g2.data.find(x=>x.code == d1.code);
          if(_d1){
            _d1Data = [..._d1.data];
          }
        }
        _d1Data.forEach(d2=>{
          var _v2 = d2.v;
          if(d1.code == 'GM' || d1.code == 'SP'){
            _v2 = 100-_v2;
          }
          _data.push({scoreType:d1.code, code:d2.code, value:_v2});
          if(d2.data && d2.data.length>0){
            d2.data.forEach(d3=>{
              var _v3 = d3.v;
              if(d1.code == 'VC'){
                _v3 = 100-_v3;
              }
              _data.push({scoreType:d1.code, code:d3.code, value:_v3});
            })
          }
        });
      }
    })
    var _payload = {token: oUser.token, grade:gradeTo, data:_data, isSA:0};
    console.log(_payload);
    //if(!AppHelper.isEmptyString(profileId)) _payload.profileId = profileId;
    setIsLoading(true);
    await AppHelper.apiPost('api/essay/score/rule/update', _payload);
    setIsLoading(false);
    //alert('Copy done successfully!');
    if(onCopyDone) onCopyDone();
    handleClose();
  }
  return(
    <Dialog onClose={handleClose} open={open} sx={{minWidth:'400px'}}>
      <DialogTitle>COPY SCORING RULES ({gradeTo})
      <div style={{fontSize:'0.8rem', fontWeight:'normal', opacity:0.6, fontStyle:'italic'}}>Copy scoring rules from one grade to another</div>
      </DialogTitle>
      { !isLoading &&
      <>
      <Box padding={'0.5rem'} textAlign={'center'}>
        <FormControl fullWidth>
          <InputLabel id='lblGrade'>FROM GRADE</InputLabel>
          <Select labelId='lblGrade'
            value={grade}
            onChange={gradeSelect}
            label="FROM GRADE"
            sx={{width:'30rem'}}
          >
            {gradeList.map((item) => (
              <MenuItem key={item.v} value={item.v}>
                {item.n}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
      <Box ml={2}>
        <FormControl>
          <FormLabel id="demo-controlled-radio-buttons-group"></FormLabel>
          <RadioGroup
            aria-labelledby="demo-controlled-radio-buttons-group"
            name="controlled-radio-buttons-group"
            value={copyOption}
            onChange={copyOptionChange}
          >
            <FormControlLabel value="WS" control={<Radio />} label="Weights & Score" />
            <FormControlLabel value="W" control={<Radio />} label="Weights" />
          </RadioGroup>
        </FormControl>
      </Box>
      <Box padding={'0.5rem'} textAlign={'center'}>
        { !isLoading &&
        <Button onClick={onCopy} className="app-btn-bg" color="info" variant="contained" size="large" style={{marginRight:'2px'}}>COPY</Button>
        }
      </Box>
      </>
      }
      { isLoading &&
        <div style={{margin:'1rem', textAlign:'center'}}>
          <CircularProgress />
        </div>
      }
    </Dialog>
  );
}