import React, { useState, useEffect, useRef } from "react";
import CssBaseline from "@mui/material/CssBaseline";
import { alpha } from '@mui/material/styles';
import '../App.css';
import { useNavigate } from 'react-router-dom';
import AppTopBar2 from './common/app-top-bar2';
import AppLoading from './common/app-loading';
import { Avatar, AppBar, Button, Box, Chip, CircularProgress, Fab, Container, Divider, Drawer, 
  List, ListItem, ListItemButton, ListItemIcon, ListItemText, Paper, Select, Toolbar, Typography, MenuItem, FormControl, InputLabel, TextField, Grid 
} from '@mui/material';
import LinearProgress from '@mui/material/LinearProgress';
import BookmarksIcon from '@mui/icons-material/Bookmarks';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import PhoneNumber from 'libphonenumber-js';
import { AppHelper } from '../AppHelper';
import { EssayHelper } from '../helpers/essay.helper';
import EssayForm from './essay/essay-form-v2';
import EssayView from './essay/essay-view';
import SpaCheck from './common/spa-check';
import AppTopBar from "./common/app-top-bar";
import PopSuggestion from "./common/pop-suggestion";
import HomeDrawer from "./home-drawer";
import { useEssayContext } from "../contexts/context-essay";
import { useUser } from "../UserContext";
const dayjs = require('dayjs');

export default function PageHomeTest() {
  const navigate = useNavigate();
  const oUser = AppHelper.userCacheGet();
  const isPhoneVerifed = oUser && oUser.phoneNumber && oUser.phoneNumber.length>0;
  
  const [viewCode, setViewCode] = useState('PHONE');
  const [isLoading, setIsLoading] = useState(false);
  //const [essayList, setEssayList] = useState([]);
  const [countryCode, setCountryCode] = useState('SG');
  const [phoneNumber, setPhoneNumber] = useState('99998888');
  const [formattedPhoneNumber, setFormattedPhoneNumber] = useState('0');
  const [flagUrl, setFlagUrl] = useState('https://flagcdn.com/w320/sg.png');
  const [txtPhoneNoOK, setTxtPhoneNoOK] = useState(true);

  const otpRefs = [useRef(), useRef(), useRef(), useRef(), useRef(), useRef()];
  const [otpErrors, setOtpErrors] = useState([false, false, false, false, false, false]);
  const drawerWidth = 240;

  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [isClosing, setIsClosing] = React.useState(false);
  const [viewMode, setViewMode] = React.useState('NEW');
  const [refreshList, setRefreshList] = React.useState(false);
  const [recEssay, setRecEssay] = React.useState({});
  const [autoSelectId, setAutoSelectId] = React.useState('');
  const scoreCodesV2 = [
    { c:'GM', c2: 'grammar', n:'Grammar', v:0, p:0, eg: [], sub: [], expand: false }, 
    { c:'SP', c2: 'spelling', n:'Spelling', v:0, p:0, eg: [], sub: [], expand: false },
    { c:'VC', c2: 'vocabulary', n:'Vocabulary', v:0, p:0, eg: [], sub: [], expand: false }, 
    { c:'ST', c2: 'structure', n:'Structure', v:0, p:0, eg: [], sub: [], expand: false },
    //{ c:'FL', c2: 'flow', n:'Flow', v:0, p:0 },
  ];
  const [filterEssay, setFilterEssay] = React.useState('');
  const [profiles, setProfiles] = React.useState([]);
  const [profileId, setProfileId] = useState('');
  const refTopBar = useRef(null);
  const [isSa, setIsSa] = React.useState(false);
  const [popShowFeedbackOpen, setPopShowFeedbackOpen] = useState(false);

  const {essayList, isEssayListLoaded, loadEssayList, essaySelect} = useEssayContext();

  useEffect(()=>{
    if(!oUser) return;
    //console.log('isEssayListLoaded',isEssayListLoaded);
    if(!isEssayListLoaded){
      //loadEssayList(oUser);
      return;
    }
    else{
      //console.log('loaded essay list', essayList);
    }
  }, [isEssayListLoaded]);
  useEffect(()=>{
    if(!oUser){
      navigate('/login');
      return;
    }
    AppHelper.apiAuditPost("P-H", "");
    if(!oUser.profileId){
      console.log('no profile id', oUser);
      //navigate('/login');
      //return;
    }
    if(profileId=='')
      setProfileId(oUser.profileId);

    const fetchIAM = async()=>{
      var _res = await AppHelper.apiPost('api/account/iam', {token: oUser.token});
      if(!_res || !_res.status || !_res.data || !_res.data.isSa){
        return;
      }
      setIsSa(true);
    }
    const fetchProfiles = async () => {
      try {
        const response = await AppHelper.apiPost('api/profile/list', {token: oUser.token});
        if (response && response.status && response.data) {
          setProfiles(response.data);
        } else {
          console.error('Failed to fetch profiles:', response);
        }
      } catch (error) {
        console.error('Error fetching profiles:', error);
      }
    };
    fetchIAM();
    fetchProfiles();
  }, []);
  const fetchEssayList = async() =>{
    if(!oUser){
      navigate('/login');
      return;
    }
    
    essayList.splice(0);
    setIsLoading(true);
    var _res = await AppHelper.apiPost('api/essay/list/v3', {token: oUser.token, profileId: oUser.profileId});
    setIsLoading(false);
    if(!_res || !_res.status){
      return;
    }
    _res.data.forEach(r=>{
      var _ts = dayjs(r.timestampUtc);
      r.tCode = _ts.format('YYYYMMDDHHmmss');
      //r.tTitle = `Week ${_ts.week()} - ` + _ts.format('DD MMM YYYY');
      r.tTitle = _ts.format("DD MMM YY' hh:mm A");
      r.viewTitle = `${r.topic}`;
      r.version = r.avgScore != null ? 1 : 2;
      var _score = r.version == 1 ? r.avgScore : r.avgScore2;
      //console.log('r.version',r.version);
      if(r.version == 1){
        r.avgScore = parseFloat(_score);
        r.avgScoreP = (r.avgScore / 5)*100;
      }
      else if(r.version == 2){
        var _scores = [...scoreCodesV2];
        r.version = EssayHelper.scoreVersionNumFromScore(r);
        // if(r.scores && r.scores.length>0 && r.scores[0].version == '2.10')
        //   r.version = 2.1;
        // else if(r.scores && r.scores.length>0 && r.scores[0].version == '2.20')
        //   r.version = 2.2;
        // else if(r.scores && r.scores.length>0 && r.scores[0].version == '3.00')
        //   r.version = 3.0;
        // else if(r.scores && r.scores.length>0 && r.scores[0].version == '3.10')
        //   r.version = 3.1;

        var _v2TotalScore = 0;
        if(r.version == 3.1){
          _scores.forEach(_rScore=>{
            var _arrScRec = r.scores.filter(_x=>_x.scoreCode == _rScore.c);
            const _scSum = _arrScRec.reduce((total, current) => total + current.score, 0);
            _rScore.v = _arrScRec.length > 0 ? (_scSum/_arrScRec.length) : 0;
            _rScore.v = parseFloat(_rScore.v.toFixed(2));
            if(_rScore.c == 'ST'){
              _rScore.v = parseFloat((_rScore.v * 2.5).toFixed(2));
            }
            else if(_rScore.c == 'VC' && _arrScRec.length<2){
              _rScore.v = parseFloat((_rScore.v / 2).toFixed(2));
            }
            _v2TotalScore += _rScore.v;
            // console.log('_scSum',_scSum, r);
            // if((r.version == 3 || r.version == 3.1) && (_rScore.c == 'SP' || _rScore.c == 'GM' || _rScore.c == 'VC')){
            //   _scSum = _scSum / 2.5;
            // }
            // _v2TotalScore += _arrScRec.length > 0 ? (_scSum/_arrScRec.length) : 0;
          });
          r.avgScoreP = (_v2TotalScore / (10*4))*100;
        }
        else{
          _scores.forEach(_rScore=>{
            var _arrScRec = r.scores.filter(_x=>_x.scoreCode == _rScore.c);
            var _scSum = _arrScRec.reduce((total, current) => total + current.score, 0);
            //console.log('_scSum',_scSum, r);
            if((r.version == 3 || r.version == 3.1) && (_rScore.c == 'SP' || _rScore.c == 'GM' || _rScore.c == 'VC')){
              _scSum = _scSum / 2.5;
            }
            _v2TotalScore += _arrScRec.length > 0 ? (_scSum/_arrScRec.length) : 0;
          });
          r.avgScore = parseFloat(_v2TotalScore.toFixed(2));
          r.avgScoreP = (r.avgScore / 16)*100;
        }
      }
      r.versionTitle = r.version == 3.1 ? 'v3.1' :r.version == 3 ? 'v3.0' : r.version == 2.2 ? 'v2.2' : r.version == 2.1 ? 'v2.1' : r.version == 2 ? 'v2.0' : 'v.1.0';

      if(r.status>0 && r.status<5){
        var _diffMinutes = dayjs.utc().diff(dayjs(r.timestampUtc), 'minutes');
        if(_diffMinutes<15){
          r.isInProgress = true;
        }
      }
    });
    var _list = [...AppHelper.sortList(_res.data, 'tCode', 2)];

    if(AppHelper.sampleEssay()){
      _list = _list.filter(r=>r.essayId != AppHelper.sampleEssay().essayId);
      _list.push(AppHelper.sampleEssay())
    }
    //setEssayList(_list);
    if(autoSelectId && autoSelectId != ''){
      var _recEssay = _list.find(r=>r.essayId == autoSelectId);
      if(_recEssay) onEssaySelect(_recEssay);
      setAutoSelectId('');
    }
  };
const fetchEssayListAndSync = async() => {
  if(!essayList || essayList.length<1) return;
  var _isAnyInProgress = essayList.find(x=>x.isInProgress);
  if(!_isAnyInProgress) return;
  var _res = await AppHelper.apiPost('api/essay/list/v3', {token: oUser.token, profileId: oUser.profileId});
  if(!_res || !_res.status) return;
  if(!_res.data || _res.data.length<1) return;

  var _essayList = [...essayList];
  console.log('res', _res, _essayList);
  const _newList = _res.data;
  var _isAnyInProgress = false;
  _essayList.forEach(r=>{
    if(!r.isSample){
      var _arr = _newList.filter(x=>x.essayId = r.essayId);
      if(_arr.length>0){
        var _newRec = _arr[0];
        console.log('status', _newRec.status, r.status, r.esayId, _newRec.essayId);
        if(_newRec.status != r.status && _newRec.status == 5 && r.isInProgress){
          console.log('status CHANGED', _newRec.status, r.status, r.essayId, _newRec.essayId);
          r.status = 5;
          r.isInProgress = false;

          if(_newRec.scores){
            r.scores = _newRec.scores;
            var _scores = [...scoreCodesV2];
            var _v2TotalScore = 0;
            _scores.forEach(_rScore=>{
              var _arrScRec = r.scores.filter(_x=>_x.scoreCode == _rScore.c);
              const _scSum = _arrScRec.reduce((total, current) => total + current.score, 0);
              _rScore.v = _arrScRec.length > 0 ? (_scSum/_arrScRec.length) : 0;
              _rScore.v = parseFloat(_rScore.v.toFixed(2));
              if(_rScore.c == 'ST'){
                _rScore.v = parseFloat((_rScore.v * 2.5).toFixed(2));
              }
              else if(_rScore.c == 'VC' && _arrScRec.length<2){
                _rScore.v = parseFloat((_rScore.v / 2).toFixed(2));
              }
              _v2TotalScore += _rScore.v;
            });
            r.avgScoreP = (_v2TotalScore / (10*4))*100;
          }

          //setEssayList(_essayList);
        }
        else if(r.status>0 && r.status<5){
          var _diffMinutes = dayjs.utc().diff(dayjs(r.timestampUtc), 'minutes');
          if(_diffMinutes<15){
            r.isInProgress = true;
            _isAnyInProgress = true;
          }
        }
      }
      else{
        console.log('not found');
      }
    }
  });
  setTimeout(() => {
    fetchEssayListAndSync(_essayList);
  }, 1000*5);
}

// useEffect(()=>{
  
//   fetchEssayList();
// }, [refreshList]);
// useEffect(()=>{
//   fetchEssayListAndSync();
// }, [essayList]);

  const onEssaySelect = (arg)=>{

  }
  const essayAddClick = (arg)=>{

  }
  return (
    <Box sx={{display:'flex'}}>
      <AppTopBar2 />
      <Box backgroundColor="#000"
        component="nav"
        sx={{ width: { md: drawerWidth }, flexShrink: { sm: 0, md:0 }, border:'0 solid red' }}
      >
      <Drawer
        variant="permanent"
        sx={{
          display: { xs: 'none', sm: 'none', md: 'block' },
          //'& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          minWidth:'300px'
        }}
        PaperProps={{
          sx: {
            backgroundColor:'#f1f1f1',
            //backgroundColor: "#f9efdb",
            //color: "red",
            marginTop:'65px',
            width: drawerWidth
          }
        }}
        open
      >
        {/* {drawer} */}
        <HomeDrawer onEssaySelect={onEssaySelect} essayAddClick={essayAddClick} isSa={isSa} />
      </Drawer>
      </Box>
    </Box>
  );
}