import React, {useEffect, useState} from "react";
import { useNavigate } from 'react-router-dom';
import { Button, CircularProgress, Dialog, DialogContent, DialogTitle, FormControl, Grid, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { AppHelper } from "../../AppHelper";
import AppAlert from "./app-alert";

export default function PopSuggestion({open, onClose, essayId}) {
  //const { onClose, open, onSubmit, essayId } = props;
  const [loading, setLoading] = useState(false);
  const [popOpen, setPopOpen] = useState(false);
  const [feedbackData, setFeedbackData] = useState({
	  text: '',
	  files: [],
	  type: '',
	});
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMsg, setAlertMsg] = useState('');
  useEffect(() => {
    setPopOpen(open);
  }, [open]);

  const handleClose = () => {
    if(onClose) onClose();
  };
  const handleSubmit = async () => {
    if(feedbackData.text == ""){
      alert('Feedback is required');
      return;
    }
	  if(feedbackData.type == ""){
      alert('Feedback type is required');
      return;
    }
    if(!essayId || essayId.length<1){
      alert('Invalid Essay');
      return;
    }
    setLoading(true);
	  const images = [];
	  var _url = 'api/feedback';
    var _user = AppHelper.userCacheGet();
	  if(feedbackData.files.length == 0){
		  var _res = await AppHelper.apiPost(_url, {token: _user.token, b64: images, essayId:essayId, feedback:feedbackData.text, feedbackType:feedbackData.type });
			if(!_res || !_res.status){
			  alert('ERROR: Failed to submit feedback!');			  
			  return;
			}
			else
			{
			  setFeedbackData({
				  text: '',
				  files: [],
				  type: '',
				});
        setAlertMsg('Feedback submitted');
        setAlertOpen(true);
			  if(onClose) onClose();
			}
	  }
	  else
	  {
		  let totalSize = 0;
			for (let i = 0; i < feedbackData.files.length; i++) {
				totalSize += feedbackData.files[i].size;
			}
			if (totalSize > 9 * 1024 * 1024) {
				alert('Total size of uploaded files cannot exceed 9MB.');
				return;
			}
	  
		  for (let i = 0; i < feedbackData.files.length; i++) {
			const reader = new FileReader();
			reader.readAsDataURL(feedbackData.files[i]);
			reader.onload = async () => {
			  images.push(reader.result.split(',')[1]);
			  if (images.length === feedbackData.files.length) {
					const feedbackWithImages = { ...feedbackData, images };
					
					var _res = await AppHelper.apiPost(_url, {token: _user.token, b64: images, essayId:essayId, feedback:feedbackData.text, feedbackType:feedbackData.type });
					if(!_res || !_res.status){
					  alert('ERROR: Failed to submit feedback!');			  
					  return;
					}
					else
					{
					  setFeedbackData({
						  text: '',
						  files: [],
						  type: '',
						});
            setAlertMsg('Feedback submitted');
            setAlertOpen(true);
            if(onClose) onClose();
					}
				 
				}
			};
		  }
	  }
    setLoading(false);
  };
  const alertClose = ()=>{
    setAlertOpen(false);
  }

  return (
    <>
    <Dialog onClose={handleClose} fullWidth maxWidth="xs" open={popOpen}>
		  <DialogTitle>Feedback</DialogTitle>
		  <DialogContent>
        <Grid container direction="column" spacing={3}>
          <Grid item mt={1}>
            <FormControl fullWidth variant="outlined" >
              <InputLabel id="feedback-type-label">Feedback Type</InputLabel>
              <Select
                labelId="feedback-type-label"
                value={feedbackData.type}
                onChange={(e) => setFeedbackData({ ...feedbackData, type: e.target.value })}
                label="Feedback Type"
              >
                <MenuItem value={1}>Feedback about this essay evaluation</MenuItem>
                <MenuItem value={2}>New features I would like</MenuItem>
                <MenuItem value={3}>Love this product and would like to chat</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item>
            <TextField
              label="Feedback Text"
              multiline
              rows={4}
              variant="outlined"
              fullWidth
              value={feedbackData.text}
              onChange={(e) => setFeedbackData({ ...feedbackData, text: e.target.value })}
            />
          </Grid>
          <Grid item>
            <input
              type="file"
              accept="image/*"
              multiple
              onChange={(e) => setFeedbackData({ ...feedbackData, files: e.target.files })}
            />
          </Grid>
          <Grid item>
            <Grid container justifyContent="center">
              {loading ? (
                <CircularProgress />
              ) : (
                <Button
                  className="app-btn-bg"
                  onClick={handleSubmit}
                  color="info"
                  variant="contained"
                >
                  Submit
                </Button>
              )}
            </Grid>
          </Grid>
        </Grid>
		  </DialogContent>
		  <Button onClick={handleClose} style={{ position: 'absolute', top: 0, right: 0 }}>
        <CloseIcon />
      </Button>
	  </Dialog>
    <AppAlert
      open={alertOpen}
      onClose={alertClose}
      msg={alertMsg}
    />
    </>
  );
}