import React, {useEffect, useState} from "react";
import { useNavigate } from 'react-router-dom';
import { Alert, Box, Button, CircularProgress, Dialog, DialogContent, DialogTitle, FormControl, Grid, InputLabel, MenuItem, Select, Snackbar, TextField } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { AppHelper } from "../../AppHelper";

export default function AppAlert({open, onClose, msg}) {
  //const { onClose, open, onSubmit, essayId } = props;
  //const [loading, setLoading] = useState(false);
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMsg, setAlerMsg] = useState('');
  useEffect(() => {
    setAlertOpen(open);
  }, [open]);
  useEffect(() => {
    if(!msg) setAlerMsg('');
    else setAlerMsg(msg);
    //console.log('alert msg', msg);
  }, [msg]);

  const alertClose = () => {
    if(onClose) onClose();
  };

  return (
    <Snackbar
      anchorOrigin={{vertical: 'top', horizontal: 'center',}}
      autoHideDuration={5000}
      open={alertOpen}
      onClose={alertClose}
      message={alertMsg}
    >
      <Alert
        onClose={alertClose}
        severity="info"
        variant="filled"
        sx={{ width: '100%', backgroundColor:'white', color:'#333', border:'1px solid #efefef' }}
      >
        {alertMsg}
      </Alert>
    </Snackbar>
  );
}