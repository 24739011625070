import React, { useState, useEffect } from "react";
import { Avatar, Box, Button, Container, FormControl, 
  IconButton, InputLabel, Paper, 
  List, ListItem, ListItemAvatar, ListItemButton, ListItemIcon, ListItemText, MenuItem, Divider, Select
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import PermPhoneMsgIcon from '@mui/icons-material/PermPhoneMsg';
import PublishedWithChangesIcon from '@mui/icons-material/PublishedWithChanges';
import { AppHelper } from '../AppHelper';
import AppTopBar from './common/app-top-bar';
import AppFooterFeedback from './common/footer-feedback';
import AppDataPolicy from './common/app-data-policy';

export default function PageExercise() {
  const navigate = useNavigate();
  const oUser = AppHelper.userCacheGet();  
  const [submitted, setSubmitted] = useState(false);

  const questions = [
    {
      parts: [
        { text: "The" },
        { choices: ["them", "book", "books", "its"], answer: "books" },
        { text: "are on the shelf." }
      ]
    },
    {
      parts: [
        { choices: ["This", "That", "These", "Those"], answer: "These" },
        { text: "pencils belong to me." }
      ]
    },
    {
      parts: [
        { text: "I live in" },
        { choices: ["city", "it", "Singapore", "its"], answer: "Singapore" },
        { text: "." }
      ]
    },
    {
      parts: [
        { choices: ["What", "Who", "Which", "Whose"], answer: "Whose" },
        { text: "bag is this?" }
      ]
    },
    {
      parts: [
        { text: "The" },
        { choices: ["bottle", "water", "it", "its"], answer: "bottle" },
        { text: "is on the table." }
      ]
    },
    {
      parts: [
        { text: "The students who completed the project on time" },
        { choices: ["theirs", "they", "them", "themselves"], answer: "themselves" },
        { text: "will be rewarded with a special outing." }
      ]
    },
    {
      parts: [
        { text: "The book belongs to the teacher, and" },
        { choices: ["she", "her", "hers", "herself"], answer: "she" },
        { text: "will be giving it back to the student tomorrow." }
      ]
    },
    {
      parts: [
        { text: "The artist," },
        { choices: ["which", "whom", "whose", "who"], answer: "whose" },
        { text: "paintings are famous worldwide, will be speaking at the event next week." },
        { choices: ["His", "Himself", "He", "Him"], answer: "He" },
        { text: "will share insights into his creative process." }
      ]
    },
    {
      parts: [
        { text: "The" },
        { choices: ["its", "rice", "grain", "it"], answer: "rice" },
        { text: "that was spilled on the floor has been cleaned up by the janitor." }
      ]
    },
    {
      parts: [
        { text: "The students did the work" },
        { choices: ["themselves", "themself", "them", "theirs"], answer: "themselves" },
        { text: "without any help from their teachers." }
      ]
    },
    {
      parts: [
        { text: "The scientists," },
        { choices: ["which", "who", "whom", "whose"], answer: "whose" },
        { text: "groundbreaking discoveries have changed the world, deserve recognition for their contributions. However," },
        { choices: ["they", "them", "theirs", "themselves"], answer: "they" },
        { text: "often work in obscurity." }
      ]
    },
    {
      parts: [
        { text: "The" },
        { choices: ["it", "children", "child", "them"], answer: "children" },
        { text: "are playing happily in the park, but" },
        { choices: ["themselves", "they", "them", "theirs"], answer: "they" },
        { text: "should be cautious of strangers." }
      ]
    },
    {
      parts: [
        { text: "Although the book is" },
        { choices: ["my", "me", "mine", "I"], answer: "mine" },
        { text: ", I am willing to share" },
        { choices: ["him", "it", "its", "his"], answer: "it" },
        { text: "with my friend." }
      ]
    },
    {
      parts: [
        { text: "The company has launched" },
        { choices: ["its", "it's", "their", "theirs"], answer: "its" },
        { text: "new product, and" },
        { choices: ["its", "it’s", "it", "they"], answer: "it" },
        { text: "has been well-received by customers." }
      ]
    },
    {
      parts: [
        { text: "Having completed" },
        { choices: ["hers", "her", "she", "herself"], answer: "her" },
        { text: "tasks for the day, the employee decided to take a break." },
        { choices: ["Herself", "Her", "Hers", "She"], answer: "She" },
        { text: "deserved a rest after working diligently." }
      ]
    }
  ];

  const [answers, setAnswers] = useState(
	  questions.map((question) => question.parts.map((part) => part.choices ? part.choices[0] : ""))
	);

  const handleAnswerChange = (qIndex, pIndex, value) => {
    const newAnswers = [...answers];	
    newAnswers[qIndex][pIndex] = value;
    setAnswers(newAnswers);
  };

  const handleSubmit = () => {
    let score = 0;
	let questionScore = 0;
    questions.forEach((question, qIndex) => {
		  question.parts.forEach((part, pIndex) => {
			if (part.choices && answers[qIndex][pIndex] === part.answer) {
			  score++;
			}
		  });
		});
	let totalChoices = questions.reduce((acc, question) => acc + question.parts.filter(part => part.answer).length, 0);
    setSubmitted(true);
    alert(`Your score: ${score}/${totalChoices}`);
  };

  return (
	<div>
      <AppTopBar />
		<div style={{ padding: "20px" }}>
      <Box sx={{fontSize:'2rem', fontWeight:'bold'}}>English Exercise: Nouns and Pronouns</Box>
      <Box sx={{fontSize:'1.3rem', fontWeight:'bold', marginBottom:'1rem'}}>Version 1</Box>
		  {questions.map((question, qIndex) => (
			<div key={qIndex} className="question" style={{ marginBottom: "20px" }}>
			  {question.parts.map((part, pIndex) => (
				  <React.Fragment key={pIndex}>
				  {part.text && <span>{part.text} </span>}
				  {part.choices && (
					 <select
						  value={answers[qIndex][pIndex] || ""}
						  onChange={(e) => handleAnswerChange(qIndex, pIndex, e.target.value)}
						  style={{
							margin: "0 5px",
							backgroundColor: submitted && part.answer === answers[qIndex][pIndex]
							  ? 'lightgreen'
							  : (submitted ? 'salmon' : 'white')
						  }}
						>
							{part.choices.map((choice, choiceIndex) => (
						<option key={choiceIndex} value={choice}>
						  {choice}
						</option>
					  ))}
					</select>
				  )}
				</React.Fragment>
			  ))}
			</div>
		  ))}
			 
		  <Button sx={{backgroundColor:'rgb(99, 136, 137)', ":hover":{backgroundColor:'rgb(157, 188, 152)'}}} onClick={handleSubmit} color="info" variant="contained">{'SUBMIT'}</Button>
		</div>
	</div>
  );
}
