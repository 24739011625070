import { createContext, useContext, useState, useEffect } from 'react';
//import { auth } from './firebase';
import { AppHelper } from './AppHelper';

const UserContext = createContext();

export const useUser = () => {
  return useContext(UserContext);
};

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null);

  useEffect(() => {
    if(!user){
      var _user = AppHelper.userCacheGet();
      if(!_user) return;
      setUser(_user);
      //console.log("^^^^^ user context", _user);
    }
    // const unsubscribe = auth().onAuthStateChanged((authUser) => {
    //   if (authUser) {
    //     setUser(authUser);  // User is signed in
    //   } else {
    //     setUser(null);  // User is signed out
    //   }
    // });

    return () => {
      //unsubscribe();
    };
  }, []);

  const getUser = ()=>{
    //console.log('^^^^^ getUser');
    var _user = AppHelper.userCacheGet();
    if(!_user) return;
    setUser(_user);
    //console.log('^^^^^ getUser', _user);
  }

  return (
    <UserContext.Provider value={{user, getUser}}>{children}</UserContext.Provider>
  );
};