//import logo from './logo.svg';
import './App.css';
import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from 'react-router-dom';
//import { db } from "./firebase";
//import { auth } from './firebase';
import { Container, Snackbar } from '@mui/material';
import { AppBar, Box, Button, Card, CardActions, CardContent, Grid, Toolbar, Typography } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import { GoogleLogin } from '@react-oauth/google';

import AppTopBar from './components/common/app-top-bar';
import AppDataPolicy from './components/common/app-data-policy';

import Fab from '@mui/material/Fab';
import { AppHelper } from './AppHelper';
import SpaCheck from './components/common/spa-check';
const configJson = require('./config.json');
// const pageCss ={ fab: { width: "880px", height: "880px" } };

export default function Offline() {

  return (
    <>
      <AppTopBar />
      <React.Fragment>
      <Grid container spacing={1} mt={3}>
        <Grid item xs={12} m={3}>
          <Card variant="outlined">
            <CardContent>
            <Typography variant="h5" component="div" textAlign={'center'}>
              Down for Maintenance
            </Typography>
            <Typography sx={{ mb: 1.5 }} color="text.secondary" textAlign={'center'}>
              &nbsp;
            </Typography>
            <Typography variant="body2" textAlign={'center'} fontWeight={'bold'}>
              We'll be back soon!
              {/* <br />
              {'"a benevolent smile"'} */}
            </Typography>
            </CardContent>
            <CardActions>
              {/* <Button onClick={back2Home} size="small">Back to Home</Button> */}
            </CardActions>
          </Card>
        </Grid>
      </Grid>
    </React.Fragment>
    </>
  );
}