import React, {useEffect, useState} from "react";
import { useNavigate } from 'react-router-dom';
import { Box, Button, CircularProgress, Dialog, DialogContent, DialogTitle, FormControl, Grid, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { AppHelper } from "../../AppHelper";

export default function PopInProgress({open, onClose, onSave, recEssay}) {
  //const { onClose, open, onSubmit, essayId } = props;
  const [loading, setLoading] = useState(false);
  const [popOpen, setPopOpen] = useState(false);
  useEffect(() => {
    setPopOpen(open);
  }, [open]);
  useEffect(() => {
    //console.log('open status changed');

  }, [recEssay]);

  const handleClose = () => {
    if(onClose) onClose();
  };
  const onNewEssay = ()=>{
    console.log('onNewEssay');
    window.location.reload();
  }

  return (
    <Dialog onClose={handleClose} fullWidth maxWidth="xs" open={popOpen}>
		  <DialogTitle>In Progess</DialogTitle>
		  <DialogContent>
        <Box style={{textAlign:'center'}} mt={1}>
          We are working on your submission.
        </Box>
        <Box style={{textAlign:'center'}} mt={3}>
          <Button sx={{backgroundColor:'rgb(99, 136, 137)', ":hover":{backgroundColor:'rgb(157, 188, 152)'}}} onClick={onNewEssay} color="info" variant="contained">Try a New Essay</Button>
        </Box>
		  </DialogContent>
		  <Button onClick={handleClose} style={{ position: 'absolute', top: 0, right: 0 }}>
        <CloseIcon />
      </Button>
	  </Dialog>
  );
}