import React, { createContext, useContext, useEffect, useState } from 'react';
import { AppHelper } from '../AppHelper';

const UserContext = createContext();

const UserProviderNew = ({ children }) => {
  const [oUser, setOUser] = useState(null);

  const [saProfileList, setSaProfileList] = useState([]);
  const [saProfileListReady, setSaProfileListReady] = useState(false);

  useEffect(() => {
    if(!oUser){
      var _user = AppHelper.userCacheGet();
      if(!_user) return;
      setOUser(_user);
      //console.log("^^^^^ user context new", _user);
    }
  },[]);
  const getUser = ()=>{
    var _user = AppHelper.userCacheGet();
    if(!_user) return;
    setOUser(_user);
    //console.log("^^^^^ user context set", _user);
    return _user;
  }

  const saProfileListLoad = async ()=>{
    var _user = oUser;
    if(!oUser) _user = getUser();
    if(!_user) return;
    var _res = await AppHelper.apiPost("api/account/list/sa", { token: _user.token });
    if(!_res || !_res.status) return;
    // _res.data.forEach((r, i) => {
    //   var _ts = dayjs(r.createdOn);
    //   r.i = i;
    //   r.tCode = _ts.format("YYYYMMDDHHmmss");
    //   r.tTitle = `` + _ts.format(`DD MMM 'YY HH:mm`);
    //   r.essays = argEssays.filter((e) => e.profileId == r.profileId);
    //   r.essayCount = r.essays.length;
    //   r.createdDate = _ts.toDate();
    // });
    setSaProfileList(_res.data);
    setSaProfileListReady(true);
  }
  // const login = () => {
  //   // Example: Set oUser when logging in
  //   setOUser({ id: 1, username: 'exampleuser' });
  // };

  return (
    <UserContext.Provider value={{ oUser, getUser, saProfileList, saProfileListLoad, saProfileListReady }}>
      {children}
    </UserContext.Provider>
  );
};

const useUserContext = () =>{
  return useContext(UserContext);
};

export { UserProviderNew, useUserContext, UserContext };