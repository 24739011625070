import React, { useState, useRef } from "react";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { Button, Grid, CircularProgress } from '@mui/material';

export default function Dev02() {
  const [loading, setLoading] = useState(false);
  const fileInputRef = useRef(null);
  const [selectedImageBase64, setSelectedImageBase64] = useState("");
  const [selectedHighlightedImageBase64, setSelectedHighlightedImageBase64] = useState("");

  const handleBrowse = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = async (event) => {
    const file = event.target.files[0];

    if (file) {
      try {
        setLoading(true);

        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          const base64 = reader.result.split(',')[1];
          console.log(base64);

          // Simulate long processing time with setTimeout
          setTimeout(() => {
            setSelectedImageBase64(base64);
            setSelectedHighlightedImageBase64(base64);
            setLoading(false);
          }, 1000);
        };
      } catch (error) {
        setLoading(false);
        console.error('Error converting image to base64:', error);
      }
    }
  };

  return (
    <>
      <Grid container direction="column">
        <Grid item>
          <Grid container>
            <Grid item sx={{ marginLeft: "30px", marginRight: "30px", marginTop: "30px" }}>
              <input ref={fileInputRef} type="file" accept="image/jpeg,image/png,image/bmp" style={{ display: 'none' }} onChange={handleFileChange} />
              <Button startIcon={<CloudUploadIcon />} sx={{ marginBottom: '8px' }} onClick={handleBrowse}>Upload</Button>
            </Grid>
          </Grid>
        </Grid>
        {loading && (
          <Grid item sx={{ marginLeft: "30px", marginTop: "30px" }}>
            <CircularProgress />
          </Grid>
        )}
        {selectedImageBase64 && (
          <Grid item>
            <Grid container>
              <Grid item sx={{ marginLeft: "30px", marginTop: "30px" }}>
                <img src={`data:image/jpeg;base64,${selectedImageBase64}`} alt="Uploaded" />
              </Grid>
            </Grid>
          </Grid>
        )}
        {selectedHighlightedImageBase64 && (
          <Grid item>
            <Grid container>
              <Grid item sx={{ marginLeft: "30px", marginTop: "30px" }}>
                <img src={`data:image/jpeg;base64,${selectedHighlightedImageBase64}`} alt="Highlighted" />
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
    </>
  );
}
