import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import AppTopBar2 from './common/app-top-bar2';
import AppTopBar from './common/app-top-bar';
import AppLoading from './common/app-loading';
import { Button, Box } from '@mui/material';
import { AppHelper } from '../AppHelper';
import EssayView from './essay/essay-view';
import SpaCheck from './common/spa-check';

export default function PageRejectInvite() {
  
  const navigate = useNavigate();
  const oUser = AppHelper.userCacheGet();
  const { inviteId } = useParams();
  const [searchParams] = useSearchParams();
  const browseSource = searchParams.get('source');
  
  const [isLoading, setIsLoading] = useState(true);

  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [viewMode, setViewMode] = React.useState('VIEW');
 
  
  const [profileId, setProfileId] = useState('');
  const [isSa, setIsSa] = React.useState(false);
  

  
  
  useEffect(() => {
    AppHelper.apiAuditPost("P-CI", "");
  }, []);

  useEffect(()=>{
    const rejectInvite = async()=>{
      setIsLoading(true);
      var _res = await AppHelper.apiPost('api/tuiton/cancel', {inviteId:inviteId});
      setIsLoading(false);
      if(!_res || !_res.status || !_res.data){
        alert(_res.message);
        
      }
      
	  navigate("/home");
    }
   
    rejectInvite();
    
  }, [inviteId]);
 
  const mobileOpenClick = (arg)=>{
    setMobileOpen(!mobileOpen);
  };
  
 
  return (
    <Box sx={{display:'flex'}}>
      <AppTopBar2 mobileOpen={mobileOpen} mobileOpenClick={mobileOpenClick} hideAttr={true} />
      {isLoading? (
        <AppLoading />
      ):(
      <>
      <Box
        component="main"
        sx={{ flexGrow: 1, p: 3, 
          //width: { md: `calc(100% - ${drawerWidth}px)` },
          width: '100%',
          marginTop:'50px' 
        }}
      >
       
      </Box>
      </>
      )}
      {/* <SpaCheck></SpaCheck> */}
    </Box>
  );
}
