//import { register } from 'service-worker.js';

import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { UserProvider } from './UserContext';
//import { PropertyProvider } from './contexts/context-property';
import { EssayProvider } from './contexts/context-essay';
import { UserProviderNew } from './contexts/context-user';
import { LocalizationProvider } from '@mui/x-date-pickers';
//import { GoogleOAuthProvider } from '@react-oauth/google';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'

import './index.css';
import App from './App';
import Offline from './Offline';

//import * as serviceWorkerRegistration from './serviceWorkerRegistration';
// import Login from './components/login';
import PageDev from './components/dev';
import PageFeedback from './components/feedback';
import PageEssaySharePrint from './components/essay-share-print';
import PageQuestion from './components/question';
import PageHome from './components/home';
import PageProfile from './components/profile';
import EssayScoreRules from './components/essay/essay-score-rules';
import PageExercise from './components/exercise';
import PageEssayShare from './components/essay-share';
import reportWebVitals from './reportWebVitals';
import PageSaEssayList from './components/sa-essay-list';
import PageSaEssayLists from './components/sa-essay-lists';
import PageSaEssayListV2 from './components/sa-essay-list-v2';
import PageEssayDetail from './components/sa-essay-detail';
import PageSaUserLogList from './components/sa-user-log-list';
import PageSaUserLogListV2 from './components/sa-user-log-list-v2';
import PageSaFeedbackList from './components/sa-feedback-list';
import PageQuestionList from './components/question-list';
import PageSaEssaySubmit from './components/sa-essay-submit';
import PageAcceptInvite from './components/accept-invite';
import PageRejectInvite from './components/reject-invite';
import PageTutorInvite from './components/tutor-invite'


import Dev01 from './components/dev/dev-01';
import Dev02 from './components/dev/dev-02';
import PageHomeTest from './components/home-test';

const isOffline = false;
ReactDOM.createRoot(document.getElementById('root')).render(
  <Router>
    {/* <GoogleOAuthProvider clientId="114727211497-4vonbveq8tdskfr3sdcsuhdm10qpebos.apps.googleusercontent.com"
      redirect_uri="https://accounts.google.com/o/oauth2/v2/auth?client_id=114727211497-4vonbveq8tdskfr3sdcsuhdm10qpebos.apps.googleusercontent.com&redirect_uri=http://localhost:3000&response_type=code&scope=email%20profile%20https://www.googleapis.com/auth/calendar.calendars.readonly%20https://www.googleapis.com/auth/calendar.events.owned&access_type=offline"
    > */}
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <UserProvider>
        <UserProviderNew>
          <EssayProvider>
            <Routes>
              <Route path="/" element={isOffline ? <Offline /> : <App />} />
              <Route path="/dev" element={isOffline ? <Offline /> : <PageDev />} />
              <Route path="/feedback" element={isOffline ? <Offline /> : <PageFeedback />} />
              <Route path="/home" element={isOffline ? <Offline /> : <PageHome />} />
              <Route path="/homeTest" element={isOffline ? <Offline /> : <PageHomeTest />} />
              <Route path="/login" element={isOffline ? <Offline /> : < App />} />
              <Route path="/login/invite/:inviteId" element={isOffline ? <Offline /> : < App />} />
              <Route path="/profile" element={isOffline ? <Offline /> : <PageProfile />} />
              <Route path="/essay/score/rule" element={isOffline ? <Offline /> : <EssayScoreRules />} />
              <Route path="/exercise" element={isOffline ? <Offline /> : <PageExercise />} />
              <Route path="/sa/essays" element={isOffline ? <Offline /> : <PageSaEssayList />} />
              <Route path="/sa" element={isOffline ? <Offline /> : <PageSaEssayListV2 />} />
			 
			  <Route path="/tutor" element={isOffline ? <Offline /> : <PageTutorInvite />} />
			  <Route path="/saV2" element={isOffline ? <Offline /> : <PageSaEssayListV2 />} />
			  <Route path="/saV2/essay" element={isOffline ? <Offline /> : <PageSaEssayLists />} />
              <Route path="/sa/:pageEssayId" element={isOffline ? <Offline /> : <PageSaEssayList />} />
              <Route path="/essay/detail/:pageEssayId" element={isOffline ? <Offline /> : <PageEssayDetail />} />
              <Route path="/essay/share/:shareId" element={isOffline ? <Offline /> : <PageEssayShare />} />
			  <Route path="/essay/share/print/:shareId" element={isOffline ? <Offline /> : <PageEssaySharePrint />} />
              <Route path="/sa/userLogs" element={isOffline ? <Offline /> : <PageSaUserLogList />} />
              <Route path="/sa/userLogsV2" element={isOffline ? <Offline /> : <PageSaUserLogListV2 />} />
              <Route path="/sa/feedbacks" element={isOffline ? <Offline /> : <PageSaFeedbackList />} />
			  <Route path="/question" element={isOffline ? <Offline /> : <PageQuestionList />} />
			  <Route path="/sa/essay/submit" element={isOffline ? <Offline /> : <PageSaEssaySubmit />} />
              <Route path="/dev/01" element={isOffline ? <Offline /> : <Dev01 />} />
              <Route path="/dev/02" element={isOffline ? <Offline /> : <Dev02 />} />
			  <Route path="/invite/accept/:inviteId" element={isOffline ? <Offline /> : <PageAcceptInvite />} />
			  <Route path="/invite/reject/:inviteId" element={isOffline ? <Offline /> : <PageRejectInvite />} />
            </Routes>
          </EssayProvider>
        </UserProviderNew>
      </UserProvider>
    </LocalizationProvider>
    {/* </GoogleOAuthProvider> */}
  </Router>
);

// const root = ReactDOM.createRoot(document.getElementById('root'));
// root.render(
//   <React.StrictMode>
//     <App />
//   </React.StrictMode>
// );

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// serviceWorkerRegistration.register({
//   enabled: //environment.production && 
//     (navigator['standalone'] || window.matchMedia('(display-mode: standalone)').matches),
// });
reportWebVitals();
