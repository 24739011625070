import React, { useEffect, useState, useRef } from "react";
import { AppHelper } from '../../AppHelper';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { pink, green, blue } from '@mui/material/colors';
import { diffWordsWithSpace } from 'diff';
import {
  Button, Box, Chip, chipClasses, CircularProgress, Dialog, DialogTitle,
  FormControl, FormControlLabel, FormLabel, Grid, IconButton, Fab, InputLabel, MenuItem,
  Radio, RadioGroup, Select,
  Paper, TableContainer, Table, TableHead, TableBody, TableRow, TableCell, Tooltip, Typography,
} from '@mui/material';
import dayjs from "dayjs";
import { EssayHelper } from "../../helpers/essay.helper";
import ScoreSubExpand from "./score-sub-expand";
const relativeTime = require("dayjs/plugin/relativeTime");
dayjs.extend(relativeTime);

export default function PopScore ({
  onClose, open, scoreInfo, essay, allowMark, onMarkDone
}){
  const [record, setRecord] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [recEssay, setRecEssay] = useState(null);
  const gradeList = [
    {n:'P1',v:'P1'},
    {n:'P2',v:'P2'},
    {n:'P3',v:'P3'},
    {n:'P4',v:'P4'},
    {n:'P5',v:'P5'},
    {n:'P6',v:'P6'}
  ];
  const [grade, setGrade] = useState('');
  
  useEffect(()=>{
    if(!open || !essay) return;
    console.log(essay);
    setRecEssay({...essay});
    //fetchShareList();
    setGrade(essay.grade);
  }, [open]);
  useEffect(()=>{
    if(!grade ||grade.length<1) return;
    console.log('essay grade', grade);
    getScoreInfo();
  }, [grade]);
	
  const getScoreInfo = async()=>{
    var _user = AppHelper.userCacheGet();
    var _rulesJson = await EssayHelper.rulesJsonByProfile(_user.token, grade);
    var _arg = {...essay};
    _arg.data = {...essay};
    console.log('pop-score-essay', essay);
    //var _rec = EssayHelper.essayInfoGetForPopScore(_arg, _rulesJson);
    var _rec = EssayHelper.essayInfoGet(_arg, _rulesJson);
    console.log('essay-score-info', _rec);
    _rec.scores.forEach(s=>{
      var _sInfo = essay.scores.find(es=>es.c==s.c);
      if(_sInfo){
        s.sub = [..._sInfo.sub];
        s.eg = [..._sInfo.eg];
      }
    });
    setRecEssay(_rec);
  }
  const fetchShareList = async()=>{
    setIsLoading(true);
    var _user = AppHelper.userCacheGet();
    var _res = await AppHelper.apiPost('api/essay/share/list', {token:_user.token, essayId:essay.essayId});
    setIsLoading(false);
	  if (!_res || !_res.status) {
		  alert('ERROR: Failed to fetch the share list!');
		  return;
	  }
    _res.data.forEach(r=>{
      r.shareUrl = AppHelper.shareUrl(r.shareId);
      var _ts = dayjs(r.createdOn);
      r.tCode = _ts.format(`YYYYMMDDHHmmss`);
      r.tAgo = _ts.fromNow();
    });
    var _records = AppHelper.sortList(_res.data, 'tCode', 2);
    //setRecords(_records);
  };
  const handleClose = () =>{ onClose() };
  const gradeSelect = (event)=>{
    setGrade(event.target.value);
    console.log(essay);
  }
  const scoreExpand = (argScoreI)=>{
    var _rec = {...recEssay};
    _rec.scores[argScoreI].expand = !_rec.scores[argScoreI].expand;
    setRecEssay(_rec);
  }
  return(
    <Dialog onClose={handleClose} open={open} sx={{minWidth:'400px'}}>
      <DialogTitle>SCORE SIMULATION
      <div style={{fontSize:'0.8rem', fontWeight:'normal', opacity:0.6, fontStyle:'italic'}}>See how the scoring changes for this essay according to the grade.</div>
      </DialogTitle>
      <Box padding={'0.5rem'} textAlign={'center'}>
        { !isLoading &&
        <FormControl fullWidth>
          <InputLabel id='lblGrade'>GRADE</InputLabel>
          <Select labelId='lblGrade'
            value={grade}
            onChange={gradeSelect}
            label="GRADE"
            sx={{width:'30rem'}}
          >
            {gradeList.map((item) => (
              <MenuItem key={item.v} value={item.v}>
                {item.n}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        // <FormControl>
        //   <FormLabel id="demo-row-radio-buttons-group-label">Gender</FormLabel>
        //   <RadioGroup
        //     row
        //     aria-labelledby="demo-row-radio-buttons-group-label"
        //     name="row-radio-buttons-group"
        //     value={grade}
        //     onChange={gradeChange}
        //   >
        //     { gradeList.map((g, ig)=>(
        //       <FormControlLabel key={'pop-score-grade-'+ig} value={g.n} control={<Radio />} label={g.n} />
        //     ))
        //     }
        //   </RadioGroup>
        // </FormControl>
        }
      </Box>
      { recEssay &&
      <Box padding={'0.5rem'}>
      { recEssay.scores && recEssay.scores.map((sc, iSc)=>(
        <Grid container mb={0.6} key={'score_' + iSc}>
          <Grid item xs={10} md={10}>
            <Box sx={{border:'1px solid #333', backgroundColor:'#fff', color:'#fff', borderRadius:'50px', display:'flex'}} flexGrow={1}>
              <Box className='app-bg-g' sx={{ borderRadius:'50px', 
                width:`${sc.p}%`, height:'100%', padding:'3px 0 3px 15px', alignItems:'center', display:'flex', fontWeight:'bold' 
              }}>
                {/* {sc.v}/{recEssay.version>=3?'10':'4'} {sc.n} */}
                {sc.v}/10 {sc.n}
              </Box>
            </Box>
          </Grid>
          <Grid item xs={2} md={2} textAlign={'center'}>
            { sc.eg && sc.eg.length > 0 && (
              <>
              <Chip onClick={()=>scoreExpand(iSc)}
                icon={sc.expand ? <RemoveCircleOutlineIcon /> : <AddCircleOutlineIcon />} label={sc.eg.length} 
                className='app-bg-g'
                sx={{
                  color: '#fff', fontWeight: 'bold', width:'65px',
                  [`& .${chipClasses.icon}`]: {
                    color: '#fff'
                  },
                }} 
              />
              </>
            )}
          </Grid>
          { sc.expand && sc.sub && 
            <ScoreSubExpand records={sc.sub} essay={recEssay} allowMark={false} />
          }
          { sc.expand && sc.scoreInfo &&
          <Grid container>
            <Grid item xs={12}>
              <Box>
                { sc.scoreInfo.blocks && sc.scoreInfo.blocks.length>0 &&(
                <>
                  <Box display={'flex'} alignItems={'center'}>
                    <Typography sx={{fontWeight:'bold', color:'#666', marginRight:'0', marginLeft:'1rem'}}>
                    {sc.scoreInfo.blocks[0].title}
                    </Typography>
                    {/* { !sc.scoreInfo.blocks[0].hide && (
                      <Fab onClick={()=> spellingErrorsView(sc)} size="small" color="primary" aria-label="add" sx={{transform: 'scale(0.7)'}}>
                        <OpenInNewIcon />
                      </Fab>
                    )} */}
                  </Box>
                  { sc.scoreInfo.blocks[0].subTitles && sc.scoreInfo.blocks[0].subTitles.map((rSubT, iSubT)=>(
                    <Box>
                      <Grid item xs={12} mt={0}>
                        <Box display={'flex'} alignItems={'center'}>
                          <Typography sx={{fontWeight:'normal', color:'#666', marginRight:'0', marginLeft:'1rem'}}>
                            {rSubT}
                          </Typography>
                        </Box>
                      </Grid>
                    </Box>
                  ))}
                </>  
                )}
                { (!sc.scoreInfo.blocks || sc.scoreInfo.blocks.length<1) &&(
                <Box display={'flex'} alignItems={'center'}>
                  <Typography sx={{fontWeight:'bold', color:'#666', marginRight:'0', marginLeft:'1rem'}}>
                    {sc.scoreInfo.title}
                  </Typography>
                  {/* <Fab onClick={()=> spellingErrorsView(sc)} size="small" color="primary" aria-label="add" sx={{transform: 'scale(0.7)'}}>
                    <OpenInNewIcon />
                  </Fab> */}
                </Box>
                )}
              </Box>
            </Grid>
            { sc.scoreInfo.blocks && sc.scoreInfo.blocks.length>1 &&(
            <Grid item xs={12} mt={0.5}>
              <Box display={'flex'} alignItems={'center'}>
                <Typography sx={{fontWeight:'bold', color:'#666', marginRight:'0', marginLeft:'1rem'}}>
                  {sc.scoreInfo.blocks[1].title}
                </Typography>
                {/* <Fab onClick={()=> spellingErrorsView(sc, 'VCX')} size="small" color="primary" aria-label="add" sx={{transform: 'scale(0.7)'}}>
                  <OpenInNewIcon />
                </Fab> */}
              </Box>
              { sc.scoreInfo.blocks[1].subTitles && sc.scoreInfo.blocks[1].subTitles.map((rSubT, iSubT)=>(
                <Grid item xs={12} mt={0}>
                  <Box display={'flex'} alignItems={'center'}>
                    <Typography sx={{fontWeight:'normal', color:'#666', marginRight:'0', marginLeft:'1rem'}}>
                      {rSubT}
                    </Typography>
                  </Box>
                </Grid>
              ))}
            </Grid>
            )}
            { sc.scoreInfo.subTitles && sc.scoreInfo.subTitles.map((rSubT, iSubT)=>(
              <Grid item xs={12} mt={0}>
                <Box display={'flex'} alignItems={'center'}>
                  <Typography sx={{fontWeight:'normal', color:'#666', marginRight:'0', marginLeft:'1rem'}}>
                    {rSubT}
                  </Typography>
                </Box>
              </Grid>
            ))}
            {/* <Box sx={{maxWidth:'450px', width:'100%', paddingTop:'0.5rem'}}>
              <Grid item xs={12} mt={0.3} sx={{border:'1px solid #333'}}>
                <Grid container>
                  <Grid item xs={6} sx={{borderRight:'1px solid #333', textAlign:'center'}}>
                    <Typography sx={{marginLeft:'1rem'}}>WORD</Typography>
                  </Grid>
                  <Grid item xs={6} sx={{textAlign:'center'}}>
                    <Typography sx={{marginLeft:'1rem'}}>LEVEL</Typography>
                  </Grid>
                </Grid>
              </Grid>
              { sc.scoreInfo.levelItems && sc.scoreInfo.levelItems.map((lt, iLt)=>(
                <Grid item xs={12} mt={0.3} sx={{borderBottom:'1px solid #333', borderLeft:'1px solid #333', borderRight:'1px solid #333'}}>
                  <Grid container>
                    <Grid item xs={6} sx={{borderRight:'1px solid #333', textAlign:'center'}}>
                      <Typography sx={{fontWeight:'normal', color:'#666', marginRight:'0', marginLeft:'1rem'}}>
                        {lt.w}
                      </Typography>
                    </Grid>
                    <Grid item xs={6} sx={{textAlign:'center'}}>
                      <Typography sx={{fontWeight:'normal', color:'#666', marginRight:'0', marginLeft:'1rem'}}>
                        {lt.l}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              ))}
            </Box> */}
          </Grid>
          }
        </Grid>
      ))}
      
      <Grid container mt={3}>
        <Grid item xs={10} md={10}>
          <Box sx={{border:'1px solid #333', backgroundColor:'#fff', color:'#fff', borderRadius:'50px', marginBottom:'4px'}} flexGrow={1}>
            <Box sx={{ borderRadius:'50px', backgroundColor:'#1976d2', padding:'3px 0 3px 15px', width:`${recEssay.avgScoreP_r}%`, height:'100%', alignItems:'center', display:'flex', fontWeight:'bold'  }}>
              {/* Overall {Math.round(recEssay.avgScoreP)}% */}
              Overall {Math.round(recEssay.avgScoreP_r)}%
            </Box>
          </Box>
        </Grid>
        <Grid item xs={2} md={2} textAlign={'center'}>
          {/* <Chip onClick={()=>onScoreView()}
            label={'Score'}
            className='app-bg-g'
            sx={{
              color: '#fff',
              fontWeight: 'bold', marginLeft:'2px',
              [`& .${chipClasses.icon}`]: {
                color: '#fff'
              }
            }}
          /> */}
        </Grid>
      </Grid>
      </Box>
      }
      { isLoading &&
        <div style={{margin:'1rem', textAlign:'center'}}>
          <CircularProgress />
        </div>
      }
    </Dialog>
  );
}