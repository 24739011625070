import React, { useEffect, useState, useRef } from "react";
import { Box, CircularProgress, Grid, LinearProgress, Typography,
} from '@mui/material';
import { AppHelper } from '../../AppHelper';

export default function EssayStatus({essayId, essayFormDone, startCheck}) {
  const [ oUser, setOUser] = React.useState({});
  const [doneEval, setDoneEval] = React.useState(false);
  const [doneCorrect, setDoneCorrect] = React.useState(false);
  const [evalStatusSP, setEvalStatusSP] = React.useState({done:false, v:50});
  const [evalStatusGM, setEvalStatusGM] = React.useState({done:false, v:50});
  const [evalStatusVC, setEvalStatusVC] = React.useState({done:false, v:50});
  const [evalStatusST, setEvalStatusST] = React.useState({done:false, v:50});
  
  useEffect(()=>{
  }, []);
  useEffect(()=>{
    if(!startCheck) return;
    
    //setOUser(_user);
    checkEssayStatus();
  }, [startCheck]);

  const checkEssayStatus = async() =>{
    var _user = AppHelper.userCacheGet();
    var _res = await AppHelper.apiPost('api/essay/detail', {token: _user.token, essayId: essayId});
    if(!_res || !_res.status){
      alert('ERROR: Failed to load!');
      return;
    }
    //console.log('essay-detail', _res.data);
    checkEssayEvalStatus(_res.data.remark);
    if(_res.data.status == 8){  //-- ERROR in EVAL or CORR
      console.log('error: ');
      alert('Submission failed. Please try again!');
      //setVMode('NEW');
      return;
    }
    if(_res.data.status != 5){
      if(_res.data.status == 3)
        setDoneEval(true);
      if(_res.data.status == 4)
        setDoneCorrect(true);

      setTimeout(() => {
        checkEssayStatus(essayId);
      }, 3*1000);
      return;
    }
    setTimeout(() => {
      essayFormDone({code:'NEW', essayId: essayId});
    }, 1.5*1000);
    // var _res = await AppHelper.apiPost('api/essay/detail', {token: _user.token, essayId: essayId});
    // if(!_res || !_res.status){
    //   alert('ERROR: Failed to load!');
    //   return;
    // }
    // console.log('essay-detail', _res.data);
    // checkEssayEvalStatus(_res.data.remark);
    // if(_res.data.status == 8){  //-- ERROR in EVAL or CORR
    //   console.log('error: ');
    //   alert('Submission failed. Please try again!');
    //   // setTimeout(() => {
    //   essayFormDone({code:'ERROR', essayId: essayId});
    //   // }, 1.5*1000);
    //   return;
    // }
    // if(_res.data.status != 5){
    //   if(_res.data.status == 3)
    //     setDoneEval(true);
    //   if(_res.data.status == 4)
    //     setDoneCorrect(true);

    //   setTimeout(() => {
    //     checkEssayStatus(essayId);
    //   }, 3*1000);
    //   return;
    // }
    // setDoneEval(true);
    // setDoneCorrect(true);
    // setTimeout(() => {
    //   essayFormDone({code:'NEW', essayId: essayId});
    // }, 1.5*1000);
  }

  const checkEssayEvalStatus = (argStatus) =>{
    console.log('essay status', argStatus);
    if(!argStatus || argStatus.length!=5) return;
    if(argStatus.charAt(4) == '1') setEvalStatusSP({done:true, v:100});
    if(argStatus.charAt(3) == '1') setEvalStatusGM({done:true, v:100});
    if(argStatus.charAt(2) == '1') setEvalStatusVC({done:true, v:100});
    if(argStatus.charAt(1) == '1') setEvalStatusST({done:true, v:100});
    if(argStatus.charAt(0) == '1') setDoneCorrect(true);
  }

  return(
    <>
      <Grid container mt={1}>
        <Grid item xs={12} mt={1}>
          Processing..
          <LinearProgress color="info" sx={{marginTop:'1rem'}} />
        </Grid>
        <Grid item xs={12} mt={2}>
          <Box display={'flex'} alignItems={'center'}>
            { !evalStatusSP.done &&
              <CircularProgress />
            }
            { evalStatusSP.done &&
              (
                <Box sx={{ position: 'relative', display: 'inline-flex' }}>
                  <CircularProgress variant="determinate" value={100} color="success" />
                  <Box
                    sx={{
                      top: 0, left: 0, bottom: 0, right: 0, position: 'absolute',
                      display: 'flex', alignItems: 'center', justifyContent: 'center',
                    }}
                  >
                    <Typography variant="caption" component="div" color="text.info">{`100%`}</Typography>
                  </Box>
                </Box>
              )
            }
            <Typography sx={{marginLeft:'10px'}}>Spelling</Typography>
          </Box>
        </Grid>
        <Grid item xs={12} mt={2}>
          <Box display={'flex'} alignItems={'center'}>
            { !evalStatusGM.done &&
              <CircularProgress />
            }
            { evalStatusGM.done &&
              (
                <Box sx={{ position: 'relative', display: 'inline-flex' }}>
                  <CircularProgress variant="determinate" value={100} color="success" />
                  <Box
                    sx={{
                      top: 0, left: 0, bottom: 0, right: 0, position: 'absolute',
                      display: 'flex', alignItems: 'center', justifyContent: 'center',
                    }}
                  >
                    <Typography variant="caption" component="div" color="text.info">{`100%`}</Typography>
                  </Box>
                </Box>
              )
            }
            <Typography sx={{marginLeft:'10px'}}>Grammar</Typography>
          </Box>
        </Grid>
        <Grid item xs={12} mt={2}>
          <Box display={'flex'} alignItems={'center'}>
            { !evalStatusVC.done &&
              <CircularProgress />
            }
            { evalStatusVC.done &&
              (
                <Box sx={{ position: 'relative', display: 'inline-flex' }}>
                  <CircularProgress variant="determinate" value={100} color="success" />
                  <Box
                    sx={{
                      top: 0, left: 0, bottom: 0, right: 0, position: 'absolute',
                      display: 'flex', alignItems: 'center', justifyContent: 'center',
                    }}
                  >
                    <Typography variant="caption" component="div" color="text.info">{`100%`}</Typography>
                  </Box>
                </Box>
              )
            }
            <Typography sx={{marginLeft:'10px'}}>Vocabulary</Typography>
          </Box>
        </Grid>
        <Grid item xs={12} mt={2}>
          <Box display={'flex'} alignItems={'center'}>
            { !evalStatusST.done &&
              <CircularProgress />
            }
            { evalStatusST.done &&
              (
                <Box sx={{ position: 'relative', display: 'inline-flex' }}>
                  <CircularProgress variant="determinate" value={100} color="success" />
                  <Box
                    sx={{
                      top: 0, left: 0, bottom: 0, right: 0, position: 'absolute',
                      display: 'flex', alignItems: 'center', justifyContent: 'center',
                    }}
                  >
                    <Typography variant="caption" component="div" color="text.info">{`100%`}</Typography>
                  </Box>
                </Box>
              )
            }
            <Typography sx={{marginLeft:'10px'}}>Structure & Flow</Typography>
          </Box>
        </Grid>
        <Grid item xs={12} mt={2}>
          <Box display={'flex'} alignItems={'center'}>
            { !doneCorrect &&
              <CircularProgress />
            }
            { doneCorrect &&
              (
                <Box sx={{ position: 'relative', display: 'inline-flex' }}>
                  <CircularProgress variant="determinate" value={100} color="success" />
                  <Box
                    sx={{
                      top: 0, left: 0, bottom: 0, right: 0,
                      position: 'absolute',
                      display: 'flex', alignItems: 'center', justifyContent: 'center',
                    }}
                  >
                    <Typography variant="caption" component="div" color="text.info">
                      {`100%`}
                    </Typography>
                  </Box>
                </Box>
              )
            }
            <Typography sx={{marginLeft:'10px'}}>Correction</Typography>
          </Box>
        </Grid>
      </Grid>
    </>
  );
}