import React, {useState, useEffect, useRef} from "react";
import { CircularProgress } from '@mui/material';
import { Alert, Box, Button, Chip, Container, FormControl, Grid, IconButton, InputLabel, Paper, FormControlLabel, Switch, 
	MenuItem, Select, Table, Tooltip, Slider, TableHead, TableBody, TableCell, TableContainer, TableRow, TextField
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import InfoIcon from '@mui/icons-material/Info';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import SaveIcon from '@mui/icons-material/Save';
import { AppHelper } from '../../AppHelper';
import AppTopBar from '../common/app-top-bar';
import { EssayHelper } from "../../helpers/essay.helper";
import { useEssayContext } from "../../contexts/context-essay";
import { useUserContext } from "../../contexts/context-user";
import PopScoreRule1_10 from "./pop-score-rule-1-10";
import PopScoreRuleCopy from "./pop-score-rule-copy";
import AppAlert from "../common/app-alert";

export default function EssayScoreRules() {
  const navigate = useNavigate();
  const [oUser, setOUser] = useState(AppHelper.userCacheGet());
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [profileId, setProfileId] = useState('');
	const [accId, setAccId] = useState('');
  const [gradeOK, setGradeOK] = useState(true);
  const [grade, setGrade] = useState((oUser && oUser.grade) ? oUser.grade : 'P5');
  const [loading, setLoading] = useState(true);  
  const refTopBar = useRef(null);
  const [recRules, setRecRules] = useState([]);
  const [accList, setAccList] = useState([]);
  const [groups, setGroups] = useState([]);
  const [isMobile, setIsMobile] = useState(false);
  const [isTablet, setIsTablet] = useState(false);
  const [saMode, setSaMode] = useState(false);
  const [isSa, setIsSa] = React.useState(false);
  const [gradeList, setGradeList] = useState([]);
	const [popRule1_10, setPopRule1_10Open] = useState(false);
	const [recRule1_10, setRecRule1_10] = useState(null);
	const { loadEssayList } = useEssayContext();
	const { saProfileList, saProfileListLoad, saProfileListReady } = useUserContext();
	const [popRuleCopyOpen, setPopRuleCopyOpen] = useState(false);
  const [gradeSelected, setGradeSelected] = useState('');
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMsg, setAlertMsg] = useState('');
	
  const fetchGrades = async () => {
	 const _gradeList = await EssayHelper.essayGradeGet(oUser.token);
	 setGradeList(_gradeList);
	 console.log('grade', _gradeList);
  };
	const [gradeListView, setGradeListView] = useState({start:0, end:2});
  
  useEffect(()=>{
    var _user = AppHelper.userCacheGet();
    if(_user && _user.grade){
	  fetchGrades();
      setGrade(_user.grade);
    }
  }, []);
	useEffect(() => {    
    fetchRules();
		fetchIAM();
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    }
  }, [saMode, profileId]);
	useEffect(()=>{
		console.log('saProfileList',saProfileList);
		var _accList = [];
		saProfileList.forEach(r=>{
			var _acc = _accList.find(x=>x.accountId == r.accountId);
			if(!_acc) _accList.push({accountId:r.accountId, name:r.name, email:r.email, profiles:[]});
			_acc = _accList.find(x=>x.accountId == r.accountId);
			_acc.profiles.push({profileId:r.profileId, name: r.name});
		});
		setAccList(_accList);
		console.log('accList', _accList);
	}, [saProfileList]);
  useEffect(() => {
    if (gradeList && gradeList.length>0) {
      fetchRules();
    }
  }, [gradeList]);

  const fetchRules = async () => {
    try {
			var _groups = [];
		  setLoading(true);
			var _isMobile = window.innerWidth < 768;
			setIsMobile(_isMobile);
			//console.log('window.innerWidth', window.innerWidth);
			var _isTablet = window.innerWidth>= 768 && window.innerWidth <= 1024;
			setIsTablet(_isTablet);
			
			var _gradeListView = {start:0, end:2};
			if(_isMobile) _gradeListView = {start:0, end:0};
			else if(_isTablet) _gradeListView = {start:0, end:1};
			setGradeListView(_gradeListView);

      if(_isMobile){
        const _data = await getRulesByGrade(grade);
				setRecRules(_data);
				_groups.push({grade:grade, data: _data});
      }
      else{
				for(var i=0; i<gradeList.length; i++){
					const _data = await getRulesByGrade(gradeList[i].v);
					if(i==0) setRecRules(_data);
					_groups.push({
						grade: gradeList[i].v, data: _data, 
						id: gradeList[i].id, 
						v: gradeList[i].v,
						n: gradeList[i].n,
						minW:gradeList[i].minW
					});
				}
      }
			gradeListViewUpdate(_groups, _gradeListView, true);
    } catch (error) {
      console.error('Error fetching rules:', error);
    } finally {
		  setLoading(false); 
		}
  };
	const fetchIAM = async()=>{
		console.log('fetchIAM');
		var _res = await AppHelper.apiPost('api/account/iam', {token: oUser.token});
		if(!_res || !_res.status || !_res.data || !_res.data.isSa){
			saProfileListLoad();
			return;
		}
		setIsSa(true);
		if(!saProfileListReady) saProfileListLoad();
	}
	const getRulesByGrade = async (argGrade)=>{
		if(AppHelper.isEmptyString(profileId))
    	return await EssayHelper.rulesJsonByProfile(oUser.token, argGrade, saMode);
		return await EssayHelper.rulesJsonByProfileId(oUser.token, profileId, argGrade, saMode);
	}
  const gradeListViewUpdate = (argGroups, argView, isFirstLoad=false)=>{
		var _groups = [...argGroups];
		_groups.forEach((g,i)=>{
			g.show = i>=argView.start && i<=argView.end;
			if(isFirstLoad){
				var _gm = g.data.find(d=>d.code == 'GM');
				if(_gm && _gm.data){
					_gm.data.forEach(r=>{
						r.v = 100 - r.v;
					});
				}
				var _sp = g.data.find(d=>d.code == 'SP');
				if(_sp && _sp.data){
					_sp.data.forEach(r=>{
						r.v = 100 - r.v;
					});
				}
				var _vca = g.data.find(d=>d.code == 'VC').data[0];
				if(_vca && _vca.data){
					_vca.data.forEach(r=>{
						r.v = 100 - r.v;
					});
				}
				// var _vcx = g.data.find(d=>d.code == 'VC').data[1];
				// if(_vcx && _vcx.data){
				// 	_vcx.data.forEach(r=>{
				// 		r.v = 100 - r.v;
				// 	});
				// }
			}
		});
		setGroups(_groups);
	}
  function handleWindowSizeChange() {
    setScreenWidth(window.innerWidth);
  }
  const gradeSelect = async(arg) =>{
    setGrade(arg.target.value);
  };
  const go2Home = ()=>{
    navigate('/home');
  };
	const onCopyDone = ()=>{
		fetchRules();
	}
  const onL1Change = (event, newValue, argIndex, iGroup)=>{
    var _groups = [...groups];
		var _data = _groups[iGroup].data;
		updateValueAndNext(argIndex, 100, newValue, _data, 
			argIndex==0?[1,2,3]:
			argIndex==1?[2,3,0]:
			argIndex==2?[3,0,1]:
			[0,1,2]
		);
    //_data[argIndex].v = newValue;///4;
    //_data[argIndex].v0 = newValue;
		
    setGroups(_groups);
  }
  const onL2Change = (event, newValue, argIndex, iGroup, igr)=>{
    var _groups = [...groups];
		var _data = _groups[iGroup].data[argIndex].data;
		var _code = _groups[iGroup].data[argIndex].code;
		if(_code == 'VC'){
			updateValueAndNext(igr, 100, newValue, _data, [igr==0?1:0]);
		}
		else if(_code == 'ST'){
			updateValueAndNext(igr, 100, newValue, _data, 
				igr==0?[1,2,3]:
				igr==1?[2,3,0]:
				igr==2?[3,0,1]:
				[0,1,2]
			);
		}
		else if(_code=='GM' || _code=='SP'){

			_data[igr].v = newValue;
			for(var i=igr-1;i>=0;i--){
				if(_data[igr].v>=_data[i].v)
					_data[i].v = _data[igr].v;
			}
			for(var i=igr+1;i<10;i++){
				if(_data[igr].v<=_data[i].v)
					_data[i].v = _data[igr].v;
			}
			//var _isNext = newValue > _data[igr].v;
			// if(_isNext){
			// 	for(var i=igr+1;i<10;i++){
			// 		//_data[i].v += _data[i].v < 100 ? 1 : 0;
			// 		if(_data[igr].v<=_data[i].v)
			// 			_data[i].v = _data[igr].v-1;
			// 	}
			// }
			// else{
			// 	for(var i=igr-1;i>=0;i--){
			// 		//_data[i].v -= _data[i].v > 0 ? 1 : 0;
			// 		if(_data[igr].v>=_data[i].v)
			// 			_data[i].v = _data[igr].v+1;
			// 	}
			// }
		}
    setGroups(_groups);
  }
  const onL3Change = (event, newValue, argIndex, iGroup, igr, i3r)=>{
    var _groups = [...groups];
		var _data = _groups[iGroup].data[argIndex].data[igr].data;
		var _code = _groups[iGroup].data[argIndex].code;
		if(_code == 'ST'){
			var _vDiff = newValue - _data[i3r].v;
			if(_vDiff<0){
				for(var i=0; i<i3r; i++){
					if(newValue < _data[i].v)
						_data[i].v = newValue;
				}
			}
			else if(_vDiff>0){
				for(var i=i3r; i<_data.length; i++){
					if(newValue > _data[i].v)
						_data[i].v = newValue;
				}
			}
			_data[i3r].v = newValue;
		}
		else if(_code == 'VC'){
			_data[i3r].v = newValue;
			for(var i=i3r-1;i>=0;i--){
				if(_data[i3r].v>=_data[i].v)
					_data[i].v = _data[i3r].v;
			}
			for(var i=i3r+1;i<10;i++){
				if(_data[i3r].v<=_data[i].v)
					_data[i].v = _data[i3r].v;
			}
		}
    setGroups(_groups);
  }
	const updateValueAndNext = (argIndex, argTotal, argNewV, arrData, arrNext)=>{
		var _vDiff = arrData[argIndex].v - argNewV;
		var _total = 0;
		_total += argNewV;
		var _goNext = true;
		arrNext.forEach(x=>{
			if(_goNext){
				var _vNext = arrData[x].v + _vDiff;
				//console.log('_vNext',_vNext);
				if(_vNext<0){
					_vDiff = 0+_vNext;
					arrData[x].v = 0;
				}
				else if(_vNext>argTotal){
					_vDiff = argTotal-_vNext;
					arrData[x].v = argTotal-_vNext-_vDiff;
				}
				else{
					arrData[x].v = _vNext;
					_goNext = false;
				}
			}
		});
		arrData[argIndex].v = argNewV;
	}
	const expandRule = (argIndex)=>{
		var _groups = [...groups];
		_groups[0].data[argIndex].expand = !_groups[0].data[argIndex].expand;
		setGroups(_groups);
	}
	const expandRuleL2 = (argIndex, igr)=>{
		var _groups = [...groups];
		if(!_groups[0].data[argIndex].data[igr].expand) _groups[0].data[argIndex].data[igr].expand = false;
		if(!_groups[0].data[argIndex].data[igr].data || _groups[0].data[argIndex].data[igr].data.length<1) return;
		_groups[0].data[argIndex].data[igr].expand = !_groups[0].data[argIndex].data[igr].expand;
		setGroups(_groups);
	}
	const setSaModeChange = (argEvent)=>{
		console.log(argEvent.target.checked);
		setSaMode(argEvent.target.checked);
	}
	const accGet = async(_profileId)=>{
		var _acc = accList.find(x=>x.profiles && x.profiles.find(p=>p.profileId==_profileId));
		if(_acc) return _acc;
		const _res = await AppHelper.apiPost('api/profile/list', {token: oUser.token});
		if (_res && _res.status && _res.data) {
			return {
				profiles: _res.data
			};
		}
		else return null;
	}
	const reset = async(arg)=>{
		if(!window.confirm('Are you sure you want to reset the rules for "'+ arg.grade + '"?')) return;
		var _profileId = oUser.profileId;
		if(!AppHelper.isEmptyString(profileId)) _profileId = profileId;
		var _acc = await accGet(_profileId);
		if(!_acc){
			alert('ERROR: Profile not found!');
			return;
		}
		var _callList = [];
		_acc.profiles.forEach(p=>{
			_callList.push(AppHelper.apiPost('api/essay/score/rule/reset', {token: oUser.token, grade:arg.grade, profileId:p.profileId}));
		});
		setLoading(true);
		await Promise.all(_callList);
		loadEssayList(oUser);
		setLoading(false);
		// var _res = await AppHelper.apiPost('api/essay/score/rule/reset', {token: oUser.token, grade:arg.grade});
		// if(!_res || !_res.status){
		// 	return;
		// }
		fetchRules();
	}
	
	const updateRules = async () => {
	  if (saMode && !window.confirm('Are you sure you want to update the System-wide rules?')) return;
	  var _profileId = oUser.profileId;
	  if (!AppHelper.isEmptyString(profileId)) _profileId = profileId;
	  var _acc = await accGet(_profileId);
	  if (!_acc) {
			console.log(accList, _profileId);
			alert('ERROR: Profile not found!');
			return;
	  }
		console.log(_acc);
	  var allData = [];

	  groups.forEach(g => {
		var _data = [];
		g.data.forEach(d1 => {
		  _data.push({ scoreType: d1.code, code: 'overall', value: d1.v });
		  if (d1.data && d1.data.length > 0) {
			d1.data.forEach(d2 => {
			  var _v2 = d2.v;
			  if (d1.code == 'GM' || d1.code == 'SP') {
				_v2 = 100 - _v2;
			  }
			  _data.push({ scoreType: d1.code, code: d2.code, value: _v2 });
			  if (d2.data && d2.data.length > 0) {
				d2.data.forEach(d3 => {
				  var _v3 = d3.v;
				  if (d1.code == 'VC' && d2.code == 'VCA') {
					_v3 = 100 - _v3;
				  }
				  _data.push({ scoreType: d1.code, code: d3.code, value: _v3 });
				});
			  }
			});
		  }
		});

		_acc.profiles.forEach(p => {
		  allData.push({ grade: g.grade, data: _data, isSA: saMode ? 1 : 0, profileId: p.profileId });
		});
	  });

	  if (allData.length < 1) {
		alert('No rule to update!');
		return;
	  }

	  setLoading(true);

	  try {
		const response = await AppHelper.apiPost('api/essay/score/rule/update/bulk', { token: oUser.token, rules: allData });
		if (response && response.status) {
		  loadEssayList(oUser);
		  setAlertMsg('Updated!');
		  setAlertOpen(true);
		} else {
		  console.error('Failed to update rules:', response);
		}
	  } catch (error) {
		console.error('Error updating rules:', error);
	  } finally {
		setLoading(false);
	  }
	};

	
	const updateRules2 = async()=>{
		if(saMode && !window.confirm('Are you sure you want to update the System-wide rules?')) return;
		var _profileId = oUser.profileId;
		if(!AppHelper.isEmptyString(profileId)) _profileId = profileId;
		var _acc = accList.find(x=>x.profiles && x.profiles.find(p=>p.profileId==_profileId));
		if(!_acc){
			alert('ERROR: Profile not found!');
			return;
		}
		var _callList = [];
		groups.forEach(g=>{
			var _data = [];
			g.data.forEach(d1=>{
				_data.push({scoreType:d1.code, code:'overall', value:d1.v});
				if(d1.data && d1.data.length>0){
					d1.data.forEach(d2=>{
						var _v2 = d2.v;
						if(d1.code == 'GM' || d1.code == 'SP'){
							_v2 = 100-_v2;
							//console.log('GM-SP-L2', _v2);
						}
						_data.push({scoreType:d1.code, code:d2.code, value:_v2});
						if(d2.data && d2.data.length>0){
							d2.data.forEach(d3=>{
								var _v3 = d3.v;
								if(d1.code == 'VC' && d2.code == 'VCA'){
									_v3 = 100-_v3;
								}
								_data.push({scoreType:d1.code, code:d3.code, value:_v3});
							})
						}
					});
				}
			})
			_acc.profiles.forEach(p=>{
				_callList.push(AppHelper.apiPost('api/essay/score/rule/updateByGrade', {token: oUser.token, grade:g.grade, data:_data, isSA:saMode?1:0, profileId:p.profileId}));
			});
			// var _payload = {token: oUser.token, grade:g.grade, data:_data, isSA:saMode?1:0};
			// if(!AppHelper.isEmptyString(profileId)) _payload.profileId = profileId;
			// if(g.grade == 'P4') console.log(_payload);
			// _callList.push(AppHelper.apiPost('api/essay/score/rule/update', _payload));
			// const _res = await AppHelper.apiPost('api/essay/score/rule/update', {token: oUser.token, grade:g.grade, data:_data, isSA:saMode?1:0});
			// console.log(_res);
      // if (_res && _res.status) {
      // } else {
      //   console.error('Failed to fetch rules:', _res);
      // }
		});
		if(_callList.length<1){
			alert('No rule to update!');
			return;
		}
		setLoading(true);
		await Promise.all(_callList);
		loadEssayList(oUser);
		setLoading(false);

		setAlertMsg('Updated!');
		setAlertOpen(true);
	}
	const copyFromOpen = (arg)=>{
		setGradeSelected(arg.grade);
		setPopRuleCopyOpen(true);
	}
	const gridListBack = ()=>{
		var _view = {...gradeListView};
		if(_view.start>0){
			_view.start--;
			_view.end--;
		}
		console.log(_view);
		setGradeListView(_view);
		gradeListViewUpdate([...groups], _view);
	}
	const gridListNext = ()=>{
		var _view = {...gradeListView};
		if(_view.end<5){
			_view.start++;
			_view.end++;
		}
		setGradeListView(_view);
		gradeListViewUpdate([...groups], _view);
	}
	const openPopScoreRule1_10 = (argIndex, igr)=>{
		const iGroup = 0;
		var _groups = [...groups];
		var _data = _groups[iGroup].data[argIndex].data;
		var _code = _groups[iGroup].data[argIndex].code;
		//console.log('openPopScoreRule1_10', _data, _code, igr);
		setPopRule1_10Open(true);
		setRecRule1_10({i:igr, data:_data});
		console.log({i:igr, data:_data});
	}
	const openPopScoreRule1_10_VCX = (argIndex, igr, i3r)=>{
		const iGroup = 0;
		var _groups = [...groups];
		var _data = _groups[iGroup].data[argIndex].data[igr].data;
		var _code = _groups[iGroup].data[argIndex].code;
		//console.log('openPopScoreRule1_10', _data, _code, igr);
		setPopRule1_10Open(true);
		setRecRule1_10({i:i3r, data:_data});
		console.log({i:i3r, data:_data});
	}
	const popRule1_10Close = ()=>{
		setPopRule1_10Open(false);
	}
	const popRuleCopyClose = ()=>{
		setPopRuleCopyOpen(false);
	}
	const minWordChange = (event, ig)=>{
		console.log(event.target.value);
		var _groups = [...groups];
		var _g = _groups[ig];
		_g.minW = event.target.value;
		setGroups(_groups);
	}
	const minWordSave = async(ig)=>{
		var _groups = [...groups];
		var _g = _groups[ig];
		var _res = await AppHelper.apiPost('api/grade/update', {token: oUser.token, gradeId:_g.id, code:_g.v, name:_g.n, maxWord:_g.minW});
		if(!_res || !_res.status){
			alert('ERROR: Failed to update.');
			return;
		}
		alert('SUCCESS: Configuration updated.');
	}
	const accSelect = (arg)=>{
		var _acc = accList.find(x=>x.accountId==arg.target.value);
		if(!_acc || !_acc.profiles || _acc.profiles.length<1){
			alert('ERROR: Profile not found!');
			return;
		}
		setAccId(arg.target.value);
		setProfileId(_acc.profiles[0].profileId);
	}
  const alertClose = ()=>{
    setAlertOpen(false);
  }
  return (
		<>
    <div>
      <AppTopBar ref={refTopBar}/>
      <Container component="main" maxWidth="m" sx={{ mb: 4 }}>
        <Paper sx={{ 
          my: { 
            xs: 3, md: 5 }, p: { xs: 2, md: 3 }, minHeight:{md:'45vh', xs:'70vh'}, //backgroundColor:'rgb(235, 217, 180)' 
          }} elevation={3}>
					<Grid container>
						{ loading && 
						<Grid item xs={12} textAlign={'center'}>
							<CircularProgress />
						</Grid>
						}
						<Grid item xs={6} md={4}>
							{ isSa && !loading && 
								<FormControlLabel
									control={
										<Switch checked={saMode} onChange={setSaModeChange} name="gilad" />
									}
									label="System-wide"
								/>
							}
						</Grid>
						<Grid item xs={6} md={8}>
							{ isSa &&
							<>
							{ saMode &&
								<Alert severity="warning">Applying System-wide Scoring Rules</Alert>
							}
							{ !saMode && !loading &&
								<Box padding={'0.5rem'} textAlign={'center'}>
									<FormControl fullWidth>
										<InputLabel id='lblGrade'>PROFILE</InputLabel>
										<Select labelId='lblGrade'
											value={accId}
											onChange={accSelect}
											label="PROFILE"
											sx={{width:'30rem'}}
										>
											{accList.map((item) => (
												<MenuItem key={item.accountId} value={item.accountId}>
													{item.name} ({item.email})
												</MenuItem>
											))}
										</Select>
									</FormControl>
								</Box>
							}
							</>
							}
						</Grid>
					</Grid>
          { isMobile &&
          <Box m={.5} pt={1.5}>
            <FormControl fullWidth error={!gradeOK}>
              <InputLabel id='lblGrade'>GRADE</InputLabel>
              <Select labelId='lblGrade'
                value={grade}
                onChange={gradeSelect}
                label="GRADE"
              >
                {gradeList.map((item) => (
                  <MenuItem key={item.v} value={item.v}>
                    {item.n}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
					}
					{ !isMobile &&
					<>
					<Grid container>
						<Grid item xs={6}>
							{ !loading && gradeListView && gradeListView.start > 0 &&
							<IconButton onClick={gridListBack}>
								<NavigateBeforeIcon />
							</IconButton>
							}
						</Grid>
						<Grid flex={'flex'} item xs={6} justifyContent={'flex-end'} textAlign={'right'}>
							{ !loading && gradeListView && gradeListView.end < 5 &&
							<IconButton sx={{margin:0}} onClick={gridListNext}>
								<NavigateNextIcon />
							</IconButton>
							}
						</Grid>
					</Grid>
					</>
					}
		      <Box m={.5} pt={1.5}>
						{ !loading &&
		        <TableContainer component={Paper}>
              <Table aria-label="simple table">
								<TableHead>
									{ !isMobile &&
									<TableRow>
										<TableCell></TableCell>
										{ groups.map((g, ig)=>(
											<React.Fragment key={'grade-col' + ig}>
												{ g.show &&
													<TableCell colSpan={2} sx={{textAlign:'center', backgroundColor:ig%2==0?'#efefef':'', fontWeight:'bold', fontSize:'1.2rem',
														//display: 'flex', justifyContent: 'space-between', alignItems: 'center'
													}}>
														<div style={{float:'left'}}>
														<IconButton sx={{margin:0}} onClick={()=>copyFromOpen(g)}>
															<ContentCopyIcon />
														</IconButton>
															{/* <Button  color="info" variant="text" size="large">Copy From</Button> */}
														</div>
														{g.grade}
														{!saMode && 
														<div style={{float:'right'}}>
															<Button onClick={()=>reset(g)} color="info" variant="text" size="large">RESET</Button>
														</div>
														}
													</TableCell>
												}
											</React.Fragment>
										))}
									</TableRow>
									}
									<TableRow>
										<TableCell></TableCell>
										{ groups.map((g, ig)=>(
											<React.Fragment key={'grade-score-col' + ig}>
												{ g.show && 
												<>
												<TableCell width='15%' sx={{textAlign:'center', backgroundColor:ig%2==0?'#efefef':''}}>Value</TableCell>
												<TableCell sx={{textAlign:'right', backgroundColor:ig%2==0?'#efefef':''}}>Effective Value</TableCell>
												</>
												}
											</React.Fragment>
										))}
									</TableRow>
								</TableHead>
								<TableBody>
								{recRules.map((rec, iRec) => (
									<React.Fragment key={iRec}>
										<TableRow key={`r01-${iRec}`}>
											<TableCell onClick={()=>expandRule(iRec)} sx={{cursor:'pointer'}}>
												<Box sx={{display:'flex'}}>
													{!rec.expand && 
													<ExpandMoreIcon sx={{marginRight:'0.1rem'}}></ExpandMoreIcon>
													}
													{rec.expand && 
													<ExpandLessIcon sx={{marginRight:'0.1rem'}}></ExpandLessIcon>
													}
													{rec.title}
												</Box>
											</TableCell>
											{ groups.map((g,ig)=>(
												<React.Fragment key={'row-sr-data-' + ig}>
													{ g.show && (
													<>
														<TableCell sx={{backgroundColor:ig%2==0?'#efefef':''}}>
															<Slider color="info"
																value={groups[ig].data[iRec].v}
																aria-labelledby="continuous-slider"
																step={1}
																min={0}
																max={100}
																onChange={(event,newValue)=>onL1Change(event,newValue, iRec, ig)}
															/>
														</TableCell>
														<TableCell sx={{textAlign:'right', backgroundColor:ig%2==0?'#efefef':''}}>
															{groups[ig].data[iRec].v}%
														</TableCell>
													</>
													)}
												</React.Fragment>
											))}
										</TableRow>
										{ rec.expand && (
											<>
												{ groups[0].data[iRec].data.map((gr,igr)=>(
													<React.Fragment key={`r02-${iRec}-${igr}`}>
													<TableRow 
														//sx={{ '& > *': { borderBottom: 'unset' }, paddingBottom: '0', marginBottom: 0 }}
													>
														<TableCell onClick={()=>expandRuleL2(iRec, igr)} sx={{cursor:'pointer'}}>
															<Box sx={{display:'flex'}}>
																{/* &#8250; &diams; */}
																&rarr; {gr.title}
																{ gr.pop &&
																	<Tooltip title="view detail">
																		<IconButton onClick={()=>openPopScoreRule1_10(iRec, igr)} area-label="rule detail" sx={{paddingTop:0, paddingBottom:0}}>
																			<InfoIcon  />
																		</IconButton>
																	</Tooltip>
																}
																{gr.data && gr.data.length>0 && 
																<>
																	{ !gr.expand && <ExpandMoreIcon sx={{marginLeft:'0.1rem'}}></ExpandMoreIcon> }
																	{ gr.expand && <ExpandLessIcon sx={{marginLeft:'0.1rem'}}></ExpandLessIcon> }
																</>
																}
															</Box>
														</TableCell>
														{ groups.map((g,ig)=>(
															<React.Fragment key={'row-sr-data-' + ig}>
																{ g.show &&
																<>
																<TableCell sx={{backgroundColor:ig%2==0?'#efefef':'', paddingBottom:0, paddingTop:0}}>
																	<Slider color={(rec.code == 'GM' || rec.code == 'SP') ? "secondary" : "success"}
																		value={groups[ig].data[iRec].data[igr].v}
																		aria-labelledby="continuous-slider"
																		step={1}
																		min={0}
																		max={100}
																		//valueLabelDisplay="on"
																		onChange={(event,newValue)=>onL2Change(event,newValue, iRec, ig, igr)}
																	/>
																</TableCell>
																<TableCell sx={{textAlign:'right', backgroundColor:ig%2==0?'#efefef':''}}>
																	{groups[ig].data[iRec].data[igr].v}%
																	{(groups[ig].data[iRec].code == 'ST' || groups[ig].data[iRec].code == 'VC') &&
																		<div>
																			<Chip label={ (groups[ig].data[iRec].v * (groups[ig].data[iRec].data[igr].v/100)).toFixed(2) + '%' } 
																				sx={{height:'20px'}}
																			/>
																		</div>
																	}
																</TableCell>
																</>
																}
															</React.Fragment>
														))}
													</TableRow>
													{ groups[0].data[iRec].data[igr].expand && 
														groups[0].data[iRec].data[igr].data.map((l3r, i3r)=>(
														<React.Fragment key={`r03-${iRec}-${igr}-${i3r}`}>
														<TableRow sx={{ '& > *': { borderBottom: 'unset' }}}>
															<TableCell sx={{borderBottom:'unset'}}>
																&rarr; {l3r.title}
																{ l3r.pop &&
																	<Tooltip title="view detail">
																		<IconButton onClick={()=>openPopScoreRule1_10_VCX(iRec, igr, i3r)} area-label="rule detail" sx={{paddingTop:0, paddingBottom:0}}>
																			<InfoIcon  />
																		</IconButton>
																	</Tooltip>
																}
															</TableCell>
															{ groups.map((g,ig)=>(
																<React.Fragment key={'row-sr-data-' + ig}>
																	{ g.show &&
																	<>
																	<TableCell sx={{backgroundColor:ig%2==0?'#efefef':'', paddingBottom:0, paddingTop:0}}>
																		{ gr.code == 'VCX' &&
																		<Slider color="secondary"
																			value={groups[ig].data[iRec].data[igr].data[i3r].v}
																			aria-labelledby="continuous-slider"
																			step={.1}
																			min={0}
																			max={10}
																			onChange={(event,newValue)=>onL3Change(event,newValue, iRec, ig, igr, i3r)}
																		/>
																		}
																		{ gr.code != 'VCX' &&
																		<Slider color="secondary"
																			value={groups[ig].data[iRec].data[igr].data[i3r].v}
																			aria-labelledby="continuous-slider"
																			step={1}
																			min={0}
																			max={100}
																			onChange={(event,newValue)=>onL3Change(event,newValue, iRec, ig, igr, i3r)}
																		/>
																		}
																	</TableCell>
																	<TableCell sx={{textAlign:'right', backgroundColor:ig%2==0?'#efefef':''}}>
																		{groups[ig].data[iRec].data[igr].data[i3r].v}%
																	</TableCell>
																	</>
																	}
																</React.Fragment>
															))}
														</TableRow>
														</React.Fragment>
													))}
													</React.Fragment>
												))}
											</>
										)}
									</React.Fragment>
								))}
								{ saMode &&
									<TableRow>
										<TableCell># Minimum Word</TableCell>
										{ groups.map((g,ig)=>(
											<React.Fragment key={'row-minW-'+ig}>
												{ g.show && 
												<TableCell colSpan="2">
													<TextField
														onChange={(event)=>minWordChange(event, ig)}
														label="# Minimum Word"
														value={g.minW}
														InputProps={{endAdornment: <SaveIcon onClick={()=>minWordSave(ig)} sx={{cursor:'pointer'}} />}}
													/>
												</TableCell>
												}
											</React.Fragment>
										))}
									</TableRow>
								}
								{/* {rules.map((entry, index) => (
									<React.Fragment key={index}>
									{entry.data.map((subEntry, subIndex) => (
										<TableRow key={`${index}-${subIndex}`}>
											<TableCell>{entry.code}</TableCell>
											<TableCell>{subEntry.title}</TableCell>
											<TableCell>
												<Slider
													defaultValue={subEntry.value}
													aria-labelledby="continuous-slider"
													valueLabelDisplay="auto"
													step={1}
													min={0}
													max={100}
												/>
											</TableCell>
											<TableCell>
												<Slider
													defaultValue={subEntry.value}
													aria-labelledby="continuous-slider"
													valueLabelDisplay="auto"
													step={1}
													min={0}
													max={100}
												/>
												{subEntry.value}%
											</TableCell>
										</TableRow>
									))}
									</React.Fragment>
								))} */}
								</TableBody>
            	</Table>
          	</TableContainer>
						}
          </Box>
					{!loading && groups.length > 0 && (
					<Box mt={10} display={'flex'} justifyContent={'center'}>
						<Button className="app-btn-bg"
						// sx={{
						//   backgroundColor: 'rgb(99, 136, 137)',
						//   ":hover": { backgroundColor: 'rgb(157, 188, 152)' }
						// }}
						onClick={updateRules}
						color="info"
						variant="contained"
						size="large"
						>
						Update Rules
						</Button>
					</Box>
					)}
					{ !loading && 
          <Box mt={0} display={'flex'} justifyContent={'center'}>
            {/* <Button sx={{backgroundColor:'rgb(99, 136, 137)', ":hover":{backgroundColor:'rgb(157, 188, 152)'}}} onClick={go2Home} color="info" variant="contained" size="large">Back to Home</Button> */}
						<Button onClick={go2Home} color="info" variant="text" size="large">Back to Home</Button>
          </Box>
					}
         
          {/* <Box
            component="footer"
            sx={{ backgroundColor:'#fff', py: 1, px: 2, mt: 'auto', }}
          >
            <Container maxWidth="sm">
              <AppDataPolicy />
            </Container>
          </Box> */}
        </Paper>
        {/* <AppFooterFeedback/> */}
      </Container>
			<PopScoreRule1_10 
				scoreInfo={recRule1_10}
				open={popRule1_10}
				onClose={popRule1_10Close}
			/>
			<PopScoreRuleCopy
				scoreInfo={recRule1_10}
				open={popRuleCopyOpen}
				onClose={popRuleCopyClose}
				gradeTo={gradeSelected}
				gradeRules={groups}
				onCopyDone={onCopyDone}
			/>
    </div>
		<AppAlert
			open={alertOpen}
			onClose={alertClose}
			msg={alertMsg}
		/>
		</>
  );
}
