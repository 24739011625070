import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from 'react-router-dom';
//import { makeStyles } from '@mui/styles';
import AppTopBar from './common/app-top-bar';
import AppLoading from './common/app-loading';
import SpaCheck from './common/spa-check';
import '../css/property-list.css';
import { Avatar, Container, Paper, Button, Divider, Drawer, Hidden,
  List, ListItem, ListItemAvatar, ListItemButton, ListItemIcon, ListItemText, 
  
  Select, Typography, MenuItem, FormControl, InputLabel, TextField, Grid, AppBar, Toolbar, IconButton 
} from '@mui/material';
import Box from '@mui/material/Box';
import Snackbar from '@mui/material/Snackbar';
import { useProperty } from '../contexts/context-property';
import { AppHelper } from '../AppHelper';
import LoadingButton from '@mui/lab/LoadingButton';
import AssignmentIcon from '@mui/icons-material/Assignment';
import MenuIcon from '@mui/icons-material/Menu';


export default function PageDev() {
  const navigate = useNavigate();
  const oUser = AppHelper.userCacheGet();
  const [msgBox, setMsgBox] = React.useState({
    open: false, vertical: 'top', horizontal:'center', msg: ''
  });
  const [feedback, setFeedback] = useState('');
  const [feedbackType, setFeedbackType] = useState('-');
  const [feedbackTypeOK, setFeedbackTypeOK] = useState(true);
  const [menuOpen, setMenuOpen] = useState(false);
  var appVer = '';
  var appVerCheckId;
  //var iframeUrl = 'http://127.0.0.1:4200';
  var iframeUrl = 'https://portal-dev-2020.trakomatic.com/login?auth=token';

  const versionCheck = async ()=>{
    var _res = await AppHelper.api('api/version');
    if(_res && _res.status===1 && _res.data){
      if(appVer === '' || appVer === _res.data.v){
        appVer = _res.data.v;
        console.log('same-v:', appVer);
      }
      else{
        appVer = _res.data.v;
        console.log('v-changed:', appVer);
        if(window && 'caches' in window){
          caches.keys().then((names) => {
          names.forEach(name => {
              caches.delete(name);
          })
          })
        }
        window.location.reload(true);
      }
    }
    if(appVerCheckId) clearTimeout(appVerCheckId);
    appVerCheckId = setTimeout(() => {
      versionCheck();
    }, 1000*10);
  };
  //versionCheck();

  useEffect(()=>{
    
    // if(!property){
    //   navigate('/');
    //   return;
    // }
    // txtName.current.value = property.n;
    // txtSeller.current.value = property.s;
    // if(property.sp && property.sp.length>1){
    //   const _phoneNoParsed = PhoneNumber(property.sp);
    //   if (_phoneNoParsed && _phoneNoParsed.isValid()) {
    //     txtPhoneNo.current.value = _phoneNoParsed.formatNational();
    //   }
    // }
    // txtPostalCode.current.value = property.pc;
    // if(property.address)
    //   setAddress(property.address);
  }, []);
  const onClick1 = ()=>{
    var _iframe1 = document.getElementById('iframe1');
    console.log('iframe1', _iframe1);
    var _msg = {code:'AUTH', token:'YFG1966r3EgragB3sSpVRoNCFp4LWmDn1721804714828'};
    var _res = _iframe1.contentWindow.postMessage(_msg, iframeUrl);
  }


  return (
    <>
    {1===2 && 
    <>
      <Grid container sx={{backgroundColor:'yellow', height:'100%'}}>
        <Grid item xs={12}>
          <AppBar position="relative">
            <Toolbar>
              <Hidden mdUp>
                <IconButton color="inherit" onClick={()=> setMenuOpen(true)}>
                  <MenuIcon />
                </IconButton>
              </Hidden>
            </Toolbar>
          </AppBar>
        </Grid>
        <Grid item sx={{height:'100%'}}>
          <Hidden mdUp>
            <Drawer open={menuOpen} onClose={()=> setMenuOpen(false)}
              PaperProps={{
                style: {
                  overflow: "hidden"
                }
              }}
            >
              <div style={{ overflowY: "auto", marginBottom: '60px', minWidth:'300px' }}>
                <div>Drawer xs</div>
                <div>Week 6</div>
                <div>Week 6</div>
              </div>
              <div
                style={{
                  position: "absolute",
                  bottom: 0,
                  right: 0,
                  left: 0,
                  backgroundColor: "white",
                  height:'60px',
                  borderTop: '1px solid rgba(0,0,0,0.12)',
                  display:'flex',
                  alignItems:'center',
                  justifyContent:'center'
                }}
              >
                <Typography onClick={onClick1} variant={"subtitle1"} paddingX={2} paddingY={1}>
                  + Add New
                </Typography>
              </div>
            </Drawer>
          </Hidden>
          <Hidden smDown>
            <div style={{backgroundColor:'#efefef', display:'flex', flexDirection:'column', height:'100%'}}>
              <div style={{ overflowY: "auto", marginBottom: '60px', width:'300px' }}>
                <div>Drawer NOT xs</div>
                {/* <div style={{height:'1000px'}}>Drawer NOT xs</div> */}
                <div>Drawer NOT xs</div>
              </div>
              <div
                style={{
                  // position: "absolute",
                  // bottom: 0,
                  // right: 0,
                  // left: 0,
                  backgroundColor: "white",
                  height:'60px'
                }}
              >
                <Divider />
                <Typography variant={"subtitle1"} paddingX={2} paddingY={1}>
                  + Add New
                </Typography>
              </div>
            </div>
          </Hidden>
        </Grid>
        <Grid item>
          Children
        </Grid>
      </Grid>
      <Button onClick={onClick1}>click me</Button>
      <iframe id="iframe1" src={iframeUrl} style={{width:'90rem', height:'40rem'}}></iframe>
    </>
    }
    </>
  );
}
