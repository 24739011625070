import React, { useState, useEffect, useRef } from "react";
import moment from "moment";
import CssBaseline from "@mui/material/CssBaseline";
import { alpha } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import AppTopBar2 from "./common/app-top-bar2";
import AppLoading from "./common/app-loading";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import CloseIcon from "@mui/icons-material/Close";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import {
  Avatar,
  AppBar,
  CircularProgress,
  Button,
  Box,
  Card,
  CardActions,
  CardContent,
  Chip,
  Fab,
  Container,
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Paper,
  Select,
  Toolbar,
  Typography,
  MenuItem,
  FormControl,
  InputLabel,
  TextField,
  Grid,
  Table,
  TableContainer,
  TableCell,
  TableHead,
  TableBody,
  TableRow,
  TableSortLabel,
} from "@mui/material";
import { visuallyHidden } from "@mui/utils";
import VisibilityIcon from "@mui/icons-material/Visibility";
import LinearProgress from "@mui/material/LinearProgress";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import IconButton from "@mui/material/IconButton";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import PhoneNumber from "libphonenumber-js";
import { AppHelper } from "../AppHelper";
import SpaCheck from "./common/spa-check";
const dayjs = require("dayjs");

export default function PageSaEssaySubmit() {
  const navigate = useNavigate();
  const oUser = AppHelper.userCacheGet();

  const [isLoading, setIsLoading] = useState(false);

  const [isButtonLoading, setIsButtonLoading] = useState(false);

  useEffect(() => {
    //AppHelper.apiAuditPost("P-SA", "");
  }, []);

  const otpRefs = [useRef(), useRef(), useRef(), useRef(), useRef(), useRef()];
  const drawerWidth = 240;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [isClosing, setIsClosing] = React.useState(false);
  const [viewMode, setViewMode] = React.useState("LIST");
  const [refreshList, setRefreshList] = React.useState(false);
  const [recEssay, setRecEssay] = React.useState({});
  const [autoSelectId, setAutoSelectId] = React.useState("");

  useEffect(() => {
    if (!oUser) {
      navigate("/login");
      return;
    }
  }, [oUser, navigate]);

  const handleDrawerClose = () => {
    setIsClosing(true);
    setMobileOpen(false);
  };
  const handleDrawerTransitionEnd = () => {
    setIsClosing(false);
  };

  const handleButtonClick = async () => {
    setIsButtonLoading(true);
    try {
      var _user = AppHelper.userCacheGet();
      var _url = "api/essay/create/withVersion/async";
      const response = await fetch("/payload/essay.json");
      const data = await response.json();
      for (var i = 0; i < data.length; i++) {
        var recEssay = data[i];
        console.log(_user, recEssay);
        var _payload = {
          token: _user.token,
          imgs: [],
          essayId: recEssay.essayId,
          text: recEssay.text,
          topic: recEssay.topic,
          grade: recEssay.grade,
          addScore: 0,
          profileId: _user.profileId,
        };
        _payload.verScore = "v" + recEssay.version;
        _payload.verCorr = "v" + "2.0"; //versionCorr;
        console.log("api call", _url, _payload);
        var _res = await AppHelper.apiPost(_url, _payload);

        if (!_res.status) {
          alert("ERROR");
          return;
        }
      }
      alert("SUCCESS!");
      // Perform any other actions with the data
    } catch (error) {
      alert("Error processing essay.json:");
    } finally {
      setIsButtonLoading(false);
    }
  };

  const mobileOpenClick = (arg) => {
    setMobileOpen(!mobileOpen);
  };
  const back2Home = () => {
    navigate("/");
  };

  return (
    <Box sx={{ display: "flex" }}>
      <AppTopBar2 mobileOpen={mobileOpen} mobileOpenClick={mobileOpenClick} />
      {isLoading ? (
        <AppLoading />
      ) : (
        <>
          <Box
            component="main"
            sx={{
              flexGrow: 1,
              p: 3,
              width: "100%",
              marginTop: "50px",
            }}
          >
            <Box sx={{ mt: 2 }}>
              <Button
                sx={{
                  backgroundColor: "rgb(99, 136, 137)",
                  ":hover": { backgroundColor: "rgb(157, 188, 152)" },
                }}
                variant="contained"
                color="info"
                onClick={handleButtonClick}
                disabled={isButtonLoading}
              >
                {isButtonLoading ? <CircularProgress size={24} /> : "Submit"}
              </Button>
            </Box>
          </Box>
        </>
      )}
    </Box>
  );
}
