import React, { useEffect, useState } from "react";
import { AppHelper } from '../../AppHelper';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import EmailIcon from '@mui/icons-material/Email';
import { pink, green, blue } from '@mui/material/colors';
import {
  Button, Box, Dialog, DialogTitle, IconButton,
  Paper, CircularProgress, Table, TableHead, TableRow, TableCell, Tooltip
} from '@mui/material';
import dayjs from "dayjs";
import PopShareEmail from './pop-share-email';
const relativeTime = require("dayjs/plugin/relativeTime");
dayjs.extend(relativeTime);

export default function PopShare ({
  onClose, open, scoreInfo, essay, allowMark, onMarkDone
}){
  const [records, setRecords] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [oUser, setOUser] = useState(AppHelper.userCacheGet());
  const [popShareEmailOpen, setPopShareEmailOpen] = useState(false);
  const [shareId, setShareId] = useState('');
  
  useEffect(()=>{
    if(!open || !essay) return;
    fetchShareList();
  }, [open]);
	
  const fetchShareList = async()=>{
    setIsLoading(true);
    var _user = AppHelper.userCacheGet();
    var _res = await AppHelper.apiPost('api/essay/share/list', {token:_user.token, essayId:essay.essayId});
    setIsLoading(false);
	  if (!_res || !_res.status) {
		  alert('ERROR: Failed to fetch the share list!');
		  return;
	  }
    _res.data.forEach(r=>{
      r.shareUrl = AppHelper.shareUrl(r.shareId);
      var _ts = dayjs(r.createdOn);
      r.tCode = _ts.format(`YYYYMMDDHHmmss`);
      r.tAgo = _ts.fromNow();
    });
    var _records = AppHelper.sortList(_res.data, 'tCode', 2);
    setRecords(_records);
  };
  const handleClose = () =>{ onClose() };
  const shareAdd = async()=>{
    var _user = AppHelper.userCacheGet();
    var _url = 'api/essay/share'; 
	  var _payload = {token: _user.token, essayId: essay.essayId};
	  setIsLoading(true);
	  var _res = await AppHelper.apiPost(_url, _payload);
    setIsLoading(false);
	  if (!_res || !_res.status || !_res.data || !_res.data.shareId) {
		  alert('ERROR: Failed to add new share!');
		  return;
	  }
    var _shareUrl = AppHelper.shareUrl(_res.data.shareId);
    navigator.clipboard.writeText(_shareUrl);
    fetchShareList();
    alert('New Shared URL Copied to clipboard!');
  }
  const copyUrl = (argUrl)=>{
    navigator.clipboard.writeText(argUrl);
    alert('Copied to clipboard!');
  }
  const onRemove = async(arg)=>{
    var _user = AppHelper.userCacheGet();
    var _res = await AppHelper.apiPost('api/essay/share/remove', {token:_user.token, shareId:arg.shareId});
    setIsLoading(false);
	  if (!_res || !_res.status) {
		  alert('ERROR: Failed to remove the Shared URL!');
		  return;
	  }
    fetchShareList();
  }
  const onEmail = (arg)=>{
    setShareId(arg.shareId);
    setPopShareEmailOpen(true);
    handleClose();
  }
  const popShareEmailClose = ()=>{
    setPopShareEmailOpen(false);
  }
  return(
    <>
    <Dialog onClose={handleClose} open={open} fullWidth>
      <DialogTitle>SHARE</DialogTitle>
      <Box padding={'0.5rem'} textAlign={'center'}>
        { !isLoading &&
        <Button onClick={shareAdd} className="app-btn-bg" color="info" variant="contained" size="large" style={{marginRight:'2px'}}>NEW SHARED LINK</Button>
        }
      </Box>
      { isLoading &&
        <div style={{margin:'1rem', textAlign:'center'}}>
          <CircularProgress />
        </div>
      }
      { !isLoading && records.length>0 &&
      <Table>
        <TableHead>
          <TableRow>
          <TableCell sx={{fontWeight:'bold', textAlign:'center'}}>Time</TableCell>
            <TableCell sx={{fontWeight:'bold'}}>Link</TableCell>
            <TableCell sx={{fontWeight:'bold', textAlign:'center'}}>Action</TableCell>
          </TableRow>
          { records.map((r, i)=>(
            <TableRow key={'row_share_'+i}>
              <TableCell>{r.tAgo}</TableCell>
              <TableCell>
                <Tooltip title="copy shared URL">
                  <IconButton onClick={() => copyUrl(r.shareUrl)} area-label="copy shared URL">
                    <ContentCopyIcon  />
                  </IconButton>
                </Tooltip>
                <Tooltip title="browse shared link">
                  <IconButton href={'/essay/share/' + r.shareId} target="_blank" area-label="browse shared URL">
                    <OpenInNewIcon style={{color:blue[500]}} />
                  </IconButton>
                </Tooltip>
                <Tooltip title="share via Email">
                  <IconButton onClick={() => onEmail(r)} area-label="share vial Email">
                    <EmailIcon style={{color:'#00695c'}} />
                  </IconButton>
                </Tooltip>
                <div style={{fontSize:'0.5rem'}}>
                  {r.shareUrl}
                </div>
              </TableCell>
              <TableCell>
                <Tooltip title="remove shared URL">
                  <IconButton onClick={() => onRemove(r)} area-label="remove shared URL">
                    <RemoveCircleOutlineIcon style={{color:pink[500]}} />
                  </IconButton>
                </Tooltip>
              </TableCell>
            </TableRow>
          ))
          }
        </TableHead>
      </Table>
      }
    </Dialog>
    <PopShareEmail
      shareId={shareId}
      open={popShareEmailOpen} onClose={popShareEmailClose}
      //essay={recEssay}
    />
    </>
  );
}