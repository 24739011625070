import React, {useEffect, useState} from "react";
import { useNavigate } from 'react-router-dom';
import { Button, CircularProgress, Dialog, DialogContent, DialogTitle, FormControl, Grid, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { AppHelper } from "../../AppHelper";

export default function PopComment({open, onClose, onSave, essayId, scoreType, commentText}) {
  //const { onClose, open, onSubmit, essayId } = props;
  const [loading, setLoading] = useState(false);
  const [popOpen, setPopOpen] = useState(false);
  const [feedbackData, setFeedbackData] = useState({
	  text: '',
	  files: [],
	  type: '',
	});
  useEffect(() => {
    setPopOpen(open);
  }, [open]);
  useEffect(() => {
    var _data = {...feedbackData};
    _data.text = commentText;
    setFeedbackData(_data);
  }, [commentText]);

  const handleClose = () => {
    if(onClose) onClose();
  };
  const handleSubmit = async () => {
    if(feedbackData.text == ""){
      alert('Comment is required');
      return;
    }
    if(!essayId || essayId.length<1){
      alert('Invalid Essay');
      return;
    }
    setLoading(true);
	  const images = [];
	  var _url = 'api/comment/post';
    var _user = AppHelper.userCacheGet();
	  if(feedbackData.files.length == 0){
		  var _res = await AppHelper.apiPost(_url, {token: _user.token, essayId:essayId, comment:feedbackData.text, commentType:scoreType });
      setLoading(false);
			if(!_res || !_res.status){
			  alert('ERROR: Failed to submit!'); 
			  return;
			}
			else
			{
			  setFeedbackData({
				  text: '',
				  files: [],
				  type: '',
				});
			  onClose();
        if(onSave) onSave();
			}
	  }
	  else
	  {
		  // let totalSize = 0;
			// for (let i = 0; i < feedbackData.files.length; i++) {
			// 	totalSize += feedbackData.files[i].size;
			// }
			// if (totalSize > 9 * 1024 * 1024) {
			// 	alert('Total size of uploaded files cannot exceed 9MB.');
			// 	return;
			// }
	  
		  // for (let i = 0; i < feedbackData.files.length; i++) {
			// const reader = new FileReader();
			// reader.readAsDataURL(feedbackData.files[i]);
			// reader.onload = async () => {
			//   images.push(reader.result.split(',')[1]);
			//   if (images.length === feedbackData.files.length) {
			// 		const feedbackWithImages = { ...feedbackData, images };
					
			// 		var _res = await AppHelper.apiPost(_url, {token: _user.token, b64: images, essayId:essayId, feedback:feedbackData.text, feedbackType:feedbackData.type });
			// 		if(!_res || !_res.status){
			// 		  alert('ERROR: Failed to submit feedback!');			  
			// 		  return;
			// 		}
			// 		else
			// 		{
			// 		  setFeedbackData({
			// 			  text: '',
			// 			  files: [],
			// 			  type: '',
			// 			});
			// 			alert('Feedback submitted!');	
			// 		  setPopOpen(false);
			// 		}
				 
			// 	}
			// };
		  // }
	  }
    setLoading(false);
  };

  return (
    <Dialog onClose={handleClose} fullWidth maxWidth="xs" open={popOpen}>
		  <DialogTitle>Comment</DialogTitle>
		  <DialogContent>
        <Grid container direction="column" spacing={3}>
          {/* <Grid item>
            <FormControl fullWidth variant="outlined" >
              <InputLabel id="feedback-type-label">Feedback Type</InputLabel>
              <Select
                labelId="feedback-type-label"
                value={feedbackData.type}
                onChange={(e) => setFeedbackData({ ...feedbackData, type: e.target.value })}
                label="Feedback Type"
              >
                <MenuItem value={1}>Feedback about this essay evaluation</MenuItem>
                <MenuItem value={2}>New features I would like</MenuItem>
                <MenuItem value={3}>Love this product and would like to chat</MenuItem>
              </Select>
            </FormControl>
          </Grid> */}
          <Grid item>
            <TextField
              label="Comment Text"
              multiline
              rows={4}
              variant="outlined"
              fullWidth
              value={feedbackData.text}
              onChange={(e) => setFeedbackData({ ...feedbackData, text: e.target.value })}
            />
          </Grid>
          {/* <Grid item>
            <input
              type="file"
              accept="image/*"
              multiple
              onChange={(e) => setFeedbackData({ ...feedbackData, files: e.target.files })}
            />
          </Grid> */}
          <Grid item>
            <Grid container justifyContent="center">
              {loading ? (
                <CircularProgress />
              ) : (
                <Button
                  sx={{ backgroundColor: 'rgb(99, 136, 137)', ":hover": { backgroundColor: 'rgb(157, 188, 152)' } }}
                  onClick={handleSubmit}
                  color="info"
                  variant="contained"
                >
                  Submit
                </Button>
              )}
            </Grid>
          </Grid>
        </Grid>
		  </DialogContent>
		  <Button onClick={handleClose} style={{ position: 'absolute', top: 0, right: 0 }}>
        <CloseIcon />
      </Button>
	  </Dialog>
  );
}