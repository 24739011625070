import React, { useEffect, useState, useRef } from "react";
import { AppHelper } from '../../AppHelper';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { pink, green, blue } from '@mui/material/colors';
import { diffWordsWithSpace } from 'diff';
import {
  Alert, Button, Box, Chip, Dialog, DialogTitle, Grid, IconButton,
  Paper, CircularProgress, TableContainer, Table, TableHead, TableBody, TableRow, TableCell, Tooltip
} from '@mui/material';
import dayjs from "dayjs";
const relativeTime = require("dayjs/plugin/relativeTime");
dayjs.extend(relativeTime);

export default function PopScoreRule1_10 ({
  onClose, open, scoreInfo, essay, allowMark, onMarkDone
}){
  const [isLoading, setIsLoading] = useState(false);
  useEffect(()=>{
    if(!open || !essay) return;
    //fetchShareList();
  }, [open]);
  useEffect(()=>{
    if(!scoreInfo) return;
    console.log('scoreInfo',scoreInfo);
  }, [scoreInfo]);
  
  const handleClose = () =>{ onClose() };
  return(
    <Dialog onClose={handleClose} open={open} sx={{minWidth:'400px'}}>
      <DialogTitle>Score Rule</DialogTitle>
      { scoreInfo &&
      <>
      <Box padding={'0.5rem'} textAlign={'center'} fontWeight={'bold'}>
        {/* <Alert variant="filled" severity="info">
          {scoreInfo.data[scoreInfo.i].title}
        </Alert> */}
        <Chip color="primary" label={scoreInfo.data[scoreInfo.i].title} />
      </Box>
      <Box textAlign={'center'} padding={'0 2rem 1rem 2rem'}>
        { !scoreInfo.data[scoreInfo.i].code.includes('VCX-') &&
        <>
        Correct Word Percent (# Correct Words / # Total Words) <br/>must not be lower than {scoreInfo.data[scoreInfo.i].v}%
        </>
        }
        { scoreInfo.data[scoreInfo.i].code.includes('VCX-') &&
        <>
        <div>
          1 Rare Word = 1 Complexity Point
        </div>
        <div style={{marginBottom:'0.5rem'}}>
          1 Uncommon Word = 0.5 Complexity Point
        </div>
        Complexity Percent (# Complexity Points / # Total Words) <br/>must not be lower than {scoreInfo.data[scoreInfo.i].v}%
        </>
        }
      </Box>
      <Box textAlign={'center'} paddingBottom={'2rem'}>
        <div style={{marginBottom:'0.5rem'}}>
          <Chip label='Example' />
        </div>
        <div>Total number of words: 200</div>
        { !scoreInfo.data[scoreInfo.i].code.includes('VCX-') &&
        <div>Minimum correct words: {200*(scoreInfo.data[scoreInfo.i].v/100)} ({scoreInfo.data[scoreInfo.i].v}%)</div>
        }
        { scoreInfo.data[scoreInfo.i].code.includes('VCX-') &&
        <div>Minimum complexity points: {200*(scoreInfo.data[scoreInfo.i].v/100)} ({scoreInfo.data[scoreInfo.i].v}%)</div>
        }
      </Box>
      </>
      }
      { isLoading &&
        <div style={{margin:'1rem', textAlign:'center'}}>
          <CircularProgress />
        </div>
      }
    </Dialog>
  );
}