import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useParams } from 'react-router-dom';
import AppTopBar2 from './common/app-top-bar2';
import AppTopBar from './common/app-top-bar';
import AppLoading from './common/app-loading';
import { Button, Box } from '@mui/material';
import { AppHelper } from '../AppHelper';
import EssayView from './essay/essay-view';
import SpaCheck from './common/spa-check';

export default function PageEssaySharePrint() {
  
  const navigate = useNavigate();
  const oUser = AppHelper.userCacheGet();
  const { shareId } = useParams();
  
  const [isLoading, setIsLoading] = useState(true);

  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [viewMode, setViewMode] = React.useState('VIEW');
  const [pageEssayId, setPageEssayId] = React.useState('');
  const [recEssay, setRecEssay] = React.useState({});
  const [profileId, setProfileId] = useState('');
  const [isSa, setIsSa] = React.useState(false);
  const [shareOK, setShareOK] = useState(false);
  const [qrValue, setQrValue] = useState('');

  useEffect(()=>{
    // if(!oUser){
    //   navigate('/login');
    //   return;
    // }
  });
  
  useEffect(()=>{
    const fetchIAM = async()=>{
      var _res = await AppHelper.apiPost('api/account/iam', {token: 'oUser.token'});
      if(!_res || !_res.status || !_res.data || !_res.data.isSa){
        return;
      }
      setIsSa(true);
    }
    fetchIAM();
  });
  
  useEffect(() => {
    AppHelper.apiAuditPost("P-SA", "");
  }, []);

  useEffect(()=>{
    const fetchShareInfo = async()=>{
      setIsLoading(true);
      var _res = await AppHelper.apiPost('api/essay/share/get', {shareId:shareId});
      setIsLoading(false);
      if(!_res || !_res.status || !_res.data){
        console.error(_res);
        return;
      }
      setShareOK(true);
      setPageEssayId(_res.data.essayId);
      fetchEssayDetail(_res.data.essayId);
      setQrValue(AppHelper.shareUrl(shareId) + '?source=qrcode');
    }
    const fetchEssayDetail = async(argEssayId) =>{
      setIsLoading(true);
      var _res = await AppHelper.apiPost('api/essay/detail', {token: 'oUser.token', essayId:argEssayId});
      setIsLoading(false);
      if(!_res || !_res.status){
        return;
      }
      var _recEssay = _res.data;
      _recEssay.email = _res.email;
		  _recEssay.student = _res.student;
		
		  setRecEssay(_recEssay);
    };
    
    // const fetchIAM = async()=>{
    //   if(!oUser){
    //     navigate('/login');
    //     return;
    //   }
    //   await AppHelper.apiPost('api/account/iam', {token: oUser.token});
    //   fetchEssayDetail();
    // }
    // fetchIAM();
    //fetchEssayDetail();
    fetchShareInfo();
  }, [shareId]);
 
  const mobileOpenClick = (arg)=>{
    setMobileOpen(!mobileOpen);
  };
  
  const essayViewDone = (arg) =>{    
    setViewMode('VIEW');
  };
  return (
    <Box sx={{display:'flex'}}>
      <AppTopBar2 mobileOpen={mobileOpen} mobileOpenClick={mobileOpenClick} hideAttr={true} printMode={true}/>
      {isLoading? (
        <AppLoading />
      ):(
      <>
      <Box
        component="main"
        sx={{ flexGrow: 1, p: 3, 
          //width: { md: `calc(100% - ${drawerWidth}px)` },
          width: '100%',
          marginTop:'50px' 
        }}
      >
        {
          shareOK &&(
            <>    
            <EssayView isSa={isSa} essayFormDone={essayViewDone} essayId={recEssay.essayId} isShared={true} printMode={true} qrValue={qrValue}></EssayView>
            </>
          )
        }
      </Box>
      </>
      )}
      {/* <SpaCheck></SpaCheck> */}
    </Box>
  );
}
