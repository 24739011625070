import React, { useEffect, useState, useRef } from "react";
import { AppHelper } from '../AppHelper';
import MarkEmailReadIcon from '@mui/icons-material/MarkEmailRead';
import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox';
import SaveIcon from '@mui/icons-material/Save';
import { pink, green, blue } from '@mui/material/colors';
import {
  Button, Box, TextField, Chip, Dialog, DialogTitle, Grid, IconButton,
  Paper, CircularProgress, TableContainer, Table, TableHead, TableBody, TableRow, TableCell, Tooltip
} from '@mui/material';
import dayjs from "dayjs";
const relativeTime = require("dayjs/plugin/relativeTime");
const configJson = require('../config.json?v=20230925');
dayjs.extend(relativeTime);

export default function PopInviteEmail ({
  onClose, open, essay, tuiton
}){
  const [records, setRecords] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [oUser, setOUser] = useState(AppHelper.userCacheGet());
  const [email1, setEmail1] = useState(oUser? (oUser.email1 || ''):'');
  const [email1Sent, setEmail1Sent] = useState(false);
  
  useEffect(()=>{
    if(!open) return;
    setEmail1Sent(false);
    setEmail1("");    
  }, [open]);
  const handleClose = () =>{ onClose() };
 
  const sendEmail = async()=>{
    var recipient = [];
    var _emailCode = 'INVITE';
    
   
    var _user = AppHelper.userCacheGet();
    
    var _payload = {
      token: _user.token, 
      email:email1, 
	  ccEmail:[], 
      code:_emailCode, 
      param:{
		"tuiton":tuiton,
        "link":'https://essayace.ai',
		"acceptLink":configJson.siteUrl,
        "siteUrl":'https://essayace.ai'//configJson.siteUrl
      }
    };
    setIsLoading(true);
    var _res = await AppHelper.apiPost('api/tuiton/invite', _payload);
    setIsLoading(false);
    if (!_res || !_res.status) {
      alert('ERROR: Failed to send email!');
      return;
    }
    //alert('Email sent successfully!');
    setEmail1Sent(true);
    
  
  }
  return(
    <>
    <Dialog onClose={handleClose} open={open}>
      <DialogTitle>INVITE (Email)</DialogTitle>
      <Box m={1}>
        <Grid container m={1}>
          <Grid item xs={8}>
            <TextField
              margin="dense"
              label="Email"
              value={email1}
              type="email"
              fullWidth
              onChange={(e) => setEmail1(e.target.value)}
              
            />
          </Grid>
          <Grid item xs={4} p={0.5} display={'flex'} alignItems={'center'} justifyContent={'center'}>
            {/* <Tooltip title="share via Email">
              <IconButton onClick={() => sendEmail(1)} area-label="share via Email">
                <EmailIcon style={{color:pink[500]}} />
              </IconButton>
            </Tooltip> */}
            {!isLoading && !email1Sent &&
              <Button onClick={()=>sendEmail()} className="app-btn-bg" color="info" variant="contained" size="large" style={{marginRight:'2px'}}
                endIcon={<ForwardToInboxIcon />}
              >
                SEND
              </Button>
            }
            {email1Sent &&
              <MarkEmailReadIcon sx={{color:'rgba(19,130,117,1)'}}/>
            }
          </Grid>
          
        </Grid>
      </Box>
      { isLoading &&
        <div style={{margin:'1rem', textAlign:'center'}}>
          <CircularProgress />
        </div>
      }
    </Dialog>
    </>
  );
}