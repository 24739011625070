import React, { useEffect, forwardRef } from 'react';
import { Button, Dialog, DialogContent, DialogTitle, Grid } from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import CloseIcon from '@mui/icons-material/Close';

const ScanView = forwardRef(({ isOpen, onClose, pages, numPages }, ref) => {
  useEffect(() => {
    // Add any side effect code here
  }, [isOpen]);
  
   const handleClick = (e, imageUrl) => {
    e.preventDefault();
    const newWindow = window.open();
    newWindow.document.write(`<img src="${imageUrl}" alt="Scanned Image"/>`);
  };

  return (
    <Dialog open={isOpen} onClose={onClose} fullWidth maxWidth="xs">
      <DialogTitle>Scan</DialogTitle>
      <DialogContent>
        <Grid container direction="column" spacing={3}>
          {[...Array(numPages)].map((_, index) => (
            <Grid item key={index}>
              <Grid container justifyContent="left">
                {pages[index] !== null && (
                  <Grid item sx={{ marginRight: "30px" }}>
                   <a href="#" onClick={(e) => handleClick(e, `data:image/jpeg;base64,${pages[index]}`)}>                   
                      <img
                        src={`data:image/jpeg;base64,${pages[index]}`}
                        alt={`Page ${index + 1}`}
                        style={{ height: '150px', width: '150px' }}
                      />
                    </a>
                    <br />
                    <span style={{ textAlign: 'center', marginTop: '8px', marginBottom: '18px' }}>{`Page ${index + 1}`}</span>
                  </Grid>
                )}
              </Grid>
            </Grid>
          ))}
        </Grid>
      </DialogContent>
      <Button onClick={onClose} style={{ position: 'absolute', top: 0, right: 0 }}>
        <CloseIcon />
      </Button>
    </Dialog>
  );
});

export default ScanView;
