import React, { useEffect, useState, useRef } from "react";
import { AppHelper } from '../../AppHelper';
import {
  Button, Box, Card, Dialog, DialogTitle, Grid, IconButton,
  Paper, TableContainer, Table, TableHead, TableBody, TableRow, TableCell, Tooltip
} from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import { pink, green } from '@mui/material/colors';
import { diffWordsWithSpace } from 'diff';

export default function PopGMErrors ({
  onClose, open, scoreInfo, essay, allowMark, onMarkDone
}){
  const [gmErrors, setGmErrors] = useState([]);
  useEffect(()=>{
    if(!scoreInfo || !scoreInfo.errors) return;
    setGmErrors(scoreInfo.errors.filter(x=>x.isFalse!=1 || allowMark));
  }, [scoreInfo, allowMark]);
  const getDiffs = (str1, str2) => {
    const differences = diffWordsWithSpace(str1, str2);
    return differences.map((part, index) => {
      const style = {
        color: part.added ? '#1976d2' : part.removed ? '#ef5350' : '#000',
        textDecoration: part.added? 'underline' : part.removed ? 'line-through' : 'none',
        fontWeight: (part.added || part.removed) ? 'bold' : 'normal',
      };
      return <span key={index} style={ style }>{part.value}</span>;
    });
  }
  const handleClose = () =>{ onClose() };
  const copySentence = (arg)=>{
    navigator.clipboard.writeText(arg);
  };
  
  const onFalseClick = async(rowIndex, argValue) => {
	  var _user = AppHelper.userCacheGet();
	  var _url = 'api/score/GM/error/false'; 
	  var _payload = {token: _user.token, essayId: essay.essayId, errorIndex: rowIndex, isFalse:argValue };
	  
	  var _res = await AppHelper.apiPost(_url, _payload);
	  if (!_res || !_res.status) {
      alert('ERROR: Failed to set false!');
      return;
	  }
	  //alert("Success");
    var _err = gmErrors[rowIndex];
    _err.isFalse = argValue;
    var _errorCount = gmErrors.filter(x=>x.isFalse!=1).length;
    if(onMarkDone) onMarkDone(_errorCount);
	};
  
  return(
    <Dialog onClose={handleClose} open={open}>
      <DialogTitle>GRAMMAR MISTAKES</DialogTitle>
      <Box padding={'0.5rem'}>
        { gmErrors && gmErrors.length>0 &&
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 550 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell sx={{fontWeight:'bold'}}>Wrong Sentence</TableCell>
                <TableCell sx={{fontWeight:'bold'}}>Correct Sentence</TableCell>
                <TableCell sx={{fontWeight:'bold'}}>Type</TableCell>
                <TableCell sx={{fontWeight:'bold'}}>Subtype</TableCell>
                <TableCell sx={{fontWeight:'bold'}}></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              { gmErrors.map((row, iRow)=>(
                <TableRow key={`tr_${iRow}_E`} style={{textDecoration:row.isFalse==1?'line-through':''}}>
                  <TableCell>{row.w}</TableCell>
                  <TableCell>
                    {
                      getDiffs(row.w, row.r)
                    }
                    {/* {row.r} */}
                    <ContentCopyIcon onClick={()=>copySentence(row.r)} sx={{marginLeft:'0.5rem', cursor:'pointer', opacity:'0.35', fontSize:'1rem'}} title={'click to copy'}/>
                  </TableCell>
                  <TableCell>{row.type}</TableCell>
                  <TableCell>{row.subType}</TableCell>
                  <TableCell>
                    {/* <Button onClick={() => onFalseClick('GM', iLt)}>False</Button> */}
                    { allowMark && row.isFalse != 1 && 
                    <Tooltip title="Mark as False Positive">
                      <IconButton onClick={() => onFalseClick(iRow, 1)} area-label="Mark as False Positive">
                        <RemoveCircleOutlineIcon style={{color:pink[500]}} />
                      </IconButton>
                    </Tooltip>
                    }
                    { allowMark && row.isFalse == 1 && 
                    <Tooltip title="Mark as Positive">
                      <IconButton onClick={() => onFalseClick(iRow, 0)} area-label="Re-mark as Positive">
                        <TaskAltIcon style={{color:green[500]}} />
                      </IconButton>
                    </Tooltip>
                    }
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        }
        {/* <Grid container>
          <Grid item xs={12}>
            { scoreInfo && scoreInfo.errors && scoreInfo.errors.length>0 && scoreInfo.errors.map((lt, iLt)=>(
              <Grid item xs={12} mt={0.3} sx={{border:'1px solid #333'}}>
                <Grid container>
                  <Grid item xs={4} sx={{borderRight:'1px solid #333', textAlign:'center'}}>
                    <Typography sx={{marginLeft:'1rem'}}>WRONG SENTENCE</Typography>
                  </Grid>
                  <Grid item xs={8} sx={{textAlign:'center'}}>
                    <Typography sx={{marginLeft:'1rem'}}>{lt.w}</Typography>
                  </Grid>
                  <Grid item xs={4} sx={{borderRight:'1px solid #333', textAlign:'center'}}>
                    <Typography sx={{marginLeft:'1rem'}}>CORRECT SENTENCE</Typography>
                  </Grid>
                  <Grid item xs={8} sx={{textAlign:'center'}}>
                    <Typography sx={{marginLeft:'1rem'}}>{lt.r}</Typography>
                  </Grid>
                </Grid>
              </Grid>
            ))}
          </Grid>
          
          <Grid item xs={12} p={2} pt={0} pb={0.5}>
            <Button onClick={handleClose} sx={{width:'100%'}}>CLOSE</Button>
          </Grid>
        </Grid> */}
      </Box>
    </Dialog>
  );
}