import React, { useState, useEffect, useRef } from "react";
import CssBaseline from "@mui/material/CssBaseline";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { alpha } from "@mui/material/styles";
import { CheckCircle, Delete, Block } from "@mui/icons-material";
import { useNavigate, useParams } from "react-router-dom";
import AppTopBar2 from "./common/app-top-bar2";
import AppLoading from "./common/app-loading";
import {
  Avatar,
  AppBar,
  Button,
  Box,
  Card,
  CardActions,
  CardContent,
  Chip,
  Fab,
  Container,
  Divider,
  Drawer,
  TablePagination,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Paper,
  Select,
  Toolbar,
  Typography,
  MenuItem,
  FormControl,
  InputLabel,
  TextField,
  Grid,
  Table,
  TableContainer,
  TableCell,
  TableHead,
  TableBody,
  TableRow,
  TableSortLabel,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { visuallyHidden } from "@mui/utils";
import LinearProgress from "@mui/material/LinearProgress";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import IconButton from "@mui/material/IconButton";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import PhoneNumber from "libphonenumber-js";
import { AppHelper } from "../AppHelper";
import { EssayHelper } from "../helpers/essay.helper";
import EssayForm from "./essay/essay-form";
import EssayView from "./essay/essay-view";
import SpaCheck from "./common/spa-check";
const dayjs = require("dayjs");

function LinearProgressWithLabel(props) {
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Box sx={{ width: "100%", mr: 1 }}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box>
        <Typography
          variant="body2"
          color="text.secondary"
          fontSize={"0.7rem"}
          sx={{
            color: props.recSelected == 1 ? "#fff" : "text.secondary",
          }}
        >
          {`${Math.round(props.value)}%`}
        </Typography>
      </Box>
    </Box>
  );
}
function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// Since 2020 all major browsers ensure sort stability with Array.prototype.sort().
// stableSort() brings sort stability to non-modern browsers (notably IE11). If you
// only support modern browsers you can replace stableSort(exampleArray, exampleComparator)
// with exampleArray.slice().sort(exampleComparator)
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}
const headCellsEssay = [
  { id: "rowIndex", numeric: true, disablePadding: false, label: "#" },
  { id: "grade", numeric: false, disablePadding: false, label: "GRADE" },
  { id: "topic", numeric: false, disablePadding: false, label: "TITLE" },
  { id: "avgScore", numeric: true, disablePadding: false, label: "SCORE" },
  { id: "version", numeric: false, disablePadding: false, label: "VERSION " },
  { id: "vCorr", numeric: false, disablePadding: false, label: "CORRECTION" },
  { id: "tCode", numeric: false, disablePadding: false, label: "SUBMITTED ON" },
  { id: "email", numeric: false, disablePadding: false, label: "SUBMITTED BY" },
  { id: "name", numeric: false, disablePadding: false, label: "PROFILE" },
  { id: "essayId", numeric: false, disablePadding: false, label: "..." },
];


function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  console.log("props.gid", props.gid);
  const headCells = headCellsEssay;

  return (
    <TableHead>
      <TableRow>
        {/* <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              'aria-label': 'select all desserts',
            }}
          />
        </TableCell> */}

        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

export default function PageSaEssayListV2() {
  // const theme = createTheme({
  //   typography: {
  //     fontFamily: 'Noto Sans',
  //   }
  // });
  const navigate = useNavigate();
  const oUser = AppHelper.userCacheGet();
  const { pageEssayId } = useParams();
  const isPhoneVerifed =
    oUser && oUser.phoneNumber && oUser.phoneNumber.length > 0;
  const today = new Date();

  const [isLoading, setIsLoading] = useState(true);
  const [essayList, setEssayList] = useState([]);
  const [profileList, setProfileList] = useState([]);
  const [originalProfileList, setOriginalProfileList] = useState([]);
  

  const otpRefs = [useRef(), useRef(), useRef(), useRef(), useRef(), useRef()];
  const drawerWidth = 240;

  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [isClosing, setIsClosing] = React.useState(false);
  const [viewMode, setViewMode] = React.useState("LIST");
  const [refreshList, setRefreshList] = React.useState(false);
  const [recEssay, setRecEssay] = React.useState({});
  const [autoSelectId, setAutoSelectId] = React.useState("");
  const [countEssay, setCountEssay] = React.useState(0);
  
  const [tblOrderEssay, setTblOrderEssay] = React.useState("desc");
  const [tblOrderByEssay, setTblOrderByEssay] = React.useState("tCode");
  const [filterEssay, setFilterEssay] = React.useState("");

 
 
 
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    AppHelper.apiAuditPost("P-SA", "");
  }, []);

  const scoreCodesV2_sa = [
    {
      c: "GM",
      c2: "grammar",
      n: "Grammar",
      v: 0,
      p: 0,
      eg: [],
      sub: [],
      expand: false,
    },
    {
      c: "SP",
      c2: "spelling",
      n: "Spelling",
      v: 0,
      p: 0,
      eg: [],
      sub: [],
      expand: false,
    },
    {
      c: "VC",
      c2: "vocabulary",
      n: "Vocabulary",
      v: 0,
      p: 0,
      eg: [],
      sub: [],
      expand: false,
    },
    {
      c: "ST",
      c2: "structure",
      n: "Structure",
      v: 0,
      p: 0,
      eg: [],
      sub: [],
      expand: false,
    },
    //{ c:'FL', c2: 'flow', n:'Flow', v:0, p:0 },
  ];

  useEffect(() => {
    if (!oUser) {
      navigate("/login");
      return;
    }
  });

 
 
 
  var _rulesByProfile = {};
  useEffect(() => {
    _rulesByProfile = {};
    const fetchEssayList = async () => {
      essayList.splice(0);
      setIsLoading(true);
      var _res = await AppHelper.apiPost("api/essay/list/sa", {
        token: oUser.token,
      });
      console.log("_res", _res);
      if (!_res || !_res.status) {
        setIsLoading(false);
        return;
      }
      //_res.data = _res.data.filter(x=>x.essayId=='da6b655a-c74a-45e8-b80f-d132e645f089');
      console.log("sa", _res.data);
      _res.data.forEach((r, iRec) => {
        var _ts = dayjs(r.timestampUtc);
        r.rowIndex = 0;
        r.tCode = _ts.format("YYYYMMDDHHmmss");
        r.tTitle = _ts.format(`DD MMM 'YY hh:mm a`).toUpperCase(); //`Week ${_ts.week()} - ` + _ts.format('DD MMM YYYY');
        r.viewTitle = `${r.topic}`;
        r.avgScore = parseFloat(r.avgScore);
        r.avgScoreP = (r.avgScore / 5) * 100;
        r.email = r.ete_profile ? r.ete_profile.email : "-";
        r.name = r.ete_profile ? r.ete_profile.name : "-";
        r.version = "1.0";
        r.vCorr = r.vCorr ? r.vCorr : "-";
        if (r.scores && r.scores.length > 0) {
          r.version =
            r.scores[0].version == "3.10"
              ? "3.1"
              : "3.00"
              ? "3.0"
              : "2.20"
              ? "2.2"
              : r.scores[0].version == "2.10"
              ? "2.1"
              : r.scores[0].version == "2.00"
              ? "2.0"
              : "1.0";
          if (r.version == "2.0" || r.version == "2.1" || r.version == "2.2") {
            var _scores = [...scoreCodesV2_sa];
            var _v2TotalScore = 0;
            _scores.forEach((_rScore) => {
              var _arrScRec = r.scores.filter(
                (_x) => _x.scoreCode == _rScore.c
              );
              const _scSum = _arrScRec.reduce(
                (total, current) => total + current.score,
                0
              );
              _v2TotalScore +=
                _arrScRec.length > 0 ? _scSum / _arrScRec.length : 0;
            });
            r.avgScore = parseFloat(_v2TotalScore.toFixed(2));
            r.avgScoreP = parseFloat(((r.avgScore / 16) * 100).toFixed(2));
          } else if (r.version == "3.0" || r.version == "3.1") {
            var _scores = [...scoreCodesV2_sa];
            var _v2TotalScore = 0;
            _scores.forEach((_rScore) => {
              var _arrScRec = r.scores.filter(
                (_x) => _x.scoreCode == _rScore.c
              );
              var _scSum = _arrScRec.reduce(
                (total, current) => total + current.score,
                0
              );
              if (_rScore.c == "SP" || _rScore.c == "GM" || _rScore.c == "VC") {
                _scSum = parseFloat((_scSum / 2.5).toFixed(2));
              }
              if (_rScore.c == "VC" && _arrScRec.length < 2) {
                _scSum = parseFloat((_scSum / 2).toFixed(2));
              }
              _v2TotalScore +=
                _arrScRec.length > 0 ? _scSum / _arrScRec.length : 0;
            });
            r.avgScore = parseFloat(_v2TotalScore.toFixed(2));
            r.avgScoreP = parseFloat(((r.avgScore / 16) * 100).toFixed(2));
          }
        }
      });
      var _list = [...AppHelper.sortList(_res.data, "tCode", 2)];
      //setEssayList(_list);
      updateWithScoreRules(_list);
      // setTimeout(() => { console.log('essayList', _list); }, 1000);
      // fetchProfileList(_list);
      // fetchAccList();
      // fetchFeedbackList();
      // setCountEssay(_list.length);
      // if(autoSelectId && autoSelectId != ''){
      //   var _recEssay = _list.find(r=>r.essayId == autoSelectId);
      //   if(_recEssay) onEssaySelect(_recEssay);
      //   setAutoSelectId('');
      // }

      // if(pageEssayId){
      //   var _pageEssay = _list.find(r=>r.essayId == pageEssayId);
      //   if(_pageEssay){
      //     essayViewClick(_pageEssay);
      //   }
      // }
    };
    const updateWithScoreRules = async (_list) => {
      if (!_list || _list.length < 1) return;
      setIsLoading(true);
      for (var i = 0; i < _list.length; i++) {
        var r = _list[i];
        if (r.version == "3.0" || r.version == "3.1") {
          if (!_rulesByProfile[r.profileId]) {
            console.log("-- fetching rules -- " + r.profileId);
            _rulesByProfile[r.profileId] = await fetchRules(r.profileId);
          }
          var _rulesJsonList = _rulesByProfile[r.profileId];
          var _rule = _rulesJsonList.find((x) => x.code == r.grade);
          if (_rule) {
            //console.log({data:{grade:r.grade}, score:r.scores}, _rule.data);
            var _recRule = EssayHelper.essayInfoGet(
              { data: { grade: r.grade }, score: r.scores },
              _rule.data
            );
            r.avgScoreP = _recRule.avgScoreP_r;
          }
        } //return;
      }
      setEssayList(_list);
      setIsLoading(false);
      
      setTimeout(() => {
        console.log("essayList", _list);
      }, 1000);
      
      setCountEssay(_list.length);
      if (autoSelectId && autoSelectId != "") {
        var _recEssay = _list.find((r) => r.essayId == autoSelectId);
        if (_recEssay) onEssaySelect(_recEssay);
        setAutoSelectId("");
      }

      if (pageEssayId) {
        var _pageEssay = _list.find((r) => r.essayId == pageEssayId);
        if (_pageEssay) {
          essayViewClick(_pageEssay);
        }
      }
    };
    const fetchRules = async (argProfileId) => {
      const [
        _rulesJsonP1,
        _rulesJsonP2,
        _rulesJsonP3,
        _rulesJsonP4,
        _rulesJsonP5,
        _rulesJsonP6,
      ] = await Promise.all([
        EssayHelper.rulesJsonByProfileId(oUser.token, argProfileId, "P1"),
        EssayHelper.rulesJsonByProfileId(oUser.token, argProfileId, "P2"),
        EssayHelper.rulesJsonByProfileId(oUser.token, argProfileId, "P3"),
        EssayHelper.rulesJsonByProfileId(oUser.token, argProfileId, "P4"),
        EssayHelper.rulesJsonByProfileId(oUser.token, argProfileId, "P5"),
        EssayHelper.rulesJsonByProfileId(oUser.token, argProfileId, "P6"),
      ]);
      var _rulesJsonList = [
        { code: "P1", data: _rulesJsonP1 },
        { code: "P2", data: _rulesJsonP2 },
        { code: "P3", data: _rulesJsonP3 },
        { code: "P4", data: _rulesJsonP4 },
        { code: "P5", data: _rulesJsonP5 },
        { code: "P6", data: _rulesJsonP6 },
      ];
      console.log(_rulesJsonList);
      return _rulesJsonList;
    };
   
    const fetchIAM = async () => {
      if (!oUser) {
        navigate("/login");
        return;
      }
      var _res = await AppHelper.apiPost("api/account/iam", {
        token: oUser.token,
      });
      if (!_res || !_res.status || !_res.data || !_res.data.isSa) {
        setIsLoading(false);
        nonSaView();
        return;
      }
      fetchEssayList();
    };
    fetchIAM();
    //fetchEssayList();
  }, [refreshList]);

  const handleDrawerClose = () => {
    setIsClosing(true);
    setMobileOpen(false);
  };
  const handleDrawerTransitionEnd = () => {
    setIsClosing(false);
  };
  const onEssaySelect = (arg) => {
    setViewMode("VIEW");
    setRecEssay(arg);
    var _list = essayList;
    _list.forEach((s) => {
      s.selected = s.essayId == arg.essayId ? "1" : "0";
    });
  };
  const essayDelete = async (arg) => {
    if (!window.confirm("Are you sure you want to delete the Essay?")) return;
    setIsLoading(true);
    var _res = await AppHelper.apiPost("api/essay/remove", {
      token: oUser.token,
      essayId: arg.essayId,
    });
    if (!_res || !_res.status) {
      alert("ERROR: Failed to delete!");
      setIsLoading(false);
      return;
    }
    setRefreshList(!refreshList);
  };
  const essayFormDone = (arg) => {
    if (arg.code && arg.code == "NEW") {
      setAutoSelectId(arg.essayId);
    }
    setRefreshList(!refreshList);
  };
  const essayViewClick = (arg) => {
    setRecEssay(arg);
    setViewMode("VIEW");
  };
  const essayViewDone = (arg) => {
    setRefreshList(!refreshList);
    setViewMode("NEW");
  };
  const essayListView = () => {
    //setViewMode("LIST");
	navigate("/saV2/essay");
  };
  
  const cardView = () => {
    //setIsIncludeDeletedUser(false);
    //setViewMode("CARDS");
	navigate("/saV2");
  };
  const nonSaView = () => {
    setViewMode("NON-SA");
  };
  const drawerItemColrs = {
    "1x": "rgba(0,0,0,0.25)",
    "1xx": "rgb(157, 188, 152)",
    1: "rgb(99, 136, 137)",
    "0x": "white",
    0: "rgb(235, 217, 180)",
  };
  const drawerItemColorsText = {
    1: "rgba(255,255,255,1)",
    0: "rgba(0,0,0,0.6)",
  };
  const drawer = (
    <>
      <div style={{ overflowY: "auto", marginBottom: "110px" }}>
        <Box width={"100%"}>
          <List sx={{ fontFamily: "Noto Sans" }}>
            {/* {['Week 12 - Essay B', 'Week 12 - Essay A', 'Week 11 - Essay B', 'Week 11 - Essay A'].map((text, index) => ( */}
            {essayList.map((rec, index) => (
              <React.Fragment key={rec.essayId}>
                <ListItem
                  sx={{
                    backgroundColor: rec.selected
                      ? drawerItemColrs[rec.selected]
                      : drawerItemColrs["0"],
                    color: rec.selected
                      ? drawerItemColorsText[rec.selected]
                      : drawerItemColorsText["0"],
                  }}
                  disablePadding
                >
                  <ListItemButton onClick={() => onEssaySelect(rec)}>
                    <div style={{ widht: "100%", flex: 1 }}>
                      <Box>
                        <Typography sx={{ fontWeight: "bold" }}>
                          {rec.viewTitle}
                        </Typography>
                      </Box>
                      <div>
                        <LinearProgressWithLabel
                          value={rec.avgScoreP}
                          recSelected={rec.selected}
                        />
                      </div>
                      <Typography
                        sx={{
                          display: "inline",
                          color: rec.selected
                            ? drawerItemColorsText[rec.selected]
                            : drawerItemColorsText["0"],
                          opacity: "0.8",
                        }}
                        component="span"
                        variant="body2"
                      >
                        {rec.tTitle}
                      </Typography>
                    </div>
                  </ListItemButton>
                </ListItem>
                <Divider />
              </React.Fragment>
            ))}
          </List>
        </Box>
      </div>
      <div
        style={{
          position: "absolute",
          bottom: 50,
          right: 0,
          left: 0,
          height: "60px",
          borderTop: "0 solid rgba(0,0,0,0.12)",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {/* <Button sx={{
        backgroundColor:'rgb(99, 136, 137)', 
        ":hover":{backgroundColor:'rgb(157, 188, 152)'},
        width:'100%',
        margin:'0 5px 22px 5px'
        }}
        onClick={essayAddClick} color="info" variant="contained" size="large">
        + ADD
      </Button> */}
      </div>
    </>
  );
  const mobileOpenClick = (arg) => {
    setMobileOpen(!mobileOpen);
  };
  const back2Home = () => {
    navigate("/");
  };
  const listSortAndFilterEssay = () => {
    var _list = [...essayList];
    if (filterEssay && filterEssay.length > 0) {
      var _filterText = filterEssay.toLowerCase();
      _list = _list.filter(
        (r) =>
          r.grade.toLowerCase().includes(_filterText) ||
          r.topic.toLowerCase().includes(_filterText) ||
          r.email.toLowerCase().includes(_filterText) ||
          r.version.toLowerCase().includes(_filterText) ||
          r.tTitle.toLowerCase().includes(_filterText) ||
          r.avgScoreP.toString().toLowerCase().includes(_filterText)
      );
    }
    return stableSort(_list, getComparator(tblOrderEssay, tblOrderByEssay));
  };
  const filterEssayChange = (event) => {
    setFilterEssay(event.target.value);
  };

  const visibleEssays = React.useMemo(
    () => listSortAndFilterEssay(),
    //stableSort(essayList, getComparator(tblOrderEssay, tblOrderByEssay))
    // .slice(
    //   page * rowsPerPage,
    //   page * rowsPerPage + rowsPerPage,
    // )
    //[order, orderBy, page, rowsPerPage],

    [essayList, tblOrderEssay, tblOrderByEssay, filterEssay]
  );
  const tblSortEssay = (event, property) => {
    const isAsc = tblOrderByEssay === property && tblOrderEssay === "asc";
    setTblOrderEssay(isAsc ? "desc" : "asc");
    setTblOrderByEssay(property);
  };
 

 

  
  const container =
    window !== undefined ? () => window.document.body : undefined;
  return (
    <Box sx={{ display: "flex" }}>
      <AppTopBar2 mobileOpen={mobileOpen} mobileOpenClick={mobileOpenClick} />
      {isLoading ? (
        <AppLoading />
      ) : (
        <>
          {/* <Box
        component="nav"
        sx={{ width: { md: drawerWidth }, flexShrink: { sm: 0, md:0 }, border:'0 solid red' }}
      >
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onTransitionEnd={handleDrawerTransitionEnd}
          onClose={handleDrawerClose}
          ModalProps={{ keepMounted: true }}
          sx={{
            display: { xs: 'block', sm: 'block', md:'none' },
            minWidth: '300px'
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: 'none', sm: 'none', md: 'block' },
            backgroundColor:'red',
            minWidth:'300px'
          }}
          PaperProps={{
            sx: {
              backgroundColor: "#f9efdb", //color: "red",
              marginTop:'65px',
              width: drawerWidth
            }
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box> */}
          <Box
            component="main"
            sx={{
              flexGrow: 1,
              p: 3,
              //width: { md: `calc(100% - ${drawerWidth}px)` },
              width: "100%",
              marginTop: "50px",
            }}
          >
            
            {viewMode == "LIST" && (
              <>
                <Grid container>
                  <Grid item xs={6} sm={6}>
                    <Button onClick={cardView}>&laquo; Back to Overview</Button>
                  </Grid>
                  <Grid item xs={6} sm={6}>
                    <TextField
                      value={filterEssay}
                      onChange={filterEssayChange}
                      id="txtFilterEssay"
                      label=""
                      variant="standard"
                      placeholder="Search"
                      fullWidth
                    />
                  </Grid>
                </Grid>
                <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    {/* <TableHead>
                  <TableRow>
                    <TableCell align="center">GRADE</TableCell>
                    <TableCell align="left">TITLE</TableCell>
                    <TableCell align="center">SCORE</TableCell>
                    <TableCell align="center">SUBMITTED ON</TableCell>
                    <TableCell align="left">SUBMITTED BY</TableCell>
                  </TableRow>
                </TableHead> */}

                    <EnhancedTableHead
                      //numSelected={selected.length}
                      order={tblOrderEssay}
                      orderBy={tblOrderByEssay}
                      //onSelectAllClick={handleSelectAllClick}
                      onRequestSort={tblSortEssay}
                      rowCount={essayList.length}
                      gid="G1"
                    />
                    <TableBody>
                      {visibleEssays
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((row, iRec) => (
                          <TableRow
                            key={row.essayId}
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                            }}
                          >
                            <TableCell align="center">{iRec + 1}.</TableCell>
                            <TableCell align="left">{row.grade}</TableCell>
                            <TableCell component="th" scope="row">
                              {/* <Button href={'sa/' + row.essayId} onClick={()=> essayViewClick(row)}>{row.topic}</Button> */}
                              <Button
                                href={"/essay/detail/" + row.essayId}
                                target="_blank"
                              >
                                {row.topic}
                              </Button>
                            </TableCell>
                            <TableCell align="right">
                              {row.avgScoreP}%
                            </TableCell>
                            <TableCell align="left">{row.version}</TableCell>
                            <TableCell align="left">{row.vCorr}</TableCell>
                            <TableCell align="left">{row.tTitle}</TableCell>
                            <TableCell align="left">
                              {row.ete_profile ? row.ete_profile.email : "-"}
                            </TableCell>
                            <TableCell align="left">
                              {row.ete_profile ? row.ete_profile.name : "-"}
                            </TableCell>
                            <TableCell align="left">
                              <IconButton
                                onClick={() => essayDelete(row)}
                                aria-label="delete"
                              >
                                <DeleteForeverIcon color="error" />
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                </TableContainer>

                <TablePagination
                  rowsPerPageOptions={[5, 10, 25]}
                  component="div"
                  count={visibleEssays.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </>
            )}
           

           

           
            {viewMode == "VIEW" && (
              <>
                <Box>
                  <Button onClick={essayListView}>&laquo; Back to List</Button>
                </Box>
                <EssayView
                  essayFormDone={essayViewDone}
                  essayId={recEssay.essayId}
                ></EssayView>
              </>
            )}
            {viewMode == "NON-SA" && (
              <React.Fragment>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <Card variant="outlined">
                      <CardContent>
                        <Typography variant="h5" component="div">
                          Access denied
                        </Typography>
                        <Typography sx={{ mb: 1.5 }} color="text.secondary">
                          &nbsp;
                        </Typography>
                        <Typography variant="body2">
                          This page is only allowed for System Administrators.
                          {/* <br />
                    {'"a benevolent smile"'} */}
                        </Typography>
                      </CardContent>
                      <CardActions>
                        <Button onClick={back2Home} size="small">
                          Back to Home
                        </Button>
                      </CardActions>
                    </Card>
                  </Grid>
                </Grid>
              </React.Fragment>
            )}
          </Box>
        </>
      )}
      <SpaCheck></SpaCheck>
    </Box>
  );
}
